import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GalleryService } from 'src/app/service/gallery.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  images = [];
  
  origiImg: any;

  imagePaths = [];

  constructor(
    private router: Router,
    private galleryService: GalleryService) {
  }

  ngOnInit() {
    this.toTop();

    this.galleryService.getAllAlbums().subscribe((Response) => {
      console.log(Response);
      this.images = Response.data;

      Response.data.forEach(element => {
        this.downloadImage(element.imagePath, element.id);
      });
    }, (Error) => {
      console.log(Error);
    })
  }

  toTop() {
    document.getElementById("target").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  downloadImage(photoPath, id) {
    let imagePath = photoPath.split("albums/image/");
    this.galleryService.getAllbumImage(imagePath[1]).subscribe((Response)=> {
      this.createImageFromBlob(Response, id);
    }, (Error)=> { 
      console.log(Error);
    })
  }

  createImageFromBlob(image: Blob, id: number) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.origiImg = reader.result;
      this.imagePaths.push(this.origiImg);
      this.imagePaths[id] = this.origiImg;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  openAlbum() {
    this.router.navigateByUrl("/gallery-detail");
  }

  getAlbumImages(gallery) {
    this.router.navigate(['/gallery-detail'], {queryParams: {galleryId: gallery.id}})
  }

  onWheel(event: WheelEvent): void {
    console.log(event);
    console.log(event.deltaY);

    if(event.deltaY < 0) {
      document.getElementById('scrollDiv').scrollLeft -= 100;
      (<Element>event.target).parentElement.scrollLeft -= event.deltaY;
      event.preventDefault();
    } 

    if(event.deltaY > 0) {
      document.getElementById('scrollDiv').scrollLeft += 100;
      (<Element>event.target).parentElement.scrollLeft += event.deltaY;
      event.preventDefault();
    }
    
  } 

}
