<!-- SLIDER for Extra News -->
<section id="target" class="news-area ptb-100">
    
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    <div class="container">
        <div class="section-title">
            <h2>ALL HEADLINES</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4" *ngFor="let news of newsContent">
                <div class="news-div">
                    <div class="post-image" style="background-color: #018FF4; height: 316px;">
                        <img [src]="imagePaths[news.id]" alt="blog-image" style="width: 100%;">
                    </div>
                    <div class="news-post-content" style="padding: 15px 10px;">
                        <h5 style="padding-left:40px; color: #e5e5e5;"><a (click)="goToNewsDetail(news.id)"
                                style="color: #e5e5e5; cursor: pointer;">{{news.title | slice:0:20}}...</a></h5>
                        <p class="news-date">{{news.createdDate | date: 'dd/MM/yyyy'}}</p>
                        <p class="news-content">
                            {{news.shortDescription | slice:0:90}}</p>
                        <!-- <div class="blog-btn">
                            <a routerLink="/headlines">
                                <i class="icofont-arrow-right"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 100px;" class="bar"></div>
    </div>
</section>


<footer style="width: 100%; background-color: #000000;">
    <div style="text-align: center; padding: 100px 0;">
        <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
            BEYOND THE HORIZON
        </h5>
    </div>
</footer>