import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-detail-demo-three',
  templateUrl: './news-detail-demo-three.component.html',
  styleUrls: ['./news-detail-demo-three.component.scss']
})
export class NewsDetailDemoThreeComponent implements OnInit {

  origiImg: any;

  constructor() { }

  ngOnInit(): void {
  }

}
