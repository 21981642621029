import { Component, OnInit } from '@angular/core';
import { subscriptionService } from 'src/app/service/subscription-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  email : any;
  public subscriptionRequestView = new SubscriptionRequestView();

  constructor(
    private subscriptionService: subscriptionService
  ) { }

  ngOnInit() {
  }

  subscribeTo() {
    console.log(this.email);
    console.log("Get Subscription");
    
    if(this.email != undefined) {
      this.subscriptionRequestView.email = this.email;

      this.subscriptionService.saveSubscription(this.subscriptionRequestView).subscribe((Response) => {
        console.log(Response);
        this.email = "";
        alert("Subscription Added Successfully.");
      }, (Error) => {
        console.log(Error);
        if(Error.status == 500) {
          alert(Error.error.message);
        } else {
          alert("Unable to Subscribe. Please Apply Later.");
        }
      })
    } else {
      alert("Please Enter Email.")
    }
  }
}

export class SubscriptionRequestView  {
  email: any;
}