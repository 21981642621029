import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-events-detail',
  templateUrl: './events-detail.component.html',
  styleUrls: ['./events-detail.component.scss']
})
export class EventsDetailComponent implements OnInit {

  overview:boolean = true;
  eventSchedule:boolean = false;
  eventLecturers:boolean = false;

  eventId: number;

  origiImg: any;
  origiImgLecturer: any;

  eventObj: any;

  eventScheduleObj: any;
  eventLecturerObj: any;
  imagePathsLecturer = [];
  config: any

  blogContent:any;
  configContent: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    // uploadUrl: 'v1/image',
    // uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'indent',
        // 'outdent',
        'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        // 'fontName'
      ],
      [
        // 'fontSize',
        // 'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eventService: EventService
  ) {

    this.activatedRoute.queryParams.subscribe(param => {
      console.log(param);
      this.eventId = param.eventId;
    });
   }

  ngOnInit() {
    this.toTop();

    this.config = {
      readonly : 1,
      plugins: "autoresize",
      autoresize_bottom_margin: 20,
      menubar: false,
        statusbar: false,
        toolbar: false,
      image_advtab: false,
      images_upload_url: 'postAcceptor.php',
      imagetools_toolbar:
        'rotateleft rotateright | flipv fliph | editimage imageoptions',
      templates: [
        { title: 'Test template 1', content: 'Test 1' },
        { title: 'Test template 2', content: 'Test 2' },
      ],
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tinymce.com/css/codepen.min.css',
      ],
      setup: (editor) => {
        console.log(editor.ui);
      },
    };

    this.eventService.getEventLecturer(this.eventId).subscribe((Response) => {
      console.log(Response);
      this.eventLecturerObj = Response.data;

      this.eventLecturerObj.forEach(element => {
        this.downloadLecturerImageImage(element.imagePath, element.id);
      });

    }, (Error) => {
      console.log(Error);
    })
    
    this.eventService.getScheduleByEventId(this.eventId).subscribe((Response) => {
      console.log(Response.data);
      this.eventScheduleObj = Response.data;

    }, (Error) => {
      console.log(Error);
    })
    
    this.eventService.getEventsById(this.eventId).subscribe((Response) => {
      console.log(Response.data);

      this.eventObj = Response.data;
      this.downloadImage(Response.data.imagePath);
    }, (Error) => {
      console.log(Error);
    }) 

  }

  downloadLecturerImageImage(photoPath, id) {
    let imagePath = photoPath.split("eventLecturers/image/");
    this.eventService.getEventLecturerImage(imagePath[1]).subscribe((Response)=> {
      this.createLecturerImageFromBlob(Response, id);
    }, (Error)=> { 
      console.log(Error);
    })
  }

  createLecturerImageFromBlob(image: Blob, id: number) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.origiImgLecturer = reader.result;
      this.imagePathsLecturer.push(this.origiImgLecturer);
      this.imagePathsLecturer[id] = this.origiImgLecturer;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  toTop() {
    this.overview = true;
    this.eventSchedule = false;
    this.eventLecturers = false;

    setTimeout(()=> {
      document.getElementById("target").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }, 1000)
  }

  downloadImage(photoPath) {
    let imagePath = photoPath.split("eventsDetail/image/");
    this.eventService.getImage(imagePath[1]).subscribe((Response)=> {
      this.createImageFromBlob(Response);
    }, (Error)=> { 
      console.log(Error);
    })
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.origiImg = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  toSchedules() {
    this.eventSchedule = true;
    this.overview = false;
    this.eventLecturers = false;
    
    setTimeout(()=> {
      document.getElementById("schedules").scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }, 1000)
  }

  toLecturers() {
    this.eventSchedule = false;
    this.overview = false;
    this.eventLecturers = true;
    
    setTimeout(()=> {
      document.getElementById("lecturers").scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }, 1000)
  }  

  eventRegisterationForm() {
    this.router.navigate(["/event-register"], {queryParams: {eventId: this.eventId}});
  }
}
