<!-- SECOND APPROACH -->
<section id="target"  class="section_body" style="padding-bottom: 100px;">
    <div class="container" style="padding: 10px;">
        <div class="header">
            <h2 style="font-size: 25px; font-weight: 700; text-transform: uppercase;">News</h2>
        </div>
        <div class="founder">
            <h1 style="font-size: 23px;">Title</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="margin-bottom: 20px;">
                    DECEMBER 21, 2021, 15:04 IST
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="text-align: right;">
                    Share
                    <img src="assets/img/socials/fb.png" width="28px" height="28px">
                    <img src="assets/img/socials/insta.png" width="30px" height="30px">
                    <img src="assets/img/socials/tweet.png" width="30px" height="30px">
                </div>
            </div>

            <div class="row h-100 justify-content-center align-items-center"
                style="background-color: #131821; padding: 20px 0px 20px 0px;">
                <div class="col-lg-12 col-md-12">
                    <img [src]="origiImg" class="main-banner-img" width="100%" alt="image">
                </div>
                <div class="col-lg-12 col-md-12" style="margin-top: 3%;">
                    <div class="about-content">
                        <div class="section-title" style="max-width: 100%;">
                            <h2>Description One </h2>
                            <br>
                        </div>
                        <p style="text-align: justify;">Description Two</p>

                    </div>
                </div>

                <div class="row row-1 mt-1">
                    <div class="col-lg-12 col-md-12" >
                        <div class="" style="max-width: 100%; margin: 10px 0 5px 0;">
                            <h2>Lorem ipsum dolor sit amet,
                                con se ctetur adipiscing elit. </h2>
                        </div>
                        <p>Business-to-business metrics analytics value proposition funding
                            angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.</p>
                    </div>
                </div>

                <div class="row row-2 mt-3">
                    <div class="col-lg-6 col-md-6">
                        <ul class="mt-2">
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Sed a sem iaculis risus rutrum lacinia.</li>
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Vivamus egestas dolor at augue sagittis, eu consectetur nulla efficitur.</li>
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Sed a sem iaculis risus rutrum lacinia.</li>
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Vivamus egestas dolor at augue sagittis, eu consectetur nulla efficitur.</li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div style="height: 350px;">
                            <img src="https://unsplash.it/1504" alt="image" height="100%" width="100%">
                        </div>
                    </div>
                </div>

                <div class="row-3 mt-4">
                    <div class="col-lg-12 col-md-12">
                        <div class="container" style="text-align: center; ">
                            <h3>"Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                                viverra nunc tinci dunt nec elei fend et tiram".</h3>
                            
                            <p>
                                Lorem ipsum
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-lg-12 col-md-12">
                        <p>Business-to-business metrics analytics value proposition funding
                            angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>