import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-csr-funding-landing',
  templateUrl: './csr-funding-landing.component.html',
  styleUrls: ['./csr-funding-landing.component.scss']
})
export class CsrFundingLandingComponent implements OnInit {


  newsContent: any[] = [
    {
      "id":1,
      "title": "The Most Popular New Business Apps", //title requires min 34 characters.
      "date": "Dec 1, 2021",
      "content": "",
      "image": "assets/img/logos/body-bg-6.jpg"
    },
    {
      "id":2,
      "title": "The Most Popular New Business Apps",
      "date": "Dec 1, 2021",
      "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.",
      "image": "assets/img/logos/body-bg-6.jpg"
    },
    {
      "id":3,
      "title": "The Most Popular New Business Apps",
      "date": "Dec 1, 2021",
      "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "image": "assets/img/extras/body-bg-4.jpg"
    },
    {
      "id":4,
      "title": "The Most Popular New Business Apps", //title requires min 34 characters.
      "date": "Dec 1, 2021",
      "content": "",
      "image": "assets/img/logos/body-bg-6.jpg"
    },
    {
      "id":5,
      "title": "The Most Popular New Business Apps",
      "date": "Dec 1, 2021",
      "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "image": "assets/img/extras/body-bg-4.jpg"
    },
    {
      "id":6,
      "title": "The Most Popular New Business Apps",
      "date": "Dec 1, 2021",
      "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.",
      "image": "assets/img/logos/body-bg-6.jpg"
    },
    {
      "id":7,
      "title": "The Most Popular New Business Apps",
      "date": "Dec 1, 2021",
      "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      "image": "assets/img/extras/body-bg-4.jpg"
    },
    {
      "id":8,
      "title": "The Most Popular New Business Apps",
      "date": "Dec 1, 2021",
      "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.",
      "image": "assets/img/logos/body-bg-6.jpg"
    },
    {
      "id":9,
      "title": "The Most Popular New Business Apps", //title requires min 34 characters.
      "date": "Dec 1, 2021",
      "content": "",
      "image": "assets/img/logos/body-bg-6.jpg"
    },

  ]


  public customOptions: OwlOptions = {
    loop: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    autoplayTimeout: 5000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  };
  constructor() {

  }

  ngOnInit() {
    this.toTop();
  }

  toTop() {
    document.getElementById("target").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
