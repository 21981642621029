<!-- THIRD APPROACH -->
<section class="section_body" style="padding-bottom: 100px;">
    <div class="container" style="padding: 10px;">
        <div class="header">
            <h2 style="font-size: 25px; font-weight: 700; text-transform: uppercase;">News</h2>
        </div>
        <div class="founder">
            <h1 style="font-size: 23px;">News Title 3</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="margin-bottom: 20px;">
                    DECEMBER 21, 2021, 15:04 IST
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="text-align: right;">
                    Share
                    <img src="assets/img/socials/fb.png" width="28px" height="28px">
                    <img src="assets/img/socials/insta.png" width="30px" height="30px">
                    <img src="assets/img/socials/tweet.png" width="30px" height="30px">
                </div> -->
            </div>

            <div class="row h-100 justify-content-center align-items-center"
                style="background-color: #131821; padding: 20px 0px 20px 0px;">
                <div class="col-lg-12 col-md-12">
                    <img src="https://unsplash.it/1503" class="main-banner-img" width="100%" alt="image">
                </div>
                <div class="col-lg-12 col-md-12" style="margin-top: 3%;">
                    <div class="about-content">
                        <div class="section-title" style="max-width: 100%; margin-bottom: 10px;">
                            <h2>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                                viverra nunc tinci dunt nec elei fend et tiram.</h2>
                            <br>
                        </div>
                        <p style="text-align: justify;">Business-to-business metrics analytics value proposition funding
                            angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque. Business-to-business metrics analytics value
                            proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque. Business-to-business metrics analytics value
                            proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque.vBusiness-to-business metrics analytics value
                            proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque.
                            Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                            viverra nunc tinci dunt nec elei fend et tiram.</p>
                    </div>
                </div>

                <div class="row row-5 mt-1">
                    <div class="col-lg-7 col-md-7">
                        <div class="section-title img-container">
                            <img src="https://unsplash.it/1505" alt="image" height="100%" width="100%">
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 flex-quote">
                        <h4>"Lorem ipsum dolor sit amet,
                            con se ctetur adipiscing elit". </h4>
                            <p>
                                Lorem Ipsum
                            </p>
                    </div>
                </div>

                <div class="row row-6 mt-3">
                    <div class="col-lg-12 col-md-12">
                        <h4>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. </h4>
                        <p>Business-to-business metrics analytics value proposition funding
                            angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.</p>
                    </div>
                </div>

                <div class="row row-7 mt-4">
                    <div class="col-lg-6 col-md-6">
                        <ul class="mt-2">
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Sed a sem iaculis risus rutrum lacinia.</li>
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Vivamus egestas dolor at augue sagittis, eu consectetur nulla efficitur.</li>
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Sed a sem iaculis risus rutrum lacinia.</li>
                            <li><i class="icofont-rounded-right" style="color: #018FF4;"></i> Vivamus egestas dolor at augue sagittis, eu consectetur nulla efficitur.</li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div >
                            <img src="https://unsplash.it/1506" alt="image">
                        </div>
                    </div>
                </div>

                <div class="row row-8 mt-4">
                    <div class="col-lg-12 col-md-12">
                        <h4>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. </h4>
                        <p>Business-to-business metrics analytics value proposition funding
                            angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.
                            Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.</p>
                    </div>
                    <div class="col-lg-12 col-md-12 quote">
                        <h3>"Lorem ipsum dolor sit amet,
                            con se ctetur adipiscing elit." </h3>
                            <p>
                                Lorem Ipsum
                            </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
