import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLConfig } from '../url-config';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  
  constructor(
    private httpClient: HttpClient,
    private urlConfig: URLConfig
  ) { }
  
  public getAlbumById(albumId):Observable<any> {
    return this.httpClient.get(this.urlConfig.getAlbumById + albumId);
  }

  public getAllAlbums():Observable<any> {
    return this.httpClient.get(this.urlConfig.getAllAlbum);
  }

  public getAllbumImage(imagePath):Observable<any> {
    return this.httpClient.get(this.urlConfig.getImage + imagePath, {responseType: 'blob'});
  }

  public getImagesByAlbumId(albumId):Observable<any> {
    return this.httpClient.get(this.urlConfig.getImagesByAlbumId + albumId);
  }

  public getImagesofAlbum(imagePath):Observable<any> {
    return this.httpClient.get(this.urlConfig.getAlbumImage + imagePath, {responseType: 'blob'});
  }
}
