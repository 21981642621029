
<section class="section_body" style="padding-bottom: 100px;">
    <div class="container" style="padding: 10px;">
        <div class="header">
            <h2 style="font-size: 25px; font-weight: 700; text-transform: uppercase;">Funding Title</h2>
        </div>
        <div class="founder">
            <h1 style="font-size: 23px;">Title 3</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="margin-bottom: 20px;">
                    DECEMBER 21, 2021, 15:04 IST
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="text-align: right;">
                    Share
                    <img src="assets/img/socials/fb.png" width="28px" height="28px">
                    <img src="assets/img/socials/insta.png" width="30px" height="30px">
                    <img src="assets/img/socials/tweet.png" width="30px" height="30px">
                </div> -->
            </div>

            <!-- main-image -->
            <div class="row h-100 justify-content-center align-items-center"
                style="background-color: #131821; padding: 20px 10px 20px 0px;">
                <div class="col-lg-12 col-md-12">
                    <img src="https://unsplash.it/1503" class="main-banner-img" width="100%" alt="image">
                </div>
                <div class="col-lg-12 col-md-12" style="margin-top: 3%;">
                    <div class="about-content">
                        <div class="section-title" style="max-width: 100%; margin-bottom: 10px;">
                            <h2>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                                viverra nunc tinci dunt nec elei fend et tiram.</h2>
                            <br>
                        </div>
                        <p style="text-align: justify;">Business-to-business metrics analytics value proposition funding
                            angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque. Business-to-business metrics analytics value
                            proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque. Business-to-business metrics analytics value
                            proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque.vBusiness-to-business metrics analytics value
                            proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque.
                            Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                            viverra nunc tinci dunt nec elei fend et tiram.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- SLIDER for Extra News -->
<section class="news-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>More Images</h2>
        </div>

        <div class="col-sm-12 mt-2" style="padding-left: 5px;">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let news of newsContent">
                    <div class="news-div">
                        <div class="post-image" style="background-color: #018FF4; height: 316px;">
                            <img src="{{news.image}}" alt="blog-image">
                        </div>
                        <!-- <div class="news-post-content" style="padding: 15px 10px;">
                            <h5 style="padding-left:40px; color: #e5e5e5;"><a routerLink="/blog-details"
                                    style=" color: #e5e5e5;">{{news.title}}</a></h5>
                            <p class="news-date">{{news.date}}</p>
                            <p class="news-content">
                                {{news.content}}</p>
                        </div> -->
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
        <div style="margin-top: 100px;" class="bar"></div>
    </div>
</section>



<footer style="width: 100%; background-color: #000000;">
    <div style="text-align: center; padding: 100px 0;">
        <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
            BEYOND THE HORIZON
        </h5>
    </div>
</footer>