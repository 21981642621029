
<!-- SECOND APPROACH -->
<section id="target"  class="section_body" style="padding-bottom: 100px;">
    <div class="container" style="padding: 10px;">
        <div class="header">
            <h2 style="font-size: 25px; font-weight: 700; text-transform: uppercase;">Headline Details</h2>
            <h2 class="mt-5" style="font-size: 25px; font-weight: 700; text-transform: uppercase;">{{newsObj.title}}</h2>
        </div>
        <tinymce [(ngModel)]="newsObj.description" [config]="config" [ngModelOptions]="{standalone: true}"></tinymce>
    </div>
</section>

<footer style="width: 100%; background-color: #000000;">
    <div style="text-align: center; padding: 100px 0;">
        <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
            BEYOND THE HORIZON
        </h5>
    </div>
</footer>