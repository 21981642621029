import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLConfig } from '../url-config';

@Injectable({
  providedIn: 'root'
})
export class ContactServiceService {

  constructor(
    private httpClient: HttpClient,
        private urlConfig: URLConfig
  ) { }

  public saveContact(ContactRequestView: any): Observable<any> {
    return this.httpClient.post(this.urlConfig.saveContact, ContactRequestView);
  }
}
