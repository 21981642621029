<!-- Start Navbar Area -->
<header id="header">
    <div class="crake-nav {{location == '/digital-marketing' ? 'marketing-navbar' : ''}}">
        <div class="header" >
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logos/TSC_Logo_10.png" alt="logo" style="width: 150px;">
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav nav ms-auto">

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" >Products</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '1'}" class="nav-link">CubeSat Platforms</a></li>
                                <li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '2'}" class="nav-link">Communications</a></li>
                                <!--  <li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '3'}" class="nav-link">Antennas</a></li>-->
                                <li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '4'}" class="nav-link">Beacon</a></li>
                                <li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '5'}" class="nav-link">Onboard Computers</a></li>
                                <li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '6'}" class="nav-link">Electrical Power Systems</a></li>
                                 <!--<li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '7'}"  class="nav-link">Solar Panels</a></li>-->
                                <li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '8'}" class="nav-link">Battery Board</a></li>
                                <li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '9'}"  class="nav-link">Structures</a></li>
                                <li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '10'}"  class="nav-link">Payloads</a></li>
                                <!--<li class="nav-item"><a [routerLink]="['/products']" [queryParams]="{ productId: '11'}"  class="nav-link">Educational Modules</a></li>-->
                            </ul>
                        </li>
                        
                        <!-- <li><a routerLink="/projects">Projects</a></li> -->
                        <!-- <li><a routerLink="/coming-soon">Services</a></li> -->
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a [routerLink]="['/services/tsc-edunav']" [queryParams]="{ s: '1'}" class="nav-link">TSC EduNav</a></li>
                                <!-- <li class="nav-item"><a [routerLink]="['/services/digital-ground-station']" [queryParams]="{ s: '2'}" class="nav-link">Digital Ground Station</a></li> -->
                                <!-- <li class="nav-item"><a [routerLink]="['/services/tsc-satnav']" [queryParams]="{ s: '3'}" class="nav-link">TSC Patent Application</a></li> -->
                                <li class="nav-item"><a [routerLink]="['/services/tsc-satnav']" [queryParams]="{ s: '4'}" class="nav-link">TSC SatNav</a></li>
                                <!-- <li class="nav-item"><a [routerLink]="['/services/shared-satellite-service']" [queryParams]="{ s: '5'}" class="nav-link">TSC Space Community</a></li> -->
                            </ul>
                        </li>
                        
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" >Downloads</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a [routerLink]="['/publications']" [queryParams]="{ sectionId: '1'}" class="nav-link">Downloads</a></li>
                                <li class="nav-item"><a [routerLink]="['/publications']" [queryParams]="{ sectionId: '2'}" class="nav-link">Publications</a></li>
                            </ul>
                        </li>
                        <!-- <li><a routerLink="/publications">Publications</a></li> -->
                        <li><a routerLink="/partners">Partners</a></li>
                        <li><a routerLink="/about-us" >About</a></li>
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Events</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/events" class="nav-link">Events</a></li>

                                <li class="nav-item"><a routerLink="/headlines" class="nav-link">Headlines</a></li>
                                <!-- <li class="nav-item"><a routerLink="/csr-funding" class="nav-link">CSR-Funding</a></li> -->

                            </ul>
                        </li>
                        <li><a routerLink="/gallery">Gallery</a></li>
                        















                        <!-- <li class="nav-item dropdown">
                            <a href="/startup-agency" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Home</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">SaaS Landing</a></li>

                                <li class="nav-item"><a routerLink="/app-landing" routerLinkActive="active" class="nav-link">App Landing</a></li> 

                                <li class="nav-item"><a routerLink="/digital-marketing" routerLinkActive="active" class="nav-link">Digital Marketing</a></li>

                                <li class="nav-item"><a routerLink="/product-landing" routerLinkActive="active" class="nav-link">Product Landing</a></li>

                                <li class="nav-item"><a routerLink="/software-landing" routerLinkActive="active" class="nav-link">Software Landing</a></li>

                                <li class="nav-item"><a routerLink="/book-landing" routerLinkActive="active" class="nav-link">Book Landing</a></li>

                                <li class="nav-item"><a routerLink="/startup-agency" routerLinkActive="active" class="nav-link">Startup Agency</a></li>

                                <li class="nav-item"><a routerLink="/payment-processing" routerLinkActive="active" class="nav-link">Payment Processing</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Products</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/about-us" routerLinkActive="active" class="nav-link">CubeSat Platforms</a></li>

                                <li class="nav-item"><a routerLink="/features" routerLinkActive="active" class="nav-link">Communications</a></li>

                                <li class="nav-item"><a routerLink="/services" routerLinkActive="active" class="nav-link">Antennas</a></li>

                            </ul>
                        </li> -->
                        <!-- <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/about-us" routerLinkActive="active" class="nav-link">Shared Satellite Service</a></li>

                                <li class="nav-item"><a routerLink="/features" routerLinkActive="active" class="nav-link">Digital Ground Station</a></li>

                                <li class="nav-item"><a routerLink="/services" routerLinkActive="active" class="nav-link">Education and Training</a></li>

                            </ul>
                        </li> -->

                        <!-- <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a></li>

                                <li class="nav-item"><a routerLink="/features" routerLinkActive="active" class="nav-link">Features</a></li>

                                <li class="nav-item"><a routerLink="/services" routerLinkActive="active" class="nav-link">Services</a></li>

                                <li class="nav-item"><a routerLink="/gallery" routerLinkActive="active" class="nav-link">Gallery</a></li>

                                <li class="nav-item"><a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" routerLinkActive="active" class="nav-link">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/projects" routerLinkActive="active" class="nav-link">Portfolio</a></li>

                                <li class="nav-item"><a routerLink="/login" routerLinkActive="active" class="nav-link">Login</a></li>

                                <li class="nav-item"><a routerLink="/signup" routerLinkActive="active" class="nav-link">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" routerLinkActive="active" class="nav-link">Coming Soon</a></li>

                                <li class="nav-item"><a routerLink="/error" routerLinkActive="active" class="nav-link">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ</a></li>
                            </ul>
                        </li> -->

                        <!-- <li><a routerLink="/gallery" routerLinkActive="active">Projects</a></li> -->
                        
                        <li><a routerLink="/careers" >Careers</a></li>

                        <!-- <li><a routerLink="/gallery" routerLinkActive="active">Gallery</a></li> -->

                        <!-- <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Blog</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" routerLinkActive="active" class="nav-link">Blog Details</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item"><a routerLink="/contact-us">Contact</a></li>
                    </ul>
                </div>

                <!-- <div class="mr-auto others-option">
                    <ul class="navbar-nav">

                        <li data-bs-toggle="modal" data-bs-target="#myModal2">
                            <div class="side-menu">
                                <span class="bar-1"></span>
                                <span class="bar-2"></span>
                                <span class="bar-3"></span>
                            </div>
                        </li>
                    </ul>
                </div> -->
            </nav>
        </div>
    </div>
</header>
<!-- End Navbar Area -->

<app-sidebar></app-sidebar>

<!-- Start Search Box -->
<div id="header-search" class="header-search">
    <button type="button" class="close">×</button>
    <form class="header-search-form">
        <input type="search" value="" placeholder="Type here........" />
        <button type="submit" class="btn btn-primary">Search</button>
    </form>
</div>
<!-- End Search Box -->