import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-events-landing',
  templateUrl: './events-landing.component.html',
  styleUrls: ['./events-landing.component.scss']
})
export class EventsLandingComponent implements OnInit {

  eventsArrayLength: boolean = false;

  eventsDatabase: any[] =[];
  eventsArray: any[] =[];
  temp: any[]= [];

  origiImg: any;

  imagePaths = [];
  p: number = 1;

  constructor(
    private eventService: EventService,
    private router: Router
  ) { }

  ngOnInit() {
    this.toTop();

    this.eventService.getAllEvents().subscribe((Response) => {
      console.log(Response);
      this.eventsDatabase = Response.data;
      this.eventsArray = Response.data;
      if(this.eventsArray.length >=5) {
        this.eventsArrayLength = true;
      }
      Response.data.forEach(element => {
        this.downloadImage(element.imagePath, element.id);
      });

    }, (Error) => {
      console.log(Error)
    })

    console.log(this.eventsArray);

  }

  toTop() {
    document.getElementById("target").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  downloadImage(photoPath, id) {
    console.log(photoPath);
    if(photoPath != null) {
      let imagePath = photoPath.split("eventsDetail/image/");
      this.eventService.getImage(imagePath[1]).subscribe((Response)=> {
        this.createImageFromBlob(Response, id);
      }, (Error)=> { 
        console.log(Error);
      })
    } else {
      this.imagePaths.push("");
      this.imagePaths[id] = '';
    }
  }

  createImageFromBlob(image: Blob, id: number) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.origiImg = reader.result;
      this.imagePaths.push(this.origiImg);
      this.imagePaths[id] = this.origiImg;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  showNewPage(startValue, endValue) {
    this.temp = this.eventsDatabase.slice(startValue, endValue);
    console.log(this.temp);
    this.eventsArray = [...this.temp];
    console.log(this.eventsArray);
  }

  goToEventDetailPage(eventId) {
    console.log("EventId = " + eventId);
    this.router.navigate(["/event-details"], {queryParams:{eventId: eventId}});
  }

}
