<section id="target" *ngIf="showPartners" class="overview-section ptb-100" style="margin-right: 0;">
    
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    <!-- <div class="container" style="padding-top: 50px;"> -->
        <div id="1"  class="row mb-5">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                <div style="float: right;">
                    <a class="nav-link" style="margin-right: 10px;"
                        class="btn btn-primary btn-gradient service-more-desktop-btn" (click)="togglePartners()">Partners</a>
                    <a class="nav-link"
                        class="btn btn-primary btn-gradient service-more-desktop-btn" (click)="toggleClients()">Clients</a>                                            
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <h2 style="float: left; color: #018ff4; font-weight: 700; font-size: 25px;">PARTNERS</h2>
            </div>
        </div>
        <!-- <div class="">
            <h2>PARTNERS</h2>
        </div> -->
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/itca.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Indian Technology Congress Association (ITCA)</h1>
                    <p>Indian Technology Congress (ITC) over the last 15 years attained international prominence as a unique platform fostering Industry-Academia-Research 
                        Labs-Policy Makers’ collaboration with the objective of capacity-building for global technological progress.<br>
                        <br>
                        The need for a dedicated Association for Technologists comprising of scientists, engineers, entrepreneurs, and policy-makers has been echoed at many summits, fora, including at the previous editions of ITC; and has been gaining strength and momentum with the call to establish an Association under the Societies Registration Act. During the recently concluded ITC-2017, a resolution was unanimously adopted in the Bangalore Declaration that Technology Professionals from the industry, R&D Laboratories, and Academic Institutions should collaborate and 'institutionalize' the synergy by establishing the Indian Technology Congress Association (ITCA).</p>

                    <p class="website">
                        <!-- To Visit Website, <a href="https://www.itca.org.in/" target="_blank"><strong> click here. </strong></a> -->
                        <a href="https://www.itca.org.in/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="2" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Indian Space Research Organisation (ISRO)</h1>
                    <p>India decided to go to space when Indian National Committee for Space Research (INCOSPAR) was set up by the Government of India in 1962. 
                        With the visionary Dr Vikram Sarabhai at its helm, INCOSPAR set up the Thumba Equatorial Rocket Launching Station (TERLS) in 
                        Thiruvananthapuram for upper atmospheric research.</p>
                    <p class="website">
                        <!-- To Visit Website, <a href="https://www.isro.gov.in/"  target="_blank"><strong> click here. </strong></a> -->
                        <a href="https://www.isro.gov.in/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                    
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/isro.png"  alt="gallery">
                </div>
            </div>
        </div>

        <div id="3" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/wcrc1.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>World CanSat & Rocketry Championship (WCRC)</h1>
                    <p>A World CanSat/Rocketry Championship is generally an international
                        competition open to elite competitors from around the world, representing their nations (as university
                        student Teams or as independent student Teams), and winning this event will be considered the highest
                        or near highest achievement in this field.
                        </p>
                    <p class="website">
                        <!-- To Visit Website, <a href="https://www.wcrc.world/"  target="_blank"><strong> click here. </strong></a> -->
                        <a href="https://www.wcrc.world/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="4" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>NewSpace India Ltd (NSIL)</h1>
                    <p>In order to commercially exploit the products and services emanating from the Indian Space Programme,
                         the <strong>“NewSpace India Limited (NSIL)” </strong> has been incorporated in March 2019, as a wholly-owned Government of India Company, 
                         under the administrative control of Department of Space (DOS). NSIL currently has an authorized share capital of ₹ 100 crore and paid-up capital of ₹ 10 crore.
                         NSIL is the commercial arm of Indian Space Research Organisation (ISRO).
                        </p>
                    <p class="website">
                        <!-- To Visit Website, <a href="https://www.nsilindia.co.in/"  target="_blank"><strong> click here. </strong></a> -->
                        <a href="https://www.nsilindia.co.in/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/nsil.png" alt="gallery">
                </div>
            </div>
        </div>

        <div id="5" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/geek.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>GeekSpace Labs</h1>
                    <p> </p>
                    <p >
                        <!-- To Visit Website, <a href="https://geekspacelabs.in/"  target="_blank"><strong> click here. </strong></a> -->
                        <a href="https://geekspacelabs.in/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="6" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Ananth Technologies Limited (ATL)</h1>
                    <p>Ananth was incorporated in 1992 to support the growth of the Indian economy through leveraging aerospace technologies. Our twin offerings include 
                        the manufacture of critical aerospace systems and high-value geospatial services. These products and services have been both internationally 
                        certified (AS 9100C & ISO 9001:2008) and recognized by our clients.<br>
                        <br>

                        Ananth is a strong and reliable partner in both the local and global aerospace supply chains.Our systems have been integral in about 50 launch vehicles and close to 100 space crafts. In addition, Ananth’s Geospatial services enable activities across the spectrum from town planning to infrastructure building. Expertise, Quality, and Focus are the guiding principles at Ananth.
                        </p>
                    <p class="website">
                        <!-- To Visit Website, <a href="https://ananthtech.com/"  target="_blank"><strong> click here. </strong></a> -->
                        <a href="https://ananthtech.com/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/atl.png" alt="gallery">
                </div>
            </div>
        </div>

        <div id="7" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/cspd.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Committee for Space Programme Development (CSPD)</h1>
                    <p> </p>
                    <p class="Website">
                        <!-- To Visit Website, <a href="http://2comnet.info/"  target="_blank"><strong> click here. </strong></a> -->
                        <a href="http://2comnet.info/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="8" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Alpha Design Technologies</h1>
                    <p>Alpha Design Technologies Pvt Ltd., has been established with a view to put into action "MAKE IN INDIA" policy of the Government of India.  ALPHA specializes in R&D, manufacture, assembly, testing, qualification, integration & installation of Defence Electronics, Avionics & Space Satellites systems.
                        <br>
                        <br>
                        Established in Bangalore during 2004, the Company is structured to offer the above to wide range of products to Indian and International Markets.
                        
                        ALPHA's management, operations and production executives and skilled technicians combine a wealth of experience in all facets of defence technology including R&D, manufacture, quality assurance, evaluation and system integration.
                        </p>
                    <p class="website">
                        <!-- To Visit Website, <a href="https://www.adtl.co.in/"  target="_blank"><strong> click here. </strong></a> -->
                        <a href="https://www.adtl.co.in/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/adt.png" alt="gallery">
                </div>
            </div>
        </div>

        <div id="9" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/nts.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>New Tech Solutions (NTS)</h1>
                    <p>Newtech Solutions Electronics was founded in 1995 by Mr. Anand Krishnappa in Bangalore, India.
                        Since then, Newtech Solutions has rapidly grown into a diversified electronics company operating across different industry segments and 
                        offering a broad range of products and services. It has continuously invested in strengthening its design & product
                         development capabilities and has established truly world-class manufacturing facility.
                        <br> <br>
                        A key contributor to Newtech Solutions growth has been the strong relationships forged with customers and partners who have enabled the company to establish a long term presence and bring value to a range of customers. This customer-focused approach coupled with Newtech Solutions core-values of Technology-Teamwork-Trust has resulted in a track-record of high quality products & services and excellent execution ability. </p>
                    <p class="website">
                        <!-- To Visit Website, <a href="https://ntsblr.com/"  target="_blank"><strong> click here. </strong></a> -->
                        <a href="https://ntsblr.com/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="10" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Karnataka Hybrid Micro Devices Ltd. (KHMD)</h1>
                    <p>Established in 1992 and restructured in 2001, KHMD has positioned itself as a leader by delivering more than 30 million hybrid micro-devices for Aerospace, Automotive and Bio-medical segments.

                        We have 6000 square metre manufacturing facility in Electronics City, Bangalore that houses State of the art hybrid fabrication facility, Electronic Manufacturing lines and Environmental and Reliability evaluation facilities.
                        
                        KHMD has delivered more than 40000 complex Avionics Board Assembly incorporated to more than 450 Aircrafts.
                        
                        The core capability of KHMD include Design, Development and Manufacture of Thick Film Hybrid Micro Circuits catering to Aerospace, Defense and Automotive Segments.
                        <br>
                        <br>
                        The design and development group of KHMD has developed HMC based Electronic Control Units for Aircraft, Bio-medical & Automotive applications.
                        
                        With comprehensive Environmental Testing and Reliability capabilities, KHMD can deliver turnkey solutions meeting the stringent requirements of space aircraft and other high-reliability segments.</p>
                    <p class="website">
                        <a href="https://khmdl.com/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/khmd.png" alt="gallery">
                </div>
            </div>
        </div>

        <div id="11" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/ducom.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Ducom Aerospace</h1>
                    <p>We started manufacturing for the space and aviation industries over three decades ago. We currently have offices in New York, USA and Groningen, Netherlands and three production locations in Bangalore, India.

                        We are ISO and AS certified, and have produced parts and assemblies for industries ranging from Space and Aviation to Defense and Robotics. Some of the most recognizable space missions have parts engineered, produced and assembled by Ducom Aerospace on them.</p>
                    <p class="website">
                        <a href="https://ducom-aerospace.com/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="12" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Nettur Technical Training Foundation (NTTF)</h1>
                    <p>Dr. Heinrich Hellstern, Director of HEKS (a Swiss NGO), with his noble gesture, set the ball rolling for opening a technical training institute at Nettur, Tellicherry (Kerala), in 1959. The Nettur Technical Training Foundation (NTTF) was founded in 1963. NTTF is the repository of active technical support and knowledge transfer from the Swiss industry and training institutions. The seed was sown 50 years ago - the decision to start tool and die-making courses in Tellicherry. Today, it has grown into a successful productive plant, surpassing even the oddest expectations. The foundation has successfully developed over the years, ensuring technical training that meets the needs of our society.</p>
                    <p class="website">
                        <a href="https://www.nttftrg.com/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/nttf.png" alt="gallery">
                </div>
            </div>
        </div>

        <div id="13" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/vm.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Valles Marineris Int'l</h1>
                    <p> Valles Marineris is the Indian private company, design analysis and manufacturing   high-tech spacecraft components and technologies, as well as cubesat   platforms.
                        We   are working since 2016, providing to our clients cost -effective solutions,   based on microsatellite and cubesat technology, and a high level of technical   support at all stages of the product life cycle. Our approach is to be   attentive to the customer’s wishes, to work on time and to provide flexible   pricing. 
                        We are also working Hybrid Vehicles ,Hydrogen powered Cars and Electric Cars Research and analysis.
                        The   core of efficient and professional Valles team are young professionals with   real experiences in the design and operation of spacecraft, including   microsatellites and cubesats.
                        <br>
                    </p>
                    <p class="website">
                        <a href="https://vallesmarineris.in/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="14" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>BRICS-FEO</h1>
                    <p>BRICS is the acronym coined to associate five major emerging economies: Brazil, Russia, India, China, and South Africa. The BRICS members are known for their significant influence on regional affairs. Since 2009, 
                        the governments of the BRICS states have met annually at formal summits. India hosted the most recent 13th BRICS summit on 9 September 2021 virtually.</p>
                    <p class="website">
                        <a href="https://infobrics.org/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/brics.png" alt="gallery">
                </div>
            </div>
        </div>

        <div id="15" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/mp.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Micropack Private Ltd.</h1>
                    <p>Micropack Private Limited, is one of the leading manufacturer in India for professional grade PCBs. Established in 1984, we provide solutions to your PCB requirements with a complete product range of multilayer PCBs, Heatsink PCBs, Flexirigid PCBs etc. for both production and quick turn around proto type requirements.

                        A name synonymous with quality and reliability , we have been diligently meeting the stringent needs of all our customers over the years. With a customer base covering various segments - Defence, Space, Avionics, Telecom, Medical Electronics, Industrial Electronics - we have been the preferred PCB solution provider to many of the leading organisations. With a production capacity of 30000 Square meters per annum and committed to quality, we are the fore runners in our industry, setting a benchmark in our area of expertise. We have a separate prototype team to meet your quick turn around requirements.We also take up end to end.</p>
                    <p class="website">
                        <a href="https://www.micro-pack.com/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="16" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Spaceway Limited</h1>
                    <p>Spaceway is focused on engineering and education solutions. As a technology company, we offer services that are at the forefront of innovation in the areas of IoT, Communications and Earth Observation. We also work to enable the next generation of space explorers through developing specialized training. Our aim is to bring value to businesses, communities, and contribute to making the world a better place.</p>
                    <p class="website">
                        <a href="https://www.wearespaceway.com/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/spaceway.png" alt="gallery">
                </div>
            </div>
        </div>

        <div id="17" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/acs.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Aero Club Sremska Mitrovica</h1>
                    <p> </p>
                    <p class="website">
                        <a href="http://www.aksmitrovica.rs" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="18" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Aeronautical Union of Serbia</h1>
                    <p>The Aviation Federation of Serbia is a national branch sports federation on the territory of the Republic of Serbia, consisting of aviation sports organizations, territorial aviation federations and professional sports associations, established for the purpose of dealing with sports aviation.
                        He is an active member of the International Aviation Federation (FAI). It has 60 organizations with about 2,500 members.
                    <br>
                    <br>
                    The goals of the Alliance are: development and improvement of aviation activity and aviation sports; directing young people to be educated in sports in the spirit of tradition, patriotism, tolerance and friendship; gathering of athletes at aviation sports competitions; developing aviation internationalism at the international level, in accordance with the principles of the FAI.</p>
                    <p class="website">
                        <a href="http://www.vss.rs" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/aus.png" alt="gallery">
                </div>
            </div>
        </div>

        <div id="19" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/partners/rse.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>RSE, Serbia</h1>
                    <p>The Association of South-East European Electrochemists (ASEEE) is a unique regional voluntary framework bringing together scientists involved in fundamental and applied electrochemistry and its applications in different fields. Their common goal is to support the regional partnership in R&D and innovation and technological development and increase its efficiency on both regional and international level. The electrochemical science and technology is relatively young scientific field which boosted in the middle of the last century. However, it emerged rapidly and spread widely in important technological fields. This accelerated development increased the need of a new, more effective regional platform for permanent scientific communication, education and collaboration. </p>
                    <p class="website">
                        <a href="https://www.aseee.eu/index.php/rsesee2-home" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div class="bar"></div>
    <!-- </div> -->

    <footer style="width: 100%; margin-top: 150px;">
        <div style="text-align: center; padding: 50px 0;">
            <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
                BEYOND THE HORIZON
            </h5>
        </div>
    </footer>
</section>


<section *ngIf="showClients" class="overview-section ptb-100" style="margin-right: 0;">
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    <div class="row mb-5">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
            <div style="float: right;">
                <a class="nav-link" style="margin-right: 10px;"
                    class="btn btn-primary btn-gradient service-more-desktop-btn" (click)="togglePartners()">Partners</a>
                <a class="nav-link"
                    class="btn btn-primary btn-gradient service-more-desktop-btn" (click)="toggleClients()">Clients</a>                                            
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <h2 style="float: left; color: #018ff4; font-weight: 700; font-size: 25px;">CLIENTS</h2>    
        </div>
    </div>
        <div id="101" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/clients/cu.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Chandigarh University</h1>
                    <p>Indian Technology Congress (ITC) over the last 15 years attained international prominence as a unique platform fostering Industry-Academia-Research 
                        Labs-Policy Makers’ collaboration with the objective of capacity-building for global technological progress.<br>
                        <br>
                        The need for a dedicated Association for Technologists comprising of scientists, engineers, entrepreneurs, and policy-makers has been echoed at many summits, fora, including at the previous editions of ITC; and has been gaining strength and momentum with the call to establish an Association under the Societies Registration Act. During the recently concluded ITC-2017, a resolution was unanimously adopted in the Bangalore Declaration that Technology Professionals from the industry, R&D Laboratories, and Academic Institutions should collaborate and 'institutionalize' the synergy by establishing the Indian Technology Congress Association (ITCA).</p>

                    <p class="website">
                        <a href="https://www.itca.org.in/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="102" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Jeppiaar Institute of Technology</h1>
                    <p>India decided to go to space when Indian National Committee for Space Research (INCOSPAR) was set up by the Government of India in 1962. 
                        With the visionary Dr Vikram Sarabhai at its helm, INCOSPAR set up the Thumba Equatorial Rocket Launching Station (TERLS) in 
                        Thiruvananthapuram for upper atmospheric research.</p>
                    <p class="website">
                        <a href="https://www.isro.gov.in/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                    
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/clients/jit.png"  alt="gallery">
                </div>
            </div>
        </div>

        <div id="103" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/clients/siet.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Shri Shakthi Institute of Engineering and Technology</h1>
                    <p>A World CanSat/Rocketry Championship is generally an international
                        competition open to elite competitors from around the world, representing their nations (as university
                        student Teams or as independent student Teams), and winning this event will be considered the highest
                        or near highest achievement in this field.
                        </p>
                    <p class="website">
                        <a href="https://www.wcrc.world/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>

        <div id="104" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>NTTF</h1>
                    <p>India decided to go to space when Indian National Committee for Space Research (INCOSPAR) was set up by the Government of India in 1962. 
                        With the visionary Dr Vikram Sarabhai at its helm, INCOSPAR set up the Thumba Equatorial Rocket Launching Station (TERLS) in 
                        Thiruvananthapuram for upper atmospheric research.</p>
                    <p class="website">
                        <a href="https://www.isro.gov.in/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                    
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/clients/nttf.png"  alt="gallery">
                </div>
            </div>
        </div>

        <div id="105" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/clients/mgr.png" alt="gallery">
                </div>

                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Dr M.G.R Educational and Research Institute</h1>
                    <p>A World CanSat/Rocketry Championship is generally an international
                        competition open to elite competitors from around the world, representing their nations (as university
                        student Teams or as independent student Teams), and winning this event will be considered the highest
                        or near highest achievement in this field.
                        </p>
                    <p class="website">
                        <a href="https://www.wcrc.world/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                </div>
            </div>
        </div>


        <div id="106" class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 overview-content">
                    <h1>Hindustan Institute of Technology & Science</h1>
                    <p>India decided to go to space when Indian National Committee for Space Research (INCOSPAR) was set up by the Government of India in 1962. 
                        With the visionary Dr Vikram Sarabhai at its helm, INCOSPAR set up the Thumba Equatorial Rocket Launching Station (TERLS) in 
                        Thiruvananthapuram for upper atmospheric research.</p>
                    <p class="website">
                        <a href="https://www.isro.gov.in/" target="_blank" class="btn btn-primary btn-gradient">Read More</a>
                    </p>
                    
                </div>

                <div class="col-lg-6 col-md-12 overview-img">
                    <img src="assets/img/clients/hindustan.png"  alt="gallery">
                </div>
            </div>
        </div>
        <div class="bar"></div>

    <footer style="width: 100%; margin-top: 150px;">
        <div style="text-align: center; padding: 50px 0;">
            <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
                BEYOND THE HORIZON
            </h5>
        </div>
    </footer>
</section>