<section id="target" class="image-section">
    
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    <div class="image-container" style="margin-bottom: 5%;">
        <div class="container">
            <div class="row" style="margin-right: 0;">
                <div class="col-lg-12">
                    <h2>EVENTS</h2>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row event-container" *ngFor="let ev of eventsArray | paginate: { itemsPerPage: 5, currentPage: p } ; index as i">
                <div class="col-lg-5 col-md-5 event-image">
                    <div class="image">
                        <!-- <img src="{{ev.image}}" alt="Avatar" > -->
                        <img [src]="imagePaths[ev.id]" alt="img" style=" aspect-ratio: 4 / 3;" loading="lazy"> 
                    </div>
                </div>
                <div class="col-lg-7 col-md-7 event-content">
                    <div class="row">
                        <div class="col-lg-8 col-md-8">
                            <h1>
                                {{ev.title}}
                            </h1>
                            <h6 class="event-location">{{ev.mode}}</h6>
                        </div>  
                        <div class="col-lg-4 col-md-4" style="text-align: right;">
                            <p>
                                {{ev.startDate | date: 'dd/MM/yyyy'}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p>
                                {{ev.descriptionOne}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12"  style="padding: 20px 0;">
                            <a class="btn btn-primary btn-gradient" (click)="goToEventDetailPage(ev.id);">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div style="text-align: center; margin-top: 2rem;">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>
    </div>
    
</section>

<footer style="width: 100%; background-color: #000000;">
    <div style="text-align: center; padding: 100px 0;">
        <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
            BEYOND THE HORIZON
        </h5>
    </div>
</footer>
