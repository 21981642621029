import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CareerFormTitleService {

    constructor() {}

    private _messageSource = new Subject<Object>();
    message$ = this._messageSource.asObservable();

    sendMessage(message: Object) {
        this._messageSource.next(message);
    }
}