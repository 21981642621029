import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NewsService } from 'src/app/service/news-service';

@Component({
    selector: 'app-startup-agency',
    templateUrl: './startup-agency.component.html',
    styleUrls: ['./startup-agency.component.scss']
})
export class StartupAgencyComponent implements OnInit {

    newsContent: any[];

    origiImg: any;

    imagePaths = [];

    public customOptions: OwlOptions = {
        loop: true,
        touchDrag: true,
        pullDrag: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        autoplayTimeout: 5000,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 3
            }
        },
        nav: false
    };

    constructor(
        private titleService: Title,
        private newsService: NewsService,
        private router: Router
    ) {
        // this.titleService.setTitle('Kilian - Angular 10 Startup Agency Landing Page');
    }

    ngOnInit() {
        this.toTop();

        this.newsService.getAllNews().subscribe((Response) => {
            console.log(Response);
            this.newsContent = Response.data;

            Response.data.forEach(element => {
                this.downloadImage(element.imagePath, element.id);
            });
        }, (Error) => {
            console.log(Error);
        })
    }

    toTop() {
        document.getElementById("target").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    downloadImage(photoPath, id) {
        let imagePath = photoPath.split("news/image/");
        this.newsService.getNewsImage(imagePath[1]).subscribe((Response) => {
            this.createImageFromBlob(Response, id);
        }, (Error) => {
            console.log(Error);
        })
    }

    createImageFromBlob(image: Blob, id: number) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.origiImg = reader.result;
            this.imagePaths.push(this.origiImg);
            this.imagePaths[id] = this.origiImg;
        }, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    }

    goToNewsDetail(newsId: number) {
        console.log("News Id " + newsId);
        this.router.navigate(["/news-details"], { queryParams: { newsId: newsId } });
    }
}