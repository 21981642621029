<!-- Start Form Area -->
<section id="target" class="contact-area ptb-100 bg-black" style="padding-top: 100px;"> 
    
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    <div class="container container-padding">
        <div class="section-title" style="max-width: 1207px;">
            <h4 style="color: #018FF4">{{eventObj.title}}</h4>
            <p style="float: right; color: #fff; font-size: 18px;">Location : <span style="color: #018FF4;">{{eventObj.location}}</span> </p>
        </div>

        <div class="h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                <!-- <div class="tab" style="background-color: #131922; "> -->
                    <div class="tab_content tab_content_padding" >
                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="tabs_item_content">
                                        <form #careerApplyForm="ngForm" novalidate (ngSubmit)="eventRegistration(careerApplyForm)">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group" >
                                                        <input class="form-control" id="fName" name="fName" type="text"
                                                            [ngClass]="{'is-invalid': !fName.valid && (fName.dirty || fName.touched || isSubmit)}" #fName="ngModel"
                                                            required [(ngModel)]="firstName">
                                                        <label>First Name </label>
                                                        <span style="font-size: 12px;" *ngIf="!fName.valid && (fName.dirty || fName.touched || isSubmit)"
                                                            id="validation-fName-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">First Name is
                                                            Required.</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group">
                                                        <input class="form-control" id="lName" name="lName" type="text"
                                                            [ngClass]="{'is-invalid': !lName.valid && (lName.dirty || lName.touched || isSubmit)}" #lName="ngModel"
                                                            required [(ngModel)]="lastName">
                                                        <label>Last Name </label>
                                                        <span style="font-size: 12px;" *ngIf="!lName.valid && (lName.dirty || lName.touched || isSubmit)"
                                                            id="validation-lName-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">Last Name is
                                                            Required.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group">
                                                        <input class="form-control" id="mobile" name="mobile" type="text"  maxlength="12" minlength="10" (keypress)="keyPressNumbersDecimal($event)"
                                                            [ngClass]="{'is-invalid': !mobile.valid && (mobile.dirty || mobile.touched || isSubmit)}" #mobile="ngModel"
                                                            required [(ngModel)]="mobileNumber">
                                                        <label>Mobile Number</label>
                                                        <span style="font-size: 12px;" *ngIf="!mobile.valid && (mobile.dirty || mobile.touched || isSubmit)"
                                                            id="validation-mobile-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">Mobile Number is
                                                            Required.</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div class="form-group">
                                                        <input class="form-control" id="email" name="email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" 
                                                            [ngClass]="{'is-invalid': !email.valid && (email.dirty || email.touched || isSubmit)}" #email="ngModel"
                                                            required [(ngModel)]="eMail">
                                                        <label>Email</label>
                                                        <span style="font-size: 12px;" *ngIf="!email.valid && (email.dirty || email.touched || isSubmit)"
                                                            id="validation-email-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">E-mail is
                                                            Required.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div class="form-group">
                                                        <input class="form-control" id="linkedIn" name="linkedIn" type="text"
                                                            [ngClass]="{'is-invalid': !linkedIn.valid && (linkedIn.dirty || linkedIn.touched || isSubmit)}" #linkedIn="ngModel"
                                                            required [(ngModel)]="linkedinProfile">
                                                        <label>Linkedin Profile</label>
                                                        <span style="font-size: 12px;" *ngIf="!linkedIn.valid && (linkedIn.dirty || linkedIn.touched || isSubmit)"
                                                            id="validation-linkedIn-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">Linkedin Profile is
                                                            Required.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div class="form-group">
                                                        <input class="form-control" id="city" name="city" type="text"
                                                            [ngClass]="{'is-invalid': !city.valid && (city.dirty || city.touched || isSubmit)}" #city="ngModel"
                                                            required [(ngModel)]="userCity">
                                                        <label>City</label>
                                                        <span style="font-size: 12px;" *ngIf="!city.valid && (city.dirty || city.touched || isSubmit)"
                                                            id="validation-city-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">City Profile is
                                                            Required.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            
                                            <!-- <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <label id="myLabel" class="main">I agree with the <a target="_blank" href="/">Terms & Conditions</a> and the 
                                                        <a target="_blank" href="/">Privacy Policy</a> 
                                                        <input type="checkbox">
                                                        <span style="margin-top: 0;" class="geekmark"></span>
                                                    </label>
                                                    <p class="my_permission">By sending my application, I give my permission to TSC to administrate my personal details included in my CV with the purpose of participating in a recruitment process for an open position in the company.</p>
                                                </div>
                                            </div> -->
                                            <div class="row mb-2">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12" style="text-align: right;">
                                                    <div class="row mb-4">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12"
                                                            style="text-align: right;">
                                                            <!-- <button type="submit" class="btn btn-primary btn-gradient">Send Message</button> -->
        
                                                            <button>
                                                                <div class="svg-wrapper-1">
                                                                    <div class="svg-wrapper">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 24 24" width="24" height="24">
                                                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                                                            <path fill="currentColor"
                                                                                d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z">
                                                                            </path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <span>Send</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <!-- <button type="submit" class="btn btn-primary btn-gradient">SEND APPLICATION</button> -->
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>                      
                    </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
</section>
<!-- End Form Area -->