import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  showClients: boolean = false;
  showPartners: boolean = true;

  public id : number = 1;
  constructor(private route: ActivatedRoute) {
      this.route.queryParams.subscribe( params => {
          console.log(params);
          if(params.partnerId == null){
              this.id = 1;
          } else {
              this.id = parseInt(params.partnerId);  
          }
      });
    }

  ngOnInit() {
      console.log(this.id);

      if(this.id > 100) {
        this.toggleClients();
      } else {
        this.togglePartners();
      }

      setTimeout(()=> {
          if(this.id != 1) {
            this.jumpToProducts(this.id);
          } else {
              console.log("else");
              this.toTop();
          }
      },1)
  }

  toggleClients() {
    this.showClients = true;
    this.showPartners = false;
  }

  togglePartners() {
    this.showClients = false;
    this.showPartners = true;
  }

  toTop() {
      document.getElementById("target").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
      });
      
      setTimeout(()=>{
        this.jumpToProducts(this.id);
      }, 1000)
  }

  jumpToProducts(partnerId) {
      this.id= parseInt(partnerId);
      document.getElementById(partnerId).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest"
      });
  }
}
