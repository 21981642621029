import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

    public id : number = 1;
    constructor(private route: ActivatedRoute) {
        this.route.queryParams.subscribe( params => {
            console.log(params);
            if(params.productId == null || params.productId == undefined){
                this.id = 1;
            } else {
                this.id = parseInt(params.productId);  
            }
        });
      }

    ngOnInit() {

        console.log(this.id);
        this.toTop();

        setTimeout(()=> {
            if(this.id == 1) {
                this.toTop();
            } else {
                this.jumpToProducts(this.id);
            }
        },1)
    }

    toTop() {
        document.getElementById("target").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    jumpToProducts(productId) {
        this.id= parseInt(productId);
        document.getElementById(productId).scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest"
        });
    }
}