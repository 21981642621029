import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";
import { LkpCategoryService } from "src/app/service/lkp-category-service";
import { PublicationService } from "src/app/service/publication-service";

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss']
})
export class PublicationsComponent implements OnInit {

  public modal: any;
  public id : number = 1;

  lkpCategories:any;

  publicationObj = [];
  
  images = [];
  origiImg: any;
  imagePaths = [];
  categoryOnePublications = [];

  imagesTwo = [];
  origiImgTwo : any;
  imagePathsTwo = [];
  categoryTwoPublications = [];
  showCategoryOne: boolean = false;
  showCategoryTwo: boolean = false;

  imagesThree = [];
  origiImgThree : any;
  imagePathsThree = [];
  categoryThreePublications = [];
  showCategoryThree: boolean = false;
  
  public customOptions: OwlOptions = {
    loop: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    autoplayTimeout: 5000,
    navText: ['', ''],
    responsive: {
        0: {
            items: 1
        },
        400: {
            items: 2
        },
        740: {
            items: 3
        },
        940: {
            items: 3
        }
    },
    nav: false
};

  constructor(
    private route: ActivatedRoute,
    private publicationService: PublicationService,
    private lkpCategoryService: LkpCategoryService,
  ) {
    this.route.queryParams.subscribe( params => {
        console.log(params);
        if(params.sectionId == null){
            this.id = 1;
            this.jumpTosection(this.id);
        } else {
          this.id = params.sectionId;   
        }
    });
  }

  ngOnInit() {
    this.modal = document.getElementById("myModal");

    this.lkpCategoryService.getAllCategory().subscribe((Response)=> {
      console.log(Response);
      this.lkpCategories = Response.data;
      console.log(this.lkpCategories);

    }, (Error) => {
      console.log(Error);
    });

    setTimeout(() => {
      this.getPublicationByCategoryOne();
      this.getPublicationByCategoryTwo();
      this.getPublicationByCategoryThree();
    }, 1)
  }

  //for Category One
  getPublicationByCategoryOne() {
      
    this.publicationService.getPublicationsByCategoryId(1).subscribe((Response) => {
      console.log(Response);
      this.categoryOnePublications.push(Response.data);
      console.log(this.categoryOnePublications);

      this.categoryOnePublications[0].publicationList.forEach(element => {
        console.log(element);
        
        this.downloadImage(element.imagePath, element.id);
      });
    }, (Error) => {
      console.log(Error);
    })
  }

  downloadImage(photoPath, id) {
    console.log(photoPath);

    let imagePath = photoPath.split("publications/image/");
    
    this.publicationService.getPublicationImage(imagePath[1]).subscribe((Response)=> {
      this.createImageFromBlob(Response, id);
    }, (Error)=> { 
      console.log(Error);
    })
  }

  createImageFromBlob(image: Blob, id: number) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.origiImg = reader.result;
      this.imagePaths.push(this.origiImg);
      this.imagePaths[id] = this.origiImg;            
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  //for Category Two
  getPublicationByCategoryTwo() {      
    this.publicationService.getPublicationsByCategoryId(2).subscribe((Response) => {
      this.categoryTwoPublications.push(Response.data);
      console.log(this.categoryTwoPublications[0].publicationList);
      if(this.categoryTwoPublications[0].publicationList.length != 0) {
        this.showCategoryTwo = true; 
      }

      this.categoryTwoPublications[0].publicationList.forEach(element => {
        this.downloadImageTwo(element.imagePath, element.id);
      });
    }, (Error) => {
      console.log(Error);
    })
  }

  downloadImageTwo(photoPath, id) {
    let imagePathsTwo = photoPath.split("publications/image/");
    this.publicationService.getPublicationImage(imagePathsTwo[1]).subscribe((Response)=> {
      this.createImageFromBlobTwo(Response, id);
    }, (Error)=> { 
      console.log(Error);
    })
  }

  createImageFromBlobTwo(image: Blob, id: number) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.origiImgTwo = reader.result;
      this.imagePathsTwo.push(this.origiImgTwo);
      this.imagePathsTwo[id] = this.origiImgTwo;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  //for Category Three
  getPublicationByCategoryThree() {      
    this.publicationService.getPublicationsByCategoryId(3).subscribe((Response) => {
      this.categoryThreePublications.push(Response.data);
      
      this.categoryThreePublications[0].publicationList.forEach(element => {
        this.downloadImageThree(element.imagePath, element.id);
      });
    }, (Error) => {
      console.log(Error);
    })
  }

  downloadImageThree(photoPath, id) {
    let imagePathsThree = photoPath.split("publications/image/");
    this.publicationService.getPublicationImage(imagePathsThree[1]).subscribe((Response)=> {
      this.createImageFromBlobThree(Response, id);
    }, (Error)=> { 
      console.log(Error);
    })
  }

  createImageFromBlobThree(image: Blob, id: number) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.origiImgThree = reader.result;
      this.imagePathsThree.push(this.origiImgThree);
      this.imagePathsThree[id] = this.origiImgThree;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }


  toTop() {
    document.getElementById("target").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  toDownloads() {
    document.getElementById('showDownloads').scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  toPublications() {
    document.getElementById('showPublications').scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  jumpTosection(sectionId) {
    this.id = parseInt(sectionId);
    document.getElementById(sectionId).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  openModal() {
    this.modal.style.display = "block";
  }

  closeModal() {
    this.modal.style.display = "none";
  }
}