<!-- Section 1 Start -->
<section id="target" class="section_body">
    
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    <div class="container" style="padding: 10px;">
        <div class="header">
            <h2 style="font-size: 25px; font-weight: 700; text-transform: uppercase;">About Us</h2>
        </div>
        <div class="founder">
            <h4>Founded in 2020, TSC is a leading space-tech organization democratizing space by bringing it to all education platforms.
                From subsystems to constellations, we provide turnkey solutions for all your space-tech requirements along with ground, launch & post-launch support</h4>

            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12" style="margin-top: 4%;">
                    <div class="about-content">
                        <div class="section-title">
                            <h2>TSC Tech in Space</h2>
                            <br>
                            <p>Humankind's exploration into space is reaching a booming growth as we are moving towards settlements outside the planet. TSC Tech Labs, as a part of this exploration, provides help and support in exploring space. We provide mission support for all the clients from mission definition to development, testing, launch, and post-launch operations. We also deliver a plethora of ground operations through which the client explores the space domain.</p>
                        </div>
                        <p> We also bring space technology to all levels of education without the presumption of technical knowledge. Teaching aspects from abstract space engineering and aerospace theories, to in-depth tutorials and application of space tech, we support the growth of satellite and space research, thereby contributing to the drive of humankind to the furthest reaches of the cosmos.</p>
                      
                    </div>
                </div>
                <div class="col-lg-6 col-md-12" style="margin-top: 4%;">
                    <img src="assets/img/about/core.png" alt="image">
                </div>
            </div>
        </div>
        <div style="margin-top: 2%; margin-bottom: 3%" class="bar"></div>
    </div>
</section>
<!-- Section 1 End -->


<!-- TSC Tech Labs in numbers start -->
<section style="background-color: #000;">
    <div class="tsc-in-numbers">
        <div class="container">
            <div class="row h-100">
                <div class="col-lg-12 col-md-12 text-center">
                    <p class="hiring_message">TSC Tech Labs in numbers</p>
                </div>
            </div>
            <div class="tsc-grid">
                <div class="tsc-grid-item">
                    <p class="p-font-one" style="font-size:26px;">80 +</p>
                    <p class="p-font-two" style="font-size:16px;">
                        Satellites Designed
                    </p>
                </div>
                <div class="tsc-grid-item">
                    <p class="p-font-one" style="font-size:26px;">35 +</p>
                    <p class="p-font-two" style="font-size:16px;">
                        Patents Filed
                    </p>
                </div>
                <div class="tsc-grid-item">
                    <p class="p-font-one" style="font-size:26px;">20000 +</p>
                    <p  class="p-font-two" style="font-size:16px;">
                        Students Trained
                    </p>
                </div>
                <div class="tsc-grid-item">
                    <p class="p-font-one" style="font-size:26px;">3</p>
                    <p  class="p-font-two" style="font-size:16px;">
                        Satellites Launched
                    </p>
                </div>
                <div class="tsc-grid-item">
                    <p class="p-font-one" style="font-size:26px;">40 +</p>
                    <p class="p-font-two" style="font-size:16px;">
                        International Partners
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- TSC Tech Labs in numbers end -->

<!-- Section 2 Mentor's with Content Start -->
<section class="section_body">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row-one-col-one">
                    <div class="row-col-img">
                        <img [lazyLoad]="imageSourceOne" alt="image">
                    </div>
                    <div class="row-col-content">
                        <div class="about-content">
                            <div class="section-title" style="text-align: center;">
                                <h2>IEI Centenary Innovation Award</h2>
                                <br>
                                <p>TSC Tech Labs received an award for best Startup in Aerospace Industry by the Indian Institute of Technology, Kanpur (IIT) and The Institution for Engineers, India (IEI) </p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row-one-col-two">
                    <div class="row-col-content">
                        <div class="about-content">
                            <div class="section-title"  style="text-align: center;">
                                <h2>75 Students' Satellites: Mission 2022</h2>
                                <br>
                                <p>TSC Tech Labs is the Technical Lead for the National Mission endorsed by Hon'ble PM, Narendra Modi Ji at 76th UN General Assembly aiding in building 75 Students' Satellites.</p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                    <div class="row-col-img">
                        <img [lazyLoad]="imageSourceTwo" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row-one-col-one">
                    <div class="row-col-img">
                        <img [lazyLoad]="imageSourceThree" alt="image">
                    </div>
                    <div class="row-col-content">
                        <div class="about-content">
                            <div class="section-title"  style="text-align: center;">
                                <h2>Foreign Ties</h2>
                                <br>
                                <p>TSC Tech Labs has been invited by Indian Embassy in Serbia to discuss and collaboratively work towards building a space agency in Serbia</p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row-one-col-two">
                    <div class="row-col-content">
                        <div class="about-content">
                            <div class="section-title"  style="text-align: center;">
                                <h2>Space-Flight Heritage</h2>
                                <br>
                                <p>We are space-proven with our 3 satellites launched into Low Earth Orbit aboard Amazonia Mission with PSLV C-51 on 28 February 2021
                                </p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                    <div class="row-col-img">
                        <img [lazyLoad]="imageSourceFour" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-center align-items-center">
            <div class="col-lg-3 col-md-12" style="margin-top: 4%;">
                <img src="assets/img/about/iei.png" alt="image">
            </div>
            <div class="col-lg-3 col-md-12" style="margin-top: 4%;">
                <div class="about-content">
                    <div class="section-title" style="text-align: center;">
                        <h2>IEI Centenary Innovation Award</h2>
                        <br>
                        <p>TSC Tech received an award for best Start up in Aerospace Industry by Indian Institute of Technology, Kanpur (IIT) and The Institution for Engineers, India (IEI) </p>
                    </div>
                    <p></p>
                </div>
            </div>

            <div class="col-lg-3 col-md-12" style="margin-top: 4%;">
                <div class="about-content">
                    <div class="section-title"  style="text-align: center;">
                        <h2>75 Students' Satellites: Mission 2022</h2>
                        <br>
                        <p>TSC is the Technical Lead for the National Mission endorsed by Hon'ble PM, Narendra Modi Ji at UN General Assembly aiding in building 75 Students' Satellites.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12" style="margin-top: 4%;">
                <img src="assets/img/about/75sats.png" alt="image">
            </div>
        </div> -->

        <!-- <div class="row justify-content-center align-items-center">
            <div class="col-lg-3 col-md-12" style="margin-top: 4%;">
                <img src="assets/img/about/foreign.png" alt="image">
            </div>
            <div class="col-lg-3 col-md-12" style="margin-top: 4%;">
                <div class="about-content">
                    <div class="section-title"  style="text-align: center;">
                        <h2>Foreign Ties</h2>
                        <br>
                        <p>TSC has been invited by Indian Embassy at Serbia to discuss and collaboratively work towards building a space agency in Serbia</p>
                    </div>
                    <p></p>
                </div>
            </div>

            <div class="col-lg-3 col-md-12" style="margin-top: 4%;">
                <div class="about-content">
                    <div class="section-title"  style="text-align: center;">
                        <h2>Space-Flight Heritage</h2>
                        <br>
                        <p>We are space proven with our 3 satellites launched into Low Earth Orbit aboard Amazonia Mission with PSLV C-51 on 28 February 2021
                        </p>
                    </div>
                    <p></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-12" style="margin-top: 4%;">
                <img src="assets/img/about/flight.png" alt="image">
            </div>
        </div> -->
        <div class="bar"></div>
    </div>
</section>
<!-- Section 2 Mentor's with Content End -->

<!-- Section 2 Mentor's Images Start -->
<section class="section_body">
    <div class="container">
        <div >
            <h2 style="font-size: 25px; font-weight: 700; text-transform: uppercase; color: #018FF4; margin-bottom: 2%;">MENTORS</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-12" style="margin-bottom: 4%;">
                
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img src="assets/img/mentors/vasagam.png" alt="Avatar" style="width:100%;">
                        </div>
                        <div class="row flip-card-back">
                            <h1>Padma Shri Prof. R M Vasagam</h1>
                            <p>Vice-President, Indian Technology Congress Association (ITCA)</p>
                            <p>Former Project Director, "APPLE" India's First Geo-stationary Communication Satellite</p>
                            <p>Former VC, Anna University</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12" style="margin-bottom: 4%;">
                <!-- <img src="assets/img/extras/two.jpg" alt="image"> -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img src="assets/img/mentors/annadurai.png" alt="Avatar" style="width:100%;">
                        </div>
                        <div class="flip-card-back">
                            <h1>Padma Shri Dr. Mylswamy Annadurai</h1>
                            <p>Vice president, Tamil Nadu State Council for Science and Technology</p>
                            <p>Chairman, board of governors, National Research and Design Forum (NDRF)</p>
                            <p>Former Director, ISRO Satellite Centre</p>
                            <p>Former Project Director, Chandrayaan 1 & 2 and Mangalyaan (Mars Orbiter Mission)</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12" style="margin-bottom: 4%;">
                <!-- <img src="assets/img/extras/three.jpg" alt="image"> -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img src="assets/img/mentors/dusan.png" alt="Avatar" style="width:100%;">
                        </div>
                        <div class="flip-card-back">
                            <h1>Dusan Radosavljevic</h1>
                            <p>Founder & Head, Committee for Space Development (CSPD), Serbia</p>
                            <p>Founding Committee, World CanSat/Rocketery Consortium (WCRC)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-12" style="margin-bottom: 2%;">
                <!-- <img src="assets/img/mentors/dusan.png" alt="image" > -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img src="assets/img/mentors/gk.png" alt="Avatar" style="width:100%;">
                        </div>
                        <div class="row flip-card-back">
                            <h1>Dr. K Gopalakrishnan</h1>
                            <p>Secretary General, Indian Technology Congress Association (ITCA)</p>
                            <p>Project Director, 75 Students' Satellites Mission</p>
                            <p>Advisor, Nagarjuna Group of Institutions</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12" style="margin-bottom: 2%;">
                <!-- <img src="assets/img/extras/two.jpg" alt="image"> -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img src="assets/img/mentors/gnv.png" alt="Avatar" style="width:100%;">
                        </div>
                        <div class="flip-card-back">
                            <h1>G N V Prasad</h1>
                            <p>Former Deputy Director, URSC, ISRO</p>
                            <p>Technical Advisor, Indian Technology Congress Association (ISRO)</p>
                            <p>Mentor, 75 Students' Satellites Mission</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12" style="margin-bottom: 2%;">
                <!-- <img src="assets/img/extras/three.jpg" alt="image"> -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img src="assets/img/mentors/rajan.png" alt="Avatar" style="width:100%;">
                        </div>
                        <div class="flip-card-back">
                            <h1>Padma Shri Y S Rajan</h1>
                            <p>Advisor, 75 Students' Satellites Mission</p>
                            <p>Honorary Distinguished Professor and Scientist, ISRO,</p>
                            <p>Former Vice Chancellor, Punjab Technical University</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-12" style="margin-bottom: 4%;">
                <!-- <img src="assets/img/mentors/dusan.png" alt="image" > -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img src="assets/img/mentors/lvm.png" alt="Avatar" style="width:100%;">
                        </div>
                        <div class="row flip-card-back">
                            <h1>Dr. L V Muralikrishna Reddy</h1>
                            <p>President, ITCA</p>
                            <p>Chairman, 75 Students' Satellites Mission</p>
                            <p>President, Foundation for educational excellence</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12" style="margin-bottom: 4%;">
                <!-- <img src="assets/img/extras/two.jpg" alt="image"> -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img src="assets/img/mentors/wooday.png" alt="Avatar" style="width:100%;">
                        </div>
                        <div class="flip-card-back">
                            <h1>Nadoja Dr. Wooday Krishna</h1>
                            <p>Member, Indian Technology Congress Association (ITCA)</p>
                            <p>Hon. General Secretary, Seshadripuram Educational Trust</p>
                            <p>Vice-President, University Space Engineering Consortium India</p>
                            <p>President, Karnataka Gandhi Smarak Nidhi</p>
                                
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12" style="margin-bottom: 4%;">
                <!-- <img src="assets/img/extras/three.jpg" alt="image"> -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img src="assets/img/mentors/mahadevan.png" alt="Avatar" style="width:100%;">
                        </div>
                        <div class="flip-card-back">
                            <h1>Mahadevan</h1>
                            <p>Former Group Director, Mission Development Group (MDG), URSC, ISRO</p>
                            <p>Mentor, Indian Technology Congress Association (ITCA)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top: 4%" class="bar"></div>
    </div>
</section>
<!-- Section 2 Mentor's Images End -->

<!-- Section Our Beliefs Start -->
<section class="section_body">
    <div class="container">
        <div class="header" style="margin-bottom: 0;">
            <h2 style="font-size: 25px; font-weight: 700; text-transform: uppercase; margin-bottom: 30px;">BEYOND THE HORIZON</h2>
            <h1 style="margin-bottom: 0; color: #fff;">Founded in 2020 at the edge of India, TSC’s history is proof that
                space is truly within our reach</h1>
        </div>
    </div>
</section>
<!-- Section Our Beliefs End -->

<!-- Section Way Forward Start -->
<section class="saas-tools ptb-100 bg-gray">
    <div class="container">
      
        <div class="row forward-body">
            <div class="col-lg-4 col-md-4 active" >
                <p class="number">01</p>
                <h4>
                    Our Vision
                </h4>
                <p style="font-size: 20px;">
                    TSC Tech Labs operates with a long-term vision of bringing unseen technological and research / development breakthroughs for the betterment of life on earth and outside.
                </p>
            </div>
            <div class="col-lg-4 col-md-4 active">
                <p class="number">02</p>
                <h4>
                    Our Mission
                </h4>
                <p style="font-size: 20px;">
                    TSC Tech Labs aims to equip the budding generation and the present workforce to be able to contribute to the revolutionization of the space sector.
                </p>
            </div>
            <div class="col-lg-4 col-md-4 active">
                <p class="number">03</p>
                <h4>
                    What We Do
                </h4>
                <p style="font-size: 20px;">
                    TSC Tech Labs is currently focusing on catering to the nanosatellite market across the globe. We work towards closing the academia-industry gap to ensure rapid growth in technological advancements for the space domain.
                </p>
            </div>
            <div class="row" style="background-color: #131923; padding: 50px 10px;">
                <div class="col-lg-6 col-md-6 active">
                    <p class="number" style="margin-bottom: 20px;">04</p>
                    <h2>
                        The Way Forward
                    </h2>
                </div>
                <div class="col-lg-6 col-md-6">
                    <p style="font-size: 18px; text-align: justify;">
                        With 75 Students' Satellites Mission in progress, we aim to complete it in grandeur aiding students to get their hands on CubeSats and breaking myths about how space is not accessible to everyone. Carrying the same momentum forward, TSC is working on some breakthrough innovations that can be implemented with our upcoming projects in the pipeline. 


                    </p>
                </div>
            </div>
        </div>
        <div style="margin-top: 70px;" class="bar"></div>
    </div>
</section>
<!-- Section Way Forward End -->

<!-- Section mission-details -->
<section class="mission-details">
    <div class="container">

        <div class="row mt-5 mission-book">
            <h2>
                We’ll Take Care Of The Everything
            </h2>
            <p>
                ALL COVERED BY TSC'S END TO END SERVICE
            </p>
            <div class="row" style="margin-right: 0;">
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Complete Satellite Integration

                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="46" viewBox="0 0 38 46"><defs><style>.integration-a,.integration-b{fill:none;stroke:#29b7ff;stroke-miterlimit:10;stroke-width:2px}.integration-a{stroke-linecap:square}</style></defs><g transform="translate(3 11)"><g transform="translate(-4 -4)"><path class="integration-a" d="M31.428,25v6.427H25" transform="translate(6.572 6.572)"></path><line class="integration-a" x1="8" transform="translate(16 38)"></line><path class="integration-a" d="M8.43,31.428H2V25" transform="translate(0 6.572)"></path><path class="integration-a" d="M2,8.43V2"></path><path class="integration-a" d="M30,2V8.43" transform="translate(8)"></path><line class="integration-a" y1="8" transform="translate(38 16)"></line><line class="integration-a" y1="8" transform="translate(2 16)"></line></g><g transform="translate(0 36)"><line class="integration-b" y2="10" transform="translate(16 -19)"></line><path class="integration-a" d="M17.454,18l-3.727,3.727L10,18" transform="translate(2.273 -30.201)"></path></g><path class="integration-a" d="M30,8V2H2V30H30V24" transform="translate(32 -12) rotate(90)"></path></g></svg>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Space Qualification & Testing
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.2" height="46" viewBox="0 0 37.2 46"><defs><style>.space-q-a{fill:none;stroke:#29b7ff;stroke-linecap:square;stroke-miterlimit:10;stroke-width:2px}</style></defs><g transform="translate(-3)"><path class="space-q-a" d="M23.2,5.4a4.4,4.4,0,1,0-8.8,0H10v5.867H27.6V5.4Z" transform="translate(2.8)"></path><path class="space-q-a" d="M34.8,5h4.4V43.133H4V5H8.4" transform="translate(0 1.867)"></path><line class="space-q-a" x2="6" transform="translate(24.6 21)"></line><path class="space-q-a" d="M9,9l1,1,3-3" transform="translate(3.6 12)"></path><line class="space-q-a" x2="6" transform="translate(24.6 28)"></line><line class="space-q-a" x2="6" transform="translate(24.6 35)"></line><path class="space-q-a" d="M9,16l1,1,3-3" transform="translate(3.6 12)"></path><path class="space-q-a" d="M9,16l1,1,3-3" transform="translate(3.6 19)"></path></g></svg>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Sat Registration & Legal Work
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="46" viewBox="0 0 44 46"><defs><style>.legal-a,.legal-b{fill:none;stroke:#29b7ff;stroke-miterlimit:10;stroke-width:2px}.legal-a{stroke-linecap:square}</style></defs><g transform="translate(-2 -1)"><path class="legal-a" d="M13,39v2c0,4.5-5,5-5,5H38a5,5,0,0,0,5-5V39Z"></path><path class="legal-a" d="M37,2H3V41a5,5,0,0,0,5,5"></path><line class="legal-a" x2="12" transform="translate(11 12)"></line><line class="legal-a" x2="12" transform="translate(11 20)"></line><line class="legal-a" x2="9" transform="translate(11 28)"></line><path class="legal-b" d="M43,20.291V33l-6-2-6,2V20.291"></path><circle class="legal-a" cx="8" cy="8" r="8" transform="translate(29 7)"></circle></g></svg>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Launch Provision & Support</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 46.225 46.227"><defs><style>.launch-a{fill:#030504}.launch-a,.launch-b,.launch-c{stroke:#29b7ff;stroke-width:2px}.launch-b{fill:none}.launch-c{fill:rgba(0,0,0,0)}</style></defs><g transform="translate(230.631 189.466)"><line class="launch-a" x2="12.177" y2="12.177" transform="translate(-213.306 -172.74)"></line><path class="launch-b" d="M-226.7-136.5a4.318,4.318,0,0,1,6.1.183,4.318,4.318,0,0,1,0,5.921c-2.156,2.161-8.807,2.706-8.807,2.706S-228.853-134.345-226.7-136.5Z" transform="translate(0 -16.774)"></path><path class="launch-b" d="M-208.05-168.9a3.312,3.312,0,0,0,0-4.683h0a3.311,3.311,0,0,0-4.683,0l-16.392,16.391,2.1,2.1" transform="translate(-0.092 -4.323)"></path><path class="launch-b" d="M-193.03-155.2a3.311,3.311,0,0,1,4.683,0h0a3.311,3.311,0,0,1,0,4.683l-16.391,16.392-2.1-2.1" transform="translate(-7.617 -10.531)"></path><path class="launch-c" d="M-210.4-152.659l26.7-26.7c1.333-1.333,1.919-5.027,2.169-7.489a1.983,1.983,0,0,0-2.174-2.174c-2.462.25-6.156.837-7.489,2.169l-26.7,26.7" transform="translate(-3.885 0.564)"></path></g></svg>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Ground Station Equipment
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45"><defs><style>.operations-a,.operations-b{fill:none;stroke:#29b7ff;stroke-miterlimit:10;stroke-width:2px}.operations-b{stroke-linecap:square}</style></defs><g transform="translate(-1003 -10738)"><path class="operations-a" d="M44.016,17.051A21.5,21.5,0,1,1,36.2,6.149" transform="translate(1002 10737)"></path><circle class="operations-b" cx="4.3" cy="4.3" r="4.3" transform="translate(1036.966 10741.867)"></circle><circle class="operations-b" cx="12.5" cy="12.5" r="12.5" transform="translate(1013 10748)"></circle></g></svg>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Post-Launch Operations
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46.227" viewBox="0 0 46 43.492"><defs><style>.downlink-a,.downlink-b{fill:none;stroke:#29b7ff;stroke-miterlimit:10;stroke-width:2px}.downlink-b{stroke-linecap:square}</style></defs><g transform="translate(-1 -5)"><line class="downlink-a" y2="23.65" transform="translate(24 23.763)"></line><path class="downlink-b" d="M22,28l7.4,7.4,7.4-7.4" transform="translate(-5.403 11.674)"></path><path class="downlink-b" d="M29.867,35.237h8.8a8.071,8.071,0,0,0-.073-16.08,14.658,14.658,0,0,0-28.6-2.778A9.456,9.456,0,0,0,2,25.735a9.565,9.565,0,0,0,8.8,9.5h7.333" transform="translate(0 0)"></path></g></svg>
                    </div>
                </div>
            </div>

        </div>
        <div class="bar"></div>
    </div>
</section>

<!-- Section Core Values Start -->
<section class="saas-tools ptb-100 bg-gray">
    <div class="container" style="padding-left: 5%;">
        <div class="core-value-title">
            <h2>OUR CORE VALUES</h2>
        </div>

        <div class="row" style=" background-color: #131923;">
            <div class="col-lg-6 col-md-6 core-value-list">
                <ul>
                    <li [ngClass]="activeLink == '1' ? 'isActive' : 'isNotActive'" (click)="switchClasses(1)">
                        <a>Mentorship</a>
                    </li>
                    <li [ngClass]="activeLink == '2' ? 'isActive' : 'isNotActive'" (click)="switchClasses(2)">
                        <a>Technology</a>
                    </li>
                    <li [ngClass]="activeLink == '3' ? 'isActive' : 'isNotActive'" (click)="switchClasses(3)">
                        <a>E - Global Presence</a>
                    </li>
                    <li [ngClass]="activeLink == '4' ? 'isActive' : 'isNotActive'" (click)="switchClasses(4)">
                        <a>Creating Opportunities</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-6 core-value-content">
                <h3 *ngIf="linkOne">
                    TSC is fortunate to be backed by stalwarts in the domain who have proved their capabilities against all odds and with limited resources, helping us not set foot in the wrong direction at any moment.
                </h3>
                <h3 *ngIf="linkTwo">
                    We have redefined the global standards for build quality with our indigenously built sub-systems at an extremely competitive price compared to the world market.
                </h3>
                <h3 *ngIf="linkThree">
                    With the established global relationship, TSC Tech Labs is working with more than 60 countries to help enthusiastic and passionate minds realize their dreams of accessing space.
                </h3>
                <h3 *ngIf="linkFour">
                    TSC believes in the concept of learning by doing. Hence, we create opportunities for students, professionals,  and talented individuals to explore the world of possibilities through educational programmes, tech summits, and competitions
                </h3>
            </div>
        </div>

        <div style="margin-top: 70px;" class="bar"></div>
    </div>
</section>
<!-- Section Core Values End -->

<!-- Work Timeline Starts -->
<section class="section_body">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title" style="margin-bottom: 0;">
                    <div class="titles">
                        <h2 class="title title-line text-uppercase pb-4">TSC Roadmap</h2>
                        <span></span>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-12">
                <div class="main-icon rounded-pill text-center mt-4 pt-2">
                    <i data-feather="star" class="fea icon-md-sm"></i>
                </div>
                <div class="timeline-page geeks pt-2 position-relative">
                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div
                                    class="duration date-label-left border rounded p-2 pl-4 pr-4 position-relative shadow text-left">
                                    February 2020</div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="event event-description-right rounded p-4 border float-left text-left">
                                    <h5 class="title mb-0 text-capitalize">Registration of TSC Tech Labs</h5>
                                    <p class="timeline-subtitle mt-3 mb-0 text-muted">Incorporation of TSC Technologies as a registered private limited company.</p>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->

                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                                <div class="event event-description-left rounded p-4 border float-left text-right">
                                    <h5 class="title mb-0 text-capitalize">Int'l collaborations</h5>
                                    <p class="timeline-subtitle mt-3 mb-0 text-muted">Established international collaborations with like-minded teams from 25+ countries to understand the space market and available opportunities

                                    </p>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                                <div
                                    class="duration duration-right rounded border p-2 pl-4 pr-4 position-relative shadow text-left">
                                    June 2020</div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->

                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div
                                    class="duration date-label-left border rounded p-2 pl-4 pr-4 position-relative shadow text-left">
                                    August 2020</div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="event event-description-right rounded p-4 border float-left text-left">
                                    <h5 class="title mb-0 text-capitalize">Research & Development </h5>
                                    <p class="timeline-subtitle mt-3 mb-0 text-muted">Development of Proof of Concept and Prototypes for real-life experimentation and testing of small satellites.
                                    </p>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->

                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                                <div class="event event-description-left rounded p-4 border float-left text-right">
                                    <h5 class="title mb-0 text-capitalize">Space Heritage: UNITYsat x3 Launched</h5>
                                    <p class="timeline-subtitle mt-3 mb-0 text-muted">Gained heritage status through the successful launch of our first 3 Satellites on the PSLV C-51 Amazonia Mission Launched by ISRO on 28 February, 2021
                                    </p>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                                <div
                                    class="duration duration-right rounded border p-2 pl-4 pr-4 position-relative shadow text-left">
                                    Feb 2021</div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->

                    <!-- To INCREASE MORE ITEMS IN TREE STRUCTURE START COPYING FROM HERE -->
                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div
                                    class="duration date-label-left border rounded p-2 pl-4 pr-4 position-relative shadow text-left">
                                    June 2021</div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="event event-description-right rounded p-4 border float-left text-left">
                                    <h5 class="title mb-0 text-capitalize">75 Satellites Mission: 2022</h5>
                                    <p class="timeline-subtitle mt-3 mb-0 text-muted">TSC Tech Labs will build and launch 75 Satellites as part of the national mission, to strengthen the space-sector ecosystem and commemorate 75 years of Indian Independence</p>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->
                   <!--  <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                                <div class="event event-description-left rounded p-4 border float-left text-right">
                                    <h5 class="title mb-0 text-capitalize">Space Heritage: UNITYsat x3 Launched</h5>
                                    <p class="timeline-subtitle mt-3 mb-0 text-muted">Gained heritage status through the successful launch of our first 3 Satellites on the PSLV C-51 Amazonia Mission Launched by ISRO on 28 February, 2021
                                    </p>
                                </div>
                            </div>
                           
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                                <div
                                    class="duration duration-right rounded border p-2 pl-4 pr-4 position-relative shadow text-left">
                                    Feb 2021</div>
                            </div>
                            
                        </div>
                        end row
                    </div>-->
                    <!--end timeline item-->

                   
                    <!--end timeline item-->
                    <!-- To INCREASE MORE ITEMS IN TREE STRUCTURE STOP COPYING HERE -->

                </div>
                <!--end timeline page-->
                <!-- TIMELINE END -->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
        <div style="margin-top: 4%" class="bar"></div>
    </div>
    <!--end container-->
</section>
<!-- Work Timeline End -->

<!-- Section Our Team Start -->
<section id="7" class="overview-section ptb-100" style="margin-right: 0">
    <div class="container">
        <div class="section-title" style="padding-left: 5%;">
            <h2>OUR TEAM</h2>
        </div>
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/team/nikhil.png">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <h3>I Know Sky is not the limit because there are footprints on the moon.</h3>
                    <h4>Nikhil Riyaz</h4>
                    <p>Founding Director & CEO</p>
                    <a routerLink="/contact-team/nikhil-riyaz" class="btn btn-primary mt-5">Read More</a>
                </div>
            </div>
        </div>
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <h3>“I think of space not as the final frontier but as the next frontier, not as something to be conquered but to be explored."</h3>
                    <h4>Sanketh Huddar</h4>
                    <p>Founding Director & COO</p>
                    <a routerLink="/contact-team/SankethHuddar" class="btn btn-primary mt-5">Read More</a>
                </div>
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/team/sanketh.png">
                </div>
            </div>
        </div>
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/team/denzel.png">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <h3>I Know Sky is not the limit because there are footprints on the moon.</h3>
                    <h4>Denzel George</h4>
                    <p>Founding Director & CFO</p>
                    <a routerLink="/contact-team/denzel-george" class="btn btn-primary mt-5">Read More</a>

                </div>
            </div>
        </div>
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <h3>I Know Sky is not the limit because there are footprints on the moon.</h3>
                    <h4>Jegan Mani</h4>
                    <p>Co-Founder & CTO</p>
                    <a routerLink="/contact-team/jegan-mani" class="btn btn-primary mt-5">Read More</a>
                </div>
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/team/jegan.png">
                </div>
            </div>
        </div>
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/team/ashwin.png">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <h3>I Know Sky is not the limit because there are footprints on the moon.</h3>
                    <h4>Ashwin Reddy</h4>
                    <p>Co-Founder & CEnggO</p>
                    <a routerLink="/contact-team/ashwin-reddy" class="btn btn-primary mt-5">Read More</a>
                </div>
            </div>
        </div>
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <h3>I Know Sky is not the limit because there are footprints on the moon.</h3>
                    <h4>Sainath Vamshi</h4>
                    <p>Co-Founder & CDO</p>
                    <a routerLink="/contact-team/sainath-vamshi" class="btn btn-primary mt-5">Read More</a>
                </div>
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/team/sainath.png">
                </div>
            </div>
        </div>
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/team/bhavana.png">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <h3>I Know Sky is not the limit because there are footprints on the moon.</h3>
                    <h4>Bhavana Savanth</h4>
                    <p>Embedded Software Engineer</p>
                    <a routerLink="/contact-team/bhavana-savanth" class="btn btn-primary mt-5">Read More</a>
                </div>
            </div>
        </div>
        <div class="bar"></div>
    </div>
</section>
<!-- Section Our Team End -->

<!-- Section Our Investor Start -->
<section class="overview-section ptb-100" style="margin-right: 0; display: none;">
    <div class="container">
        <div class="section-title" style="padding-left: 1%;">
            <h2>OUR INVESTORS</h2>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12  investor-image">
                <main>
                    <div [ngClass]="selectedInvestor == '1' ? 'selectedDiv' : 'notSelectedDiv'"
                        (click)="switchInvestor(1)">
                        <img src="assets/img/extras/two.jpg" alt="image">
                    </div>
                    <div [ngClass]="selectedInvestor == '2' ? 'selectedDiv' : 'notSelectedDiv'"
                        (click)="switchInvestor(2)">
                        <img src="" alt="left image is of size 1080*1080" style="color: #fff;">
                    </div>
                    <div [ngClass]="selectedInvestor == '3' ? 'selectedDiv' : 'notSelectedDiv'"
                        (click)="switchInvestor(3)">
                        <img src="" alt="right image is of size 300*200 (Width*Height)" style="color: #fff;">
                    </div>
                    <div [ngClass]="selectedInvestor == '4' ? 'selectedDiv' : 'notSelectedDiv'"
                        (click)="switchInvestor(4)">
                        <img src="assets/img/extras/sl3.jpg" alt="">
                    </div>
                </main>
            </div>
            <div class="col-lg-6 col-md-6 investor-content">
                <div *ngIf="investorOne">
                    <h1>
                        One Freigeist Capital
                    </h1>
                    <br>
                    <p>
                        Freigeist Capital is a privately-owned investment firm working with visionary founders to fund
                        and build disruptive technology companies. <br>
                        <br>
                        The firm is best known for its seed investments in Lilium, Kraftblock, Neufund and Smartlane.
                        Notable exits include Wunderlist (acquired by Microsoft),
                        MyTaxi (acquired by Daimler) and kaufDA (acquired by Springer).
                    </p>
                </div>
                <div *ngIf="investorTwo">
                    <h1>
                        Two Freigeist Capital
                    </h1>
                    <br>
                    <p>
                        Freigeist Capital is a privately-owned investment firm working with visionary founders to fund
                        and build disruptive technology companies. <br>
                        <br>
                        The firm is best known for its seed investments in Lilium, Kraftblock, Neufund and Smartlane.
                        Notable exits include Wunderlist (acquired by Microsoft),
                        MyTaxi (acquired by Daimler) and kaufDA (acquired by Springer).
                    </p>
                </div>
                <div *ngIf="investorThree">
                    <h1>
                        Three Freigeist Capital
                    </h1>
                    <br>
                    <p>
                        Freigeist Capital is a privately-owned investment firm working with visionary founders to fund
                        and build disruptive technology companies. <br>
                        <br>
                        The firm is best known for its seed investments in Lilium, Kraftblock, Neufund and Smartlane.
                        Notable exits include Wunderlist (acquired by Microsoft),
                        MyTaxi (acquired by Daimler) and kaufDA (acquired by Springer).
                    </p>
                </div>
                <div *ngIf="investorFour">
                    <h1>
                        Four Freigeist Capital
                    </h1>
                    <br>
                    <p>
                        Freigeist Capital is a privately-owned investment firm working with visionary founders to fund
                        and build disruptive technology companies. <br>
                        <br>
                        The firm is best known for its seed investments in Lilium, Kraftblock, Neufund and Smartlane.
                        Notable exits include Wunderlist (acquired by Microsoft),
                        MyTaxi (acquired by Daimler) and kaufDA (acquired by Springer).
                    </p>
                </div>
            </div>
        </div>
        <div class="bar"></div>
    </div>
</section>
<!-- Section Our Investor End -->

<footer style="width: 100%; background-color: #000000;">
    <div style="text-align: center; padding: 100px 0;">
        <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
            BEYOND THE HORIZON
        </h5>
    </div>
</footer>