<!-- SLIDER for Extra News -->
<section id="target" class=" ptb-100" style="background: #000;">
    
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    <div class="container">
        <div class="section-title mt-5">
            <h2>FUNDINGS</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 mt-4" *ngFor="let news of newsContent">
                <div class="news-div">
                    <div class="post-image" style="background-color: #018FF4; height: 316px;">
                        <img src="{{news.image}}" alt="blog-image">
                    </div>
                    <div class="news-post-content" style="padding: 15px 10px;">
                        <h5 style="padding-left:40px; color: #e5e5e5;"><a [routerLink]="['/csr-funding-detail']" [state]="{ newsId: news.id }"
                                style=" color: #e5e5e5;">{{news.title | slice:0:50}}</a></h5>
                        <p class="news-date">{{news.date}}</p>
                        <p class="news-content">
                            {{news.content | slice:0:100}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 100px;" class="bar"></div>
    </div>
</section>


<footer style="width: 100%; background-color: #000000;">
    <div style="text-align: center; padding: 100px 0;">
        <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
            BEYOND THE HORIZON
        </h5>
    </div>
</footer>