import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { auto } from '@popperjs/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NewsService } from 'src/app/service/news-service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {

  newsContent: any[] = [];


  public customOptions: OwlOptions = {
    loop: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    autoplayTimeout: 5000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  };

  getNewsId: number;

  public basicContent: string;
  htmlContent = 'Hello';
  config: any

  blogContent: any;
  configContent: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    // uploadUrl: 'v1/image',
    // uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'indent',
        // 'outdent',
        'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        // 'fontName'
      ],
      [
        // 'fontSize',
        // 'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  newsObj: any;
  origiImg: any;

  imagePaths = [];

  constructor(
    private router: Router,
    private newsService: NewsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((param) => {
      console.log(param);
      this.getNewsId = param.newsId;
    })
  }

  ngOnInit() {
    this.toTop();
    this.newsService.getNewsById(this.getNewsId).subscribe((Response) => {
      console.log(Response);
      this.newsObj = Response.data;
      this.downloadImage(this.newsObj.imagePath, this.newsObj.id);
    }, (Error) => {
      console.log(Error);
    })


    this.config = {
      readonly : 1,
      plugins: "autoresize",
      autoresize_bottom_margin: 20,
      menubar: false,
        statusbar: false,
        toolbar: false,
      image_advtab: false,
      images_upload_url: 'postAcceptor.php',
      imagetools_toolbar:
        'rotateleft rotateright | flipv fliph | editimage imageoptions',
      templates: [
        { title: 'Test template 1', content: 'Test 1' },
        { title: 'Test template 2', content: 'Test 2' },
      ],
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tinymce.com/css/codepen.min.css',
      ],
      setup: (editor) => {
        console.log(editor.ui);
      },
    };
  }


  downloadImage(photoPath, id) {
    let imagePath = photoPath.split("news/image/");
    this.newsService.getNewsImage(imagePath[1]).subscribe((Response) => {
      this.createImageFromBlob(Response, id);
    }, (Error) => {
      console.log(Error);
    })
  }

  createImageFromBlob(image: Blob, id: number) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.origiImg = reader.result;
      console.log(this.origiImg);
      this.imagePaths.push(this.origiImg);
      this.imagePaths[id] = this.origiImg;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  showLog() {
    console.log(this.htmlContent);

    this.blogContent = this.htmlContent;
  }

  toTop() {
    document.getElementById("target").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
