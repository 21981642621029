<!-- Sub-Menu Header Start -->
<section id="target" class="navbar-section mt-2">
    <div class="navbar-menu">
        <div class="left-title">
            <p>{{eventObj.title}}</p>
        </div>
        <div class="right-content">
            <p (click)="toTop()">Overview</p>
        </div>
        <div class="right-content">
            <p (click)="toSchedules()">Schedule</p>
        </div>
        <div class="right-content">
            <p (click)="toLecturers()">Lecturers</p>
        </div>
    </div>
    <div class="row sub-menu-mobile">
        <div class="col-xs-12 col-sm-12 col-12">
            <p>Earth Observation & Geospatial Analysis: from satellite to end-user</p>
        </div>
        <div class="col-xs-4 col-sm-4 col-4">
            <p (click)="toTop()">Overview</p>
        </div>
        <div class="col-xs-4 col-sm-4 col-4">
            <p (click)="toSchedules()">Schedule</p>
        </div>
        <div class="col-xs-4 col-sm-4 col-4">
            <p (click)="toLecturers()">Lecturers</p>
        </div>
    </div>


</section>
<!-- Sub-Menu Header End -->

<section *ngIf="overview">

    <section  class="first-section">
        <div class="transparent-container">
            <div class="xs-date">
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5" style="text-align: right;">
                        <h2>{{eventObj.startDate | date : 'dd'}}</h2>
                        <span>{{eventObj.startDate | date : 'MMM yyyy'}}</span>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1" style="text-align: center; padding-top: 2%;">
                        <span>&#8211;</span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 col-6">
                        <h2>{{eventObj.endDate | date : 'dd'}}</h2>
                        <span>{{eventObj.endDate | date : 'MMM yyyy'}}</span>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5" style="text-align: right;">
                        <h2 style="font-size: 20px;">{{eventObj.startTime}}</h2>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1" style="text-align: center; padding-top: 2%;">
                        <span>&#8211;</span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 col-6">
                        <h2 style="font-size: 20px;">{{eventObj.endTime}}</h2>
                    </div>
                </div>
            </div>
            <div class="flex-container">
                <div class="date">
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5" style="text-align: right;">
                            <h2>{{eventObj.startDate | date : 'dd'}}</h2>
                            <span>{{eventObj.startDate | date : 'MMM yyyy'}}</span>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
                            style="text-align: center; padding-top: 2%;">
                            <span>&#8211;</span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
                            <h2>{{eventObj.endDate | date : 'dd'}}</h2>
                            <span>{{eventObj.endDate | date : 'MMM yyyy'}}</span>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5" style="text-align: right;">
                            <h2 style="font-size: 20px;">{{eventObj.startTime}}</h2>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1" style="text-align: center;">
                            <span>&#8211;</span>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
                            <h2 style="font-size: 20px;">{{eventObj.endTime}}</h2>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-12 col-md-12" style="text-align: center;">
                            <a (click)="eventRegisterationForm()" class="btn btn-primary">Register</a>
                        </div>
                    </div>
                </div>

                <div class="title">
                    <h1>
                        {{eventObj.title}}
                    </h1>
                    <h2>
                        {{eventObj.descriptionOne}}
                    </h2>
                    <p>
                        {{eventObj.mode}}<br>
                        Location: {{eventObj.location}}
                    </p>
                    <p>

                    </p>
                </div>
            </div>
        </div>
        <div class="image-container">
            <img [src]="origiImg" alt="rockets">
        </div>
    </section>

    <section class="second-section">

        <div class="container">
            <div class="row mb-5">
                <!-- <div class="col-lg-12 col-md-12 text-left">
                    <h2 style="font-size: 25px; font-weight: 700; color: #018FF4;">INTRO</h2>
                </div> -->
                <div class="col-lg-12 col-sm-12 col-12">
                    <!-- <angular-editor [ngModelOptions]="{standalone: true}" style="color: #fff;"
                            [(ngModel)]="eventObj.descriptionTwo" [config]="configContent"></angular-editor> -->

                <tinymce [(ngModel)]="eventObj.descriptionTwo" [config]="config" [ngModelOptions]="{standalone: true}"></tinymce>
                </div>
            </div>
            <!-- <h2>
                {{eventObj.descriptionTwo}}
            </h2>
            <p>
                {{eventObj.descriptionThree}}
            </p> -->
        </div>

        <!--Start Section Takeaways -->
        <div class="takeways mt-5">
            <div class="row h-100">
                <div class="col-lg-4 col-md-4">
                    <div class="row mb-4">
                        <div class="col-lg-12 col-md-12">
                            <h3 class="h3-align">Total investment</h3>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-12 col-md-12">
                            <h2>Class limitation:
                                30</h2>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-12 col-md-12">
                            <h2>Course hours:
                                20</h2>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-12 col-md-12">
                            <h2>Minimum registrations:
                                10</h2>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <h3 class="mb-4">
                        TakeWay/ Benefits
                    </h3>
                    <ul class="why-join">
                        <li>TSC UNITYsat Training Resources</li>
                        <li>eBooks</li>
                        <li>Presentations</li>
                        <li>Research Papers</li>
                        <li>Sat Hacks, Guides and Documents</li>
                        <li>Video Playlists</li>
                        <li>Project Timeline Planning</li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-4" style="padding-top: 3%;">
                    <a routerLink="/event-register" class="btn btn-primary">Register</a>
                </div>

            </div>
        </div>
        <!-- End Section Takeaways -->
        <div class="rn-gradient-circle"></div>
        <div class="rn-gradient-circle theme-pink"></div>

    </section>

    <!-- Section FAQ -->
    <section class="mission-control">
        <div class="rn-gradient-circle"></div>
        <div class="rn-gradient-circle theme-pink"></div>
        <div class="container">
            <h1 style="margin-bottom: 25px; color: #018FF4; font-size: 25px; font-weight: 700;">
                FAQ's
            </h1>
            <div class="col-sm-12">
                <ngb-accordion [closeOthers]="true" activeIds="static-1" class="able-pro-accordion">
                    <ngb-panel id="static-1">
                        <ng-template ngbPanelTitle>
                            <span class="utl-num">01</span>
                            <span class="utl-content ">FAQ 1</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                            squid. 3 wolf moon officia
                            aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                            Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil
                            anim keffiyeh helvetica,
                            craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings
                            occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't
                            heard of them accusamus
                            labore sustainable VHS.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-2">
                        <ng-template ngbPanelTitle>
                            <span class="utl-num">02</span>
                            <span class="utl-content ">FAQ 2</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            Business-to-business metrics analytics value proposition funding
                            angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque. Business-to-business metrics analytics value
                            proposition funding angel investor entrepreneur.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-3">
                        <ng-template ngbPanelTitle>
                            <span class="utl-num">03</span>
                            <span class="utl-content ">FAQ 3</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                            squid. 3 wolf moon officia
                            aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                            Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil
                            anim keffiyeh helvetica,
                            craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings
                            occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't
                            heard of them accusamus
                            labore sustainable VHS.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-4">
                        <ng-template ngbPanelTitle>
                            <span class="utl-num">04</span>
                            <span class="utl-content ">FAQ 4</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                            squid. 3 wolf moon officia
                            aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                            Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil
                            anim keffiyeh helvetica,
                            craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings
                            occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't
                            heard of them accusamus
                            labore sustainable VHS.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-5">
                        <ng-template ngbPanelTitle>
                            <span class="utl-num">05</span>
                            <span class="utl-content ">FAQ 5</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                            squid. 3 wolf moon officia
                            aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                            Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil
                            anim keffiyeh helvetica,
                            craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings
                            occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't
                            heard of them accusamus
                            labore sustainable VHS.
                        </ng-template>
                    </ngb-panel>

                </ngb-accordion>

            </div>
            <div class="bar"></div>
        </div>


        <footer style="width: 100%; margin-top: 150px;">
            <div style="text-align: center; padding: 50px 0;">
                <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
                    BEYOND THE HORIZON
                </h5>
            </div>
        </footer>
    </section>
    <!-- Section FAQ -->

</section>


<section id="schedules" style="background: #000000; padding: 20px 0 0 0;">

    <section class="event-schedules" *ngIf="eventSchedule">

        <div class="container">
            <div class="row text-center">
                <div class="col-lg-12 col-md-12">
                    <h3>
                        Schedule
                    </h3>
                </div>
            </div>
            <div class="row row-content" *ngFor="let schdules of eventScheduleObj; index as i">
                <div class="col-lg-6">
                    <h2>{{schdules.startDate | date : 'mediumDate'}} {{schdules.startTime}}
                        -
                        {{schdules.endTime}}</h2>
                </div>
                <div class="col-lg-6">
                    <h2>{{schdules.detail}}</h2>
                </div>
            </div>
        </div>
    </section>

</section>

<section id="lecturers" style="background: #000000; padding: 60px 0 0 0; z-index: -999; position: relative;">
    <section class="event-lecturers " *ngIf="eventLecturers">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2 style="font-size: 25px; font-weight: 700; color: #018FF4; margin-left: 20px;">
                    Lecturers
                </h2>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <div class="lecturer-section" *ngFor="let eventLecturer of eventLecturerObj; index as i">
                    <div class="lecturer-image">
                        <img [src]="imagePathsLecturer[eventLecturer.id]">
                    </div>
                    <div class="lecturer-about">
                        <p class="mt-2" style="color: #018FF4; font-size: 26px; text-align: left;">
                            {{eventLecturer.name}}
                        </p>
                        <a style="color: #fff; font-size: 20px;">{{eventLecturer.about}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>