import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLConfig } from '../url-config';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  
  constructor(
    private httpClient: HttpClient,
    private urlConfig: URLConfig
  ) { }

  public eventRegistration(eventRegistrationRequestView : any): Observable<any> {
    return this.httpClient.post(this.urlConfig.saveEventRegistration, eventRegistrationRequestView );
  }

  public getAllEvents():Observable<any> {
    return this.httpClient.get(this.urlConfig.getAllEvents);
  }
  
  public getEventsById(eventId):Observable<any> {
    return this.httpClient.get(this.urlConfig.getEventById + eventId);
  }
  
  public getImage(imgPath: string):Observable<any> {
    return this.httpClient.get(this.urlConfig.getEventImage + imgPath, { responseType: 'blob' });
  }

  //event-schedule
  public getScheduleByEventId(eventId): Observable<any> {
    return this.httpClient.get(this.urlConfig.getEventScheduleByEventId + eventId);
  }


  //event-lecturer
  public getEventLecturer(eventId): Observable<any> {
    return this.httpClient.get(this.urlConfig.getEventlecturerByEventId + eventId);
  }

  public getEventLecturerImage(imgPath): Observable<any> {
    return this.httpClient.get(this.urlConfig.getEventLecturerImage + imgPath, { responseType: 'blob' });
  }
}
