
<!-- FOURTH APPROACH -->
<section class="section_body" style="padding-bottom: 100px;">
    <div class="container" style="padding: 10px;">
        <div class="header">
            <h2 style="font-size: 25px; font-weight: 700; text-transform: uppercase;">News</h2>
        </div>
        <div class="founder">
            <h1 style="font-size: 23px;">News Title 4</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="margin-bottom: 20px;">
                    DECEMBER 21, 2021, 15:04 IST
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="text-align: right;">
                    Share
                    <img src="assets/img/socials/fb.png" width="28px" height="28px">
                    <img src="assets/img/socials/insta.png" width="30px" height="30px">
                    <img src="assets/img/socials/tweet.png" width="30px" height="30px">
                </div>
            </div>

            <div class="row h-100 justify-content-center align-items-center"
                style="background-color: #131821; padding: 20px 0px 20px 0px;">
                <div class="col-lg-12 col-md-12">
                    <img src="https://unsplash.it/1503" class="main-banner-img" width="100%" alt="image">
                </div>
                <div class="col-lg-12 col-md-12" style="margin-top: 3%;">
                    <div class="about-content">
                        <div class="section-title" style="max-width: 100%; margin-bottom: 10px;">
                            <h2>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                                viverra nunc tinci dunt nec elei fend et tiram.</h2>
                            <br>
                        </div>
                        <p style="text-align: justify;">Business-to-business metrics analytics value proposition funding
                            angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque. Business-to-business metrics analytics value
                            proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque. Business-to-business metrics analytics value
                            proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque.vBusiness-to-business metrics analytics value
                            proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque.
                            Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                            viverra nunc tinci dunt nec elei fend et tiram.</p>
                    </div>
                </div>

                <div class="row row-10 mt-4 mb-5">
                    <div class="col-lg-6 left-img-container">
                        <div class="left-content">
                            <p>Business-to-business metrics analytics value proposition funding
                                angel investor entrepreneur
                                alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                                sit amet, consectetur adipiscing elit. In sagittis egestas ante, </p>
                        </div>
                        <div class="left-img">
                            <img src="https://unsplash.it/1510" class="main-banner-img" width="100%" alt="image">
                        </div>
                    </div>
                    <div class="col-lg-6 right-img-container">
                        <div class="right-content">
                            <p>Business-to-business metrics analytics value proposition funding
                                angel investor entrepreneur
                                alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                                sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                                viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                                tempus sagittis velit vitae scelerisque. Business-to-business metrics analytics value
                                proposition funding angel investor entrepreneur.</p>
                        </div>
                        <div class="right-img">
                            <img src="https://unsplash.it/1511" class="main-banner-img" width="100%" alt="image">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>