import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GalleryService } from 'src/app/service/gallery.service';

@Component({
  selector: 'app-gallery-detail',
  templateUrl: './gallery-detail.component.html',
  styleUrls: ['./gallery-detail.component.scss']
})
export class GalleryDetailComponent implements OnInit {

  imageSection: boolean = true;
  videoSection: boolean = true;

  shuffleArray:any;

  albumId: number;
  albumObj : any;

  origiImg: any;

  images: any[] = [];
  imagePaths = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private galleryService: GalleryService
  ) { 
    this.activatedRoute.queryParams.subscribe(param => {
      console.log(param);
      this.albumId = param.galleryId;
    })
  }

  ngOnInit() {
    this.toTop();

    this.galleryService.getAlbumById(this.albumId).subscribe((Response) => {
      console.log(Response);
      this.albumObj = Response.data;
    }, (Error) => {
      console.log(Error);
    });


    this.galleryService.getImagesByAlbumId(this.albumId).subscribe((Response) => {
      console.log(Response);
      this.images = Response.data;
      
      Response.data.forEach(element => {
        if(element.mediaType == "IMAGE") {
          console.log("Fetching Image");
          this.downloadImage(element.imagePath, element.id);  
        } else {
          console.log("Fetching Video");
        }
      });
    }, (Error) => {
      console.log(Error);
    });

    this.shuffleArrayMethod(this.images);
  }

  downloadImage(photoPath, id) {
    let imagePath = photoPath.split("albumImages/image/");
    this.galleryService.getImagesofAlbum(imagePath[1]).subscribe((Response)=> {
      this.createImageFromBlob(Response, id);
    }, (Error)=> { 
      console.log(Error);
    })
  }

  createImageFromBlob(image: Blob, id: number) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.origiImg = reader.result;
      this.imagePaths.push(this.origiImg);
      this.imagePaths[id] = this.origiImg;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  toTop() {
    document.getElementById("target").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  // this.shuffleArray = 
  shuffleArrayMethod(array) {
    var m = this.images.length, t, i;
  
    // While there remain elements to shuffle
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);
  
      // And swap it with the current element.
      t = this.images[m];
      this.images[m] = this.images[i];
      this.images[i] = t;
    }
  
    this.shuffleArray = this.images;
    console.table(this.shuffleArray);
  }

  drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(ev) {
    console.log(ev);
    ev.dataTransfer.setData("text", ev.target.id);
  }

  showImages(){ 
    this.imageSection = true;
    this.videoSection = false;
  }

  showVideos() { 
    this.imageSection = false;
    this.videoSection = true;
  }

}
