<section id="target" class="section_body">
    <div class="container" style="padding: 10px;">
        <div class="header">
            <h2 style="font-size: 25px; font-weight: 700; text-transform: uppercase;">News</h2>
        </div>
        <div class="founder">
            <h1 style="font-size: 23px;">News Title</h1>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="margin-bottom: 20px;">
                    DECEMBER 21, 2021, 15:04 IST
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="text-align: right;">
                    Share 
                    <img src="assets/img/socials/fb.png" width="28px" height="28px">
                    <img src="assets/img/socials/insta.png" width="30px" height="30px">
                    <img src="assets/img/socials/tweet.png" width="30px" height="30px">
                </div>
            </div>

            <div class="row h-100 justify-content-center align-items-center"
                style="background-color: #131821; padding: 20px 0px 20px 10px;">
                <!-- <div class="col-lg-12 col-md-12" style="margin-bottom: 2%;">
                    <h2 style="text-align: justify; font-size: 20px;">Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                        viverra nunc tinci dunt nec elei fend et tiram.</h2>
                </div> -->

                <!-- When Only One Banner Image to be Used  -->
                <!-- <div class="col-lg-12 col-md-12">
                    <img src="assets/img/logos/body-bg-6.jpg" alt="image">
                </div> -->

                <!-- When Multiple Banner Images to be Used  -->
                <section class="screenshot-area news-banner ptb-10" >
                    <div class="row" style="margin-right: 0;">
                        <div class="news-banner-slides owl-carousel owl-theme">
                            <div class="col-lg-12" >
                                <div class="banner-item">
                                    <img src="assets/img/extras/body-bg-6.jpg" alt="screenshot">
                                </div>
                            </div>
            
                            <div class="col-lg-12">
                                <div class="banner-item">
                                    <img src="assets/img/extras/body-bg-4.jpg" alt="screenshot">
                                </div>
                            </div>
            
                            <div class="col-lg-12">
                                <div class="banner-item">
                                    <img src="assets/img/extras/satellite_one.png" alt="screenshot">
                                </div>
                            </div>
            
                            <div class="col-lg-12">
                                <div class="banner-item">
                                    <img src="assets/img/extras/sl3.jpg" alt="screenshot">
                                </div>
                            </div>
            
                            <div class="col-lg-12">
                                <div class="banner-item">
                                    <img src="assets/img/logos/body-bg-6.jpg" alt="screenshot">
                                </div>
                            </div>
            
                            <div class="col-lg-12">
                                <div class="banner-item">
                                    <img src="assets/img/page1.jpg" alt="screenshot">
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
                <div class="col-lg-12 col-md-12" style="margin-top: 3%;">
                    <div class="about-content">
                        <div class="section-title" style="max-width: 100%;">
                            <h2>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                                viverra nunc tinci dunt nec elei fend et tiram.</h2>
                            <br>
                            <p style="text-align: justify; max-width: 100%;">Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                                viverra nunc tinci dunt nec elei fend et tiram.</p>
                        </div>
                        <p style="text-align: justify;">Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.</p>
                        <p style="text-align: justify;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque.</p>
                        <!-- <div class="videoWrapper">
                            <iframe
                                src="https://www.youtube.com/embed/dwLHaS6-tUo?controls=0&amp;rel=0&amp;modestbranding=1&amp;wmode=transparent&amp;enablejsapi=1&amp;"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen="" wmode="Opaque"
                                data-lf-yt-playback-inspected-3p1w24d090g4my5n="true"></iframe>
                        </div> -->
                        <p style="text-align: justify;">Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                            viverra nunc tinci dunt nec elei fend et tiram.</p>
                        <p style="text-align: justify;">Business-to-business metrics analytics value proposition funding angel investor entrepreneur
                            alpha ramen equity gamification. Social proof partner network research.</p>
                        <p style="text-align: justify;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed
                            viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin
                            tempus sagittis velit vitae scelerisque.</p>
                        <p style="text-align: justify;">Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed
                            viverra nunc tinci dunt nec elei fend et tiram.</p>
                    </div>

                    <!-- Section with Slider Approach to display multiple news images START -->
                    <section id="sales-team" class="blog-area features-area pb-100" style="background-color: #131821; padding-top: 50px;">
                        <div style="margin-bottom: 200px;">
                            <div class="news-detail-slides owl-carousel owl-theme sales-team-padding">
                                <div class="single-blog-post">
                                    <div class="single-news">
                                        <img src="assets/img/extras/product_1.png" widt="100%" height="350px" alt="gallery">
                                    </div>
                                    <div style="top: 70%; text-align: left; padding: 10px;">
                                        <h3 style="color: #e5e5e5; background-color:  bg-color;">Extra Image Title</h3>
                                        <p style="color: #e5e5e5;">Image Size here is 610*659 (Width*Height)<br>
                                        </p>
                                    </div>
                                </div>
                    
                                <div class="single-blog-post" >
                                    <div class="single-news">
                                        <img src="assets/img/logos/body-bg-6.jpg" width="100%" height="350px" alt="Image size 350*350" style="color: #fff;">
                                        <a>Extra Image Title<br>
                                            
                                        </a>
                                        <p style="font-size: 14px; text-align: justify;">
                                            Some Line One Some Line One.
                                            Some Line two Some Line two.
                                            Image Size here is 1920*1080 (Width*Height)
                                        taken from the main image above<br>
                                        </p>
                                    </div>
                                    <div style="top: 70%; text-align: left; padding: 10px;">
                                        <h3 style="color: #e5e5e5; background-color:  bg-color;">Put Cursor on this Image</h3>
                                        <!-- <p style="color: #e5e5e5;">Image Size here is 1920*1080 (Width*Height)<br>
                                            taken from the main image above
                                        </p> -->
                                    </div>
                                </div>
                    
                                <div class="single-blog-post">
                                    <div class="single-news" >
                                        <img src="" width="100%" height="350px" alt="Image" style="color: #fff;">
                                    </div>
                                    <div style="top: 70%; text-align: left; padding: 10px;">
                                        <h3 style="color: #e5e5e5; background-color: bg-color;">Extra Image Title</h3>
                                        Breaking News Line One<br>
                                        Breaking News Line Two<br>
                                        Breaking News Line Three
                                    </div>
                                </div>

                                <div class="single-blog-post">
                                    <div class="single-news" >
                                        <img src="assets/img/extras/body-bg-4.jpg" width="100%" height="350px" alt="Image" style="color: #fff;">
                                    </div>
                                    <div style="top: 70%; text-align: left; padding: 10px;">
                                        <h3 style="color: #e5e5e5; background-color: bg-color;">Extra Image Title</h3>
                                        Breaking News Line One<br>
                                        Breaking News Line Two<br>
                                        Breaking News Line Three
                                    </div>
                                </div>
                    
                            </div>
                        </div>
                    </section>
                    <!-- Section with Slider Approach to display multiple news images ENd -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="news-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Headlines</h2> 
        </div>

        <div class="col-sm-12 mt-2" style="padding-left: 5px;">
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide
                *ngFor="let news of newsContent">
                <div class="news-div">
                    <div class="post-image" style="background-color: #018FF4; height: 316px;">
                        <img src="{{news.image}}" alt="blog-image">
                    </div>
                    <div class="news-post-content" style="padding: 15px 10px;">
                        <h5 style="padding-left:40px; color: #e5e5e5;"><a routerLink="/blog-details"
                                style=" color: #e5e5e5;">{{news.title}}</a></h5>
                        <p class="news-date">{{news.date}}</p>
                        <p class="news-content">
                            {{news.content}}</p>
                        <div class="blog-btn">
                            <a routerLink="/headlines"> 
                                <i class="icofont-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        <div style="margin-top: 100px;" class="bar"></div>
    </div>
</section>