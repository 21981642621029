<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <div class="container">
        <div class="row">

            <div class="col-lg-3 col-md-4 col-sm-6">
                <a class="navbar-brand logo_align" routerLink="/">
                    <img src="assets/img/logos/TSC_Logo_10.png" alt="logo" style="width: 150px;">
                </a>
                <div class="copyright-area" style="padding: 0; border-top: 0; margin-top: 20px;">
                    <ul class="social_icons">
                        <li><a href="https://www.facebook.com/tsctechlabs" target="_blank">
                            <img src="assets/img/socials/fb.png">
                        </a></li>
                        <li><a href="https://twitter.com/tsc_tech" target="_blank">
                            <img src="assets/img/socials/tweet.png">
                        </a></li>
                        <li><a href="https://www.instagram.com/tsc.tech/" target="_blank">
                            <img src="assets/img/socials/insta.png">
                        </a></li>
                        <li><a href="https://www.linkedin.com/company/tsctech-in/" target="_blank">
                            <img src="assets/img/socials/icons8-linkedin.svg">
                        </a></li>
                        <li><a href="https://www.youtube.com/channel/UCo0HnGobl2jPYBFCDYYwn4A" target="_blank">
                            <img src="assets/img/socials/youtube.svg">
                        </a></li>
                    </ul>
                </div>
                <div class="single-footer-widget mt-5">
                    <h3>GET IN TOUCH</h3>
                    
                    <a routerLink="/contact-us" class="btn btn-primary btn-gradient">CONTACT US</a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>COMPANY</h3>

                    <ul class="list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about-us">About</a></li>
                        <li><a [routerLink]="['/about-us']" [queryParams]="{ teamId: '7'}">Team</a></li>
                        <li><a routerLink="/projects">Projects</a></li>
                        <li><a routerLink="/">Space Qualifications</a></li>
                        <li><a routerLink="/headlines">Company News</a></li>
                        <li><a href="assets/privacy_policies/disclaimer.html" target="_blank">Disclaimer</a></li>
                        <li><a href="assets/privacy_policies/eula.html" target="_blank">EULA</a></li>
                        <li><a href="assets/privacy_policies/cookie-policy.html" target="_blank">Cookie Policy</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-footer-widget">
                    <h3>PRODUCTS</h3>

                    <ul class="list">
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '1'}">CubeSat Platforms</a></li>
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '2'}">CubeSat Communication</a></li>
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '3'}">CubeSat Antennas</a></li>
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '4'}">CubeSat Beacon</a></li>
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '5'}">CubeSat Computers</a></li>
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '6'}">CubeSat Power Modules</a></li>
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '7'}">CubeSat Solar Panels</a></li>
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '8'}">CubeSat Battery Board</a></li>
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '9'}">CubeSat Structures</a></li>
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '10'}">CubeSat Payloads</a></li>
                        <li><a [routerLink]="['/products']" [queryParams]="{ productId: '11'}">CubeSat Educational Modules</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>SERVICES</h3>

                    <ul class="list">
                        <li><a routerLink="/services/tsc-edunav">TSC EduNav</a></li>
                        <li><a routerLink="/services/digital-ground-station">Digital Ground Station</a></li>
                        <li><a routerLink="/services/tsc-satnav">TSC SatNav</a></li>
                        <li><a routerLink="/services/shared-satellite-service">Shared Satellite Service</a></li>
                    </ul>
                </div>
                <div class="single-footer-widget mt-5">
                    <h3>CONTACTS</h3>

                    <ul class="list">
                        <li><a routerLink="/contact-us">Contact us</a></li>
                        <li><a routerLink="/careers">Careers</a></li>
                    </ul>
                </div>
            </div>

            
        </div>

        <!-- Subscribe with us -->
        <div class="row mt-5">
            <div class="col-lg-4 text-right">
                <div style="display: flex; justify-content: center; align-items: center;">
                    <p style="font-weight: 700; font-size: 23px;">Subscribe now to receive TSC Tech Newsletters & Communications!</p>
                </div>
            </div>
            <div class="col-lg-8">
                    <div style="display: flex; align-items: center; gap: 20px; padding: 10px 0;">
                        <div style="width: 60%;">
                            <input type="email" [(ngModel)]="email" placeholder="Enter your Email ID" style="width: 100%; height: 50px; border-radius: 10px;">
                        </div>
                        <div  (click)="subscribeTo()" style="cursor: pointer;">
                            <button class="btn btn-primary btn-gradient">
                                Submit
                            </button>
                        </div>
                    </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-4 col-md-12 col-12 mb-3">
                    <img src="assets/img/logos/TSC_Logo_10.png" alt="logo" class="logo_width">
                </div>

                <div class="col-lg-4 col-md-12 col-12" style="text-align: center;">
                    <p>&copy; 2021 TSC Tech Labs, All rights reserved. <br>Coded with ❤️ by <a style="color: #018ff4;" href="https://www.geekspacelabs.in" target="_0"> GeekSpace Labs</a> </p>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                    <ul class="tems-privacy-align">
                        <li style="padding-right: 0; margin-right: 0;"><a style="color: #e5e5e5; font-size: 14px;" href="assets/privacy_policies/terms-and-conditions.html" target="_blank">Terms of Use</a></li> &nbsp;
                        <li ><a style="color: #e5e5e5; font-size: 14px;" href="assets/privacy_policies/privacy-policy.html" target="_blank">Privacy Policy</a></li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->