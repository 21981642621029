import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { URLConfig } from "../url-config";

@Injectable({
    providedIn: 'root'
})
export class NewsService {

    constructor(
        private httpClient: HttpClient,
        private urlConfig: URLConfig
    ) {

    }

    public getAllNews(): Observable<any> {
        return this.httpClient.get(this.urlConfig.getAllNews);
    }

    public getNewsById(newsId : number): Observable<any> {
        return this.httpClient.get(this.urlConfig.getNewsById + newsId);
    }
    
    public getNewsImage(imagePath: string): Observable<any> {
        return this.httpClient.get(this.urlConfig.getNewsImage + imagePath, {responseType: 'blob'});
    }
    
}