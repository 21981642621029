
<!-- <main>
    <div class="div1">
        <img src="assets/img/clients/cu.png" height="300px" width="100%">
    </div>
    <div class="div2">
        <img src="assets/img/clients/siet.png" height="300px">
    </div>
    <div class="div3">
        <img src="assets/img/clients/jit.png" height="300px">
    </div>
    <div class="div4">div4</div>
    <div class="div5">div5</div>
    <div class="div6">div6</div>
</main> -->













<!-- Start Coming Soon Area -->
<section class="coming-soon">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="coming-soon-content">
                    <h1 style="font-size: 80px;">COMING SOON</h1>

                    <!-- <div id="timer">
                        <div id="days">{{days}} <span>Days</span></div>
                        <div id="hours">{{hours}} <span>Hours</span></div>
                        <div id="minutes">{{minutes}} <span>Minutes</span></div>
                        <div id="seconds">{{seconds}} <span>Seconds</span></div>
                    </div> -->

                    <!-- <form>
                        <input type="text" class="form-control" placeholder="Type your email address" required>
                        <button type="submit">Subscribe</button>
                    </form> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Coming Soon Area -->