import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-detail-demo-two',
  templateUrl: './news-detail-demo-two.component.html',
  styleUrls: ['./news-detail-demo-two.component.scss']
})
export class NewsDetailDemoTwoComponent implements OnInit {

  origiImg: any;

  constructor() { }

  ngOnInit(): void {
  }

}
