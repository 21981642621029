<body>

    
    <div class="flexbox" style="padding-bottom: 20%;">
        <div class="title-container">
            <div class="row">
                <div class="col-lg12 col-md-12">
                    <h2>
                        Album Name Album Name 
                    </h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg12 col-md-12">
                    <p>
                        Date: Jan 13, 2022</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <p class="content">Business-to-business metrics analytics value proposition funding
                        angel investor entrepreneur alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit. In sagittis egestas ante.
                        Business-to-business metrics analytics value proposition funding
                        angel investor entrepreneur alpha ramen equity gamification. Social proof partner network research. Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit. In sagittis egestas ante</p>
                </div>
            </div>
        </div>

        <div class="">
            <div id="grid" class="grid-wrapper">

                <!-- row-1 2 divs, each of half width, 4:3 & 16:9 -->
                <div id="div1" class="card lrg subhead-1 col-num" (drop)="drop($event)" (dragover)="allowDrop($event)">
                    <img id="drag1" [src]="images[0].imagePath" (dragstart)="drag($event)">
                </div>  
                <div id="div2" class="card lrg subhead-2 col-num"  (drop)="drop($event)" (dragover)="allowDrop($event)">
                    <img id="drag2" [src]="images[2].imagePath" (dragstart)="drag($event)">
                </div>
                
                <!-- row-2 3 div width 4:3 -->
                <div class="card item-1 sm"  (drop)="drop($event)" (dragover)="allowDrop($event)">
                    <img id="drag3" [src]="images[1].imagePath" (dragstart)="drag($event)">
                </div>
                <div class="card item-2 med">
                    <img [src]="images[1].imagePath">
                </div>
                <div class="card item-3 med">
                    <img [src]="images[2].imagePath">
                </div>

                <!-- row-3 div 50% width 4:3 & 4:3 -->
                <div class="card item-4 med">
                    <img [src]="images[0].imagePath">
                </div>
                <div class="card item-5 med">
                    <img [src]="images[1].imagePath">
                </div> 

                <!-- row-4 div 80% width -->
                <div class="card item-6 sm">
                    <img [src]="images[0].imagePath">
                </div>

                <!-- row-5 div 20% width -->
                <div class="card item-7 sm">
                    <img [src]="images[0].imagePath">
                </div>
                
                <!-- row-6 div 20% width -->
                <div class="card item-8 sm">
                    <img [src]="images[1].imagePath">
                </div>

                <!-- row-7 3 div -->
                <div class= "card footer med col-num">
                    <img [src]="images[0].imagePath">
                </div>
                <div class= "card footer med col-num">
                    <img [src]="images[0].imagePath">
                </div>
                <div class= "card footer med col-num">
                    <img [src]="images[0].imagePath">
                </div>

            </div>
        </div>
    </div>
</body>
