<div id="target" style="background-color: #000000;">

    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    <div style="background-color: #000000;">
        <div class="container container-padding">
            <div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="hero-content header-text">
                            <h3
                                style="font-family: Montserrat; font-size: 25px; text-transform: uppercase; font-weight: 700;">
                                Our Products</h3>
                            <h1>CubeSat Modules</h1>
                        </div>
                    </div>
                </div>
            </div>
            <nav class="header-nav">
                <ul class="category-icons">
                    <li (click)="jumpToProducts('1')" [ngClass]="{'active': id === 1 }">CubeSat Platforms
                        <hr class="li_bar" [ngClass]="{'active': id === 1 }">
                    </li>
                    <li (click)="jumpToProducts('2')" [ngClass]="{'active': id === 2 }">Communications
                        <hr class="li_bar" [ngClass]="{'active': id === 2 }">
                    </li>
                      <!-- <li (click)="jumpToProducts('3')" [ngClass]="{'active': id === 3 }">Antennas
                        <hr class="li_bar" [ngClass]="{'active': id === 3 }">
                    </li>-->
                    <li (click)="jumpToProducts('4')" [ngClass]="{'active': id === 4 }">Beacon
                        <hr class="li_bar" [ngClass]="{'active': id === 4 }">
                    </li>
                    <li (click)="jumpToProducts('5')" [ngClass]="{'active': id === 5 }">Onboard Computers
                        <hr class="li_bar" [ngClass]="{'active': id === 5 }">
                    </li>
                    <li (click)="jumpToProducts('6')" [ngClass]="{'active': id === 6 }">Electrical Power Systems
                        <hr class="li_bar" [ngClass]="{'active': id === 6 }">
                    </li>
                    <!--  <li (click)="jumpToProducts('7')" [ngClass]="{'active': id === 7 }">Solar Panels
                        <hr class="li_bar" [ngClass]="{'active': id === 7 }">
                    </li>-->
                    <li (click)="jumpToProducts('8')" [ngClass]="{'active': id === 8 }">Battery Board
                        <hr class="li_bar" [ngClass]="{'active': id === 8 }">
                    </li>
                    <li (click)="jumpToProducts('9')" [ngClass]="{'active': id === 9 }">Structures
                        <hr class="li_bar" [ngClass]="{'active': id === 9 }">
                    </li>
                    <li (click)="jumpToProducts('10')" [ngClass]="{'active': id === 10 }">Payloads
                        <hr class="li_bar" [ngClass]="{'active': id === 10 }">
                    </li>
                    <!--<li (click)="jumpToProducts('11')" [ngClass]="{'active': id === 11 }">Educational Modules
                        <hr class="li_bar" [ngClass]="{'active': id === 11 }">
                    </li>-->
                </ul>
            </nav>
            <article class="article-padding">
                <div class="products-collection-div">

                    <!-- CubeSat Platforms -->
                    <div id="1" class="products-div" style="margin-top: -10px;">
                        <div class="product-img-with-detail-section-left">
                            <h2>CubeSat Platforms</h2>
                            <p>Proven CubeSat Systems</p>

                            <ul class="products-list">
                                <!-- <li>
                                    <a>6U CubeSat Platform
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/6-U">Explore</a></span>
                                </li> 
                                <li>
                                    <a>3U CubeSat Platform
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/3-U">Explore</a></span>
                                </li>
                                <li>
                                    <a>2U CubeSat Platform
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/2-U">Explore</a></span>
                                </li> -->
                                <li>
                                    <a>1U CubeSat Platform
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/1-U">Explore</a></span>
                                </li>
                            </ul>
                            <p style="margin-top: 50px; font-size: 14px;">Engineering support included: <strong
                                    style="color: #fff;">20 hours</strong></p>
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/1U.png">
                            </picture>
                        </div>

                    </div>

                    <!-- Communications -->
                    <div id="2" class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h2>Communication</h2>
                            <p>High speed data from space</p>

                            <ul class="products-list">
                                <!-- <li>
                                    <a>X-Band Transmitter
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/x-band-transmitter">Explore</a></span>
                                </li>
                                <li>
                                    <a>S-Band Transmitter
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/s-band-transmitter">Explore</a></span>
                                </li>
                                <li>
                                    <a>S-Band Receiver
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/s-band-receiver">Explore</a></span>
                                </li> -->
                                <li>
                                    <a>UHF Transceiver
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/uhf-transceiver">Explore</a></span>
                                </li>
                            </ul>

                            <p style="margin-top: 50px; font-size: 14px;">Engineering support included: <strong
                                    style="color: #fff;">10 hours</strong></p>
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/comms/1.PNG">
                            </picture>
                        </div>

                    </div>

                    <!-- Antennas 
                    <div id="3" class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h2>Antennas</h2>
                            <p>Stable Communication </p>

                            <ul class="products-list">
                                <li style="padding: 15px;">
                                    <a>Antenna Board
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/antenna-board">Explore</a></span>
                                </li>
                                 <li style="padding: 15px;">
                                    <a>X-Band 2x2 Patch Array
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/x-band-2x2-patch-array">Explore</a></span>
                                </li>
                                <li style="padding: 15px;">
                                    <a>X-Band Patch Antennas
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/x-band-patch-array">Explore</a></span>
                                </li>
                                <li style="padding: 15px;">
                                    <a>UHF Antenna 2U
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/uhf-antennas-2u">Explore</a></span>
                                </li>
                                <li style="padding: 15px;">
                                    <a>UHF Antenna 3
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/uhf-antennas-3">Explore</a></span>
                                </li>
                                <li style="padding: 15px;">
                                    <a>S-Band Antenna <br> Commercial
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/s-band-antenna-commercial">Explore</a></span>
                                </li>
                                <li style="padding: 15px;">
                                    <a>S-Band Antenna ISM
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/s-band-antenna-ism">Explore</a></span>
                                </li>
                                <li style="padding: 15px;">
                                    <a>S-Band Antenna <br>Wideband
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/s-band-antenna-wideband">Explore</a></span>
                                </li> 

                            </ul>

                             <p style="margin-top: 50px; font-size: 14px;">Engineering support included:<strong
                                style="color: #fff;">5 hours</strong></p>
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/Antenna/2.PNG">
                            </picture>
                        </div>

                    </div>-->

                    <!-- Beacon -->
                    <div id="4" class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h2>Beacon</h2>
                            <p>Independent Proof of Life</p>

                            <ul class="products-list">
                                <li style="padding: 15px;">
                                    <a>Beacon
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/beacon">Explore</a></span>
                                </li>
                            </ul>
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/Beacon/2.PNG">
                            </picture>
                        </div>

                    </div>

                    <!-- Onboard Computers -->
                    <div id="5" class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h2>Onboard Computers</h2>
                            <p>Reliable Onboard Computer to manage the satellite systems </p>

                            <ul class="products-list">
                                <li>
                                    <a>Onboard Computer (OBC)
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/onboard-computer-obc">Explore</a></span>
                                </li>

                            </ul>
                            <p style="margin-top: 50px; font-size: 14px;">Engineering support included:<strong
                                    style="color: #fff;">5 hours</strong></p>
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/OBC/3.png">
                            </picture>
                        </div>

                    </div>

                    <!-- Power Modules -->
                    <div id="6" class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h2>Electrical Power Systems</h2>
                            <p>Power and Efficiency in orbit</p>

                            <ul class="products-list">
                                <!-- <li>
                                    <a>EPS &#8544;&#8544; + Battery Pack
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/eps-power-module-two">Explore</a></span>
                                </li>
                                <li>
                                    <a>EPS &#8544; Plus
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/eps-power-module-i-plus">Explore</a></span>
                                </li> -->
                                <li>
                                    <a>EPS
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/eps-power-module">Explore</a></span>
                                </li>
                            </ul>
                            <p style="margin-top: 50px; font-size: 14px;">Engineering support included:<strong
                                    style="color: #fff;">5 hours</strong></p>
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/EPS/1.png">
                            </picture>
                        </div>

                    </div>

                    <!-- Solar Panels
                    <div id="7" class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h2>Solar Panels</h2>
                            <p>Power Generation Ensured</p>

                            <ul class="products-list">
                               <li style="padding: 15px;">
                                    <a>6U Deployable Solar <br>Array
                                    </a> <span><a class="explore-variant" 
                                        routerLink="/product-variants/solar-panel-6u-deployable-solar-array">Explore</a></span>
                                </li>
                                <li style="padding: 15px;">
                                    <a>6U Solar Panel
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/solar-panel-6u-deployable-solar-panel">Explore</a></span>
                                </li>
                                <li style="padding: 15px;">
                                    <a>3U Deployable Solar <br> Array
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/solar-panel-3u-deployable-solar-array">Explore</a></span>
                                </li> 
                                <li style="padding: 15px;">
                                    <a>3U Solar Panel
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/solar-panel-3u">Explore</a></span>
                                </li>
                                 <li style="padding: 15px;">
                                    <a>1.5U Solar Panel
                                    </a> <span><a class="explore-variant"
                                        routerLink="/product-variants/solar-panel-1-5u-solar-panel-xy">Explore</a></span>
                                </li> 
                                <li style="padding: 15px;">
                                    <a>2U Solar Panel
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/solar-panel-2u">Explore</a></span>
                                </li>
                                <li style="padding: 15px;">
                                    <a>1U Solar Panel
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/solar-panel-1u">Explore</a></span>
                                </li>
                            </ul>
                            <p style="margin-top: 50px; font-size: 14px;">Engineering support included:<strong
                                style="color: #fff;">5 hours</strong></p>
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/solar/1.png">
                            </picture>
                        </div>

                    </div> -->

                    <!-- Battery Board -->
                    <div id="8" class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h2>Battery Board</h2>
                            <p>Stable Communication </p>

                            <ul class="products-list">
                                <li style="padding: 15px;">
                                    <a>Battery Board
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/battery-board">Explore</a></span>
                                </li>
                            </ul>
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/battery/1.png">
                            </picture>
                        </div>

                    </div>

                    <!-- Structures -->
                    <div id="9" class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h2>Structures</h2>
                            <p>Extreme Precision and Durability</p>

                            <ul class="products-list">
                                <!-- <li>
                                    <a>6U CubeSat Structure
                                    </a> <span><a class="explore-variant" 
                                        routerLink="/product-variants/6u-cubesat-structure">Explore</a></span>
                                </li> 
                                <li>
                                    <a>3U CubeSat Structure
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/3u-cubesat-structure">Explore</a></span>
                                </li>
                                <li>
                                    <a>2U CubeSat Structure
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/2u-cubesat-structure">Explore</a></span>
                                </li>
                             <li>
                                    <a>Support Equipment - <br>JIG
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/support-equipment-jig">Explore</a></span>
                                </li>-->
                                <li>
                                    <a>1U CubeSat Structure
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/1u-cubesat-structure">Explore</a></span>
                                </li>
                               
                            </ul>
                            <!-- <p style="margin-top: 10px; font-size: 14px;">Engineering support included:<strong
                                style="color: #fff;">1 hours</strong></p> -->
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/structure/1U/1a.PNG">
                            </picture>
                        </div>

                    </div>

                    <!-- Payloads -->
                    <div id="10" class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h2>Payloads</h2>
                            <p>Solve Your Challenge</p>

                            <ul class="products-list">
                                <!-- <li>
                                    <a>Custom 6U Solar Panel
                                    </a> <span><a class="explore-variant" 
                                        routerLink="/product-variants/custom-6u-solar-panel">Explore</a></span>
                                </li> 
                             <li>
                                    <a>Cross Band Repeater
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/cross-band-repeater">Explore</a></span>
                                </li>-->
                                <li>
                                    <a>Payload Board
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/payload-board">Explore</a></span>
                                </li>
                               
                            </ul>
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/2U.png">
                            </picture>
                        </div>

                    </div>

                    <!-- Educational Modules 
                    <div id="11" class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h2>Educational Modules</h2>
                            <p>Learn By Doing</p>

                            <ul class="products-list">
                                <li>
                                    <a>Space Comm System for <br> Education
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/educational-comm-system">Explore</a></span>
                                </li>
                                <li>
                                    <a>1U CubeSat Protoboard
                                    </a> <span><a class="explore-variant"
                                            routerLink="/product-variants/protoboard">Explore</a></span>
                                </li>
                            </ul>
                        </div>
                        <div class="product-img-with-detail-section-right">
                            <picture>
                                <img src="assets/img/products/2U.png">
                            </picture>
                        </div>

                    </div>-->
                </div>

            </article>

            <div class="remarkable-satellites-padding">
                <div class="row">
                    <div class="col col--1of6" style="padding-top: 1rem;">
                        <p class="filler" style="font-weight: 700; font-size: 22px;">WE CREATE REMARKABLE SATELLITES</p>
                    </div>
                    <div class="col col--1of6" style="border: 1px solid #191f2b;">
                        <div class="filler">
                            <p> CONFIGURE <br>
                                TIME </p>
                            <i style="font-size: 40px; color: #018FF4;" class="icofont-satellite"></i>
                            <p> 5 min</p>
                        </div>
                    </div>
                    <div class="col col--1of6" style="border: 1px solid #191f2b;">
                        <div class="filler">
                            <p>
                                DELIVERY<br>
                                TIME
                            </p>
                            <i style="font-size: 40px; color: #018FF4; margin: 10px;" class="icofont-satellite"></i>
                            <p>
                                Unmatched
                            </p>
                        </div>
                    </div>
                    <div class="col col--1of6" style="border: 1px solid #191f2b;">
                        <div class="filler">
                            <p>
                                MODULES<br>
                                COMPATIBILITY
                            </p>
                            <i style="font-size: 40px; color: #018FF4; margin: 10px;" class="icofont-satellite"></i>
                            <p>
                                Complete
                            </p>
                        </div>
                    </div>
                    <div class="col col--1of6" style="border: 1px solid #191f2b;">
                        <div class="filler">
                            <p>
                                MODULES<br>
                                COMPATIBILITY
                            </p>
                            <i style="font-size: 40px; color: #018FF4; margin: 10px;" class="icofont-satellite"></i>
                            <p>
                                Complete
                            </p>
                        </div>
                    </div>
                    <div class="col col--1of6" style="border: 1px solid #191f2b;">
                        <div class="filler">
                            <p>
                                VERIFICATION & <br>
                                TESTING
                            </p>
                            <i style="font-size: 40px; color: #018FF4; margin: 10px;" class="icofont-satellite"></i>
                            <p>
                                Yes
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <footer style="width: 100%; margin-top: 150px;">
                <div style="text-align: center; padding: 100px 0;">
                    <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
                        BEYOND THE HORIZON
                    </h5>
                </div>
            </footer>
        </div>
    </div>
</div>
