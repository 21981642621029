<body>
    <div id="target" class="flexbox">
        
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
        <div class="title-container">
            <div class="row mb-4" style="margin-right: 0; margin-top: -8%;">
                <div class="col-lg-12">
                    <h2 style="font-size: 25px; font-weight: 700; color: #018ff4;">Gallery-Details</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <h2>
                        {{albumObj.name}}
                    </h2>
                </div>
                <div class="col-lg-6 col-md-6">
                    <p>
                        {{albumObj.createdDate | date:'dd/MM/yyyy'}}</p>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-lg-12 col-md-12">
                    <p>
                        {{albumObj.createdDate | date:'dd/MM/yyyy'}}</p>
                </div>
            </div> -->
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <p class="content" style="float: left;">{{albumObj.detail}}</p>
                </div>
            </div>
            <div class="row mb-3" style="text-align: right; z-index: 999; position: absolute; right: 81px;">
                <div class="col-lg-12 col-md-12">
                    <a (click)="showImages()" style="color: #fff; margin-right: 10px; cursor: pointer;" class="btn btn-primary btn-gradient">Images</a>
                    <a (click)="showVideos()" style="color: #fff; margin-right: 10px; cursor: pointer;" class="btn btn-primary btn-gradient">Videos</a>
                </div>
            </div>
        </div>
    </div>

    <div class="gallery-wrapper mt-5" *ngIf="imageSection">
        <div class="gallery-container" *ngFor="let imgs of images" [ngClass]="{'w-2 h-3': imgs.resolution == '4 x 3', 'w-3 h-2': imgs.resolution == '16 x 9'}">
            <div class="gallery-item" *ngIf="imgs.mediaType != 'VIDEO'" >
                <div class="image">
                    <img [src]="imagePaths[imgs.id]" alt="4:3" >
                </div> 
                <!-- <div class="text">
                    <h2>
                        {{imgs.resolution}}
                    </h2>
                </div> -->
            </div>
        </div>
    </div>

    <!-- <div class="container mt-5" *ngIf="videoSection">
        <div class="row">
            <div class="col-lg-6 col-12">
                <iframe height="450" src="https://www.youtube.com/embed/dwLHaS6-tUo" style="width: 100%;"></iframe>
            </div>
            <div class="col-lg-6 col-12">
                <iframe height="450" src="https://www.youtube.com/embed/dwLHaS6-tUo" style="width: 100%;"></iframe>
            </div>
            <div class="col-lg-6 col-12">
                <iframe height="450" src="https://www.youtube.com/embed/dwLHaS6-tUo" style="width: 100%;"></iframe>
            </div>
            <div class="col-lg-6 col-12">
                <iframe height="450" src="https://www.youtube.com/embed/dwLHaS6-tUo" style="width: 100%;"></iframe>
            </div>
        </div>
    </div> -->



    <footer style="width: 100%; background-color: #000000;">
        <div style="text-align: center; padding: 100px 0;">
            <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
                BEYOND THE HORIZON
            </h5>
        </div>
    </footer>

</body>