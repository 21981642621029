<section id="target" class="page-title-banner">
    
    <!-- <div class="rn-gradient-circle theme-pink"></div> -->
    <div class="header">
        <h2 class="get-in-touch">
            GET IN TOUCH, REACH OUT
            TO SALES OR SUPPORT
        </h2>
    </div>
    <div class="team-mobile-area" style="width: 50%; height: 200px; float: right; margin-top: 45px;">
        <div class="team-holder">
            <div style="width: 100%;">
                <div class="team-person">
                    <!-- <a href="www.itca.org.in" target="_blank"><img src="assets/img/partners/itcanew.png" alt="image"></a> -->
                </div>
                <div class="team-person">
                    <a href="https://www.wcrc.world" target="_blank"><img src="assets/img/partners/wcrc3.png" alt="image"></a>
                </div>
            </div>
            <div class="team-person-margin-top" style="width: 100%;">
                <div class="team-person">

                </div>
                <div class="team-person">
                    <!-- <a href="www.75satellites.org" target="_blank"><img src="assets/img/partners/75sat.png" alt="image" style="vertical-align: baseline; width: 70%;"></a> -->
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Start Contact Area -->
<section class="contact-area ptb-100 bg-black">
    <div class="container container-left-margin">
        <div class="section-title" style="text-align: center;">
            <h1></h1>
        </div>

        <div class="h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                <div class="tab">
                    <ul class="tabs">
                        <li class="list-item-spacing_two"><a href="#">
                            <i style="font-size: 40px;" class="icofont-iphone"></i>
                            <br>
                            CALL US
                        </a></li>
                        <li class="list-item-spacing_one"><a href="#">
                                <i style="font-size: 40px;" class="icofont-ui-user-group"></i>
                                <br>
                                MEET US
                            </a></li>
                        <li class="list-item-spacing_three"><a href="#">
                                <i style="font-size: 40px;" class="icofont-email"></i>
                                <br>
                                EMAIL US
                            </a></li>
                        <li class="list-item-spacing_four"><a href="#">
                            <i  class="icofont-brand-microsoft" ></i>
                            <br>
                            APPOINTMENT
                        </a></li>
                    </ul>

                    <div class="tab_content" style="background-color: #131922;">

                        <!-- Call Us Section  -->
                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <hr class="call-separation">
                                <div class="col-lg-6 col-md-6 call-section-one">
                                    <div class="tabs_item_content mb-0 contact">
                                            <h3 class="mb-3">PHONE</h3>
                                            <p class="phone-icon call-location">
                                                 <a style="margin-top: 70px;" href="tel:8665562570">
                                                    <i class="icofont-iphone phone">
                                                    </i>
                                                </a>
                                            </p>
                                        <!-- REPLACE VARIABLE 8665562570 WITH PROPER NUMBER, ALSO REMOVE 'Put Mobile Number Here for Call' AND PUT SAME NUMBER HERE -->
                                        <a style="margin-top: 70px; color: #018ff4;" href="tel:8665562570">Put Mobile Number Here for Call</a>
                                        <p>Business Hours</p>
                                        <p>10AM-6PM (IST)</p>
                                    </div>
                                </div>
                                <!-- <hr style="position: relative; transform: rotate(90deg); color: azure;"> -->

                                <div class="col-lg-6 col-md-6 call-section-two">
                                    <div class="tabs_item_content mb-0 contact">
                                        <h3 class="mb-3">Whatsapp</h3>
                                        <p class="phone-icon call-location">
                                            <a href="https://api.whatsapp.com/send?phone=your_number_here">
                                                <i style="color: #e5e5e5; position: absolute;" class="icofont-brand-whatsapp whatsapp">
                                                    </i>
                                            </a>
                                        </p>
                                        <!-- REPLACE VARIABLE your_phone_number & 'Put Mobile Number Here for Chat' WITH PROPER NUMBER -->
                                        <a style="margin-top: 70px; color: #018ff4;" href="https://api.whatsapp.com/send?phone=your_number_here">Put Mobile Number Here for Chat</a>
                                        <p>Business Hours</p>
                                        <p>10AM-6PM (IST)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Address Section -->
                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center padding-on-map">
                                <div class="col-lg-12 col-md-12">
                                    <div class="tabs_item_content mt-0" style="text-align: center;">
                                        <h3>ADDRESS</h3>
                                        <div style="filter: invert(90%)" >
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124421.71236573679!2d77.57950895820312!3d12.960426400000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1529f95925e5%3A0xf3e7485609373b31!2sTSC%20Tech!5e0!3m2!1sen!2sin!4v1645424726268!5m2!1sen!2sin" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

                                        </div>
                                        <address style="color: #e5e5e5; margin-top: 10px;">
                                            7th Floor, Maruthi Platinum, Road, ITPL Main Rd, <br>
                                            Lakshminarayana Pura, Kundalahalli, Brookefield <br>
                                            Bengaluru, Karnataka 560037
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Email Us Form  -->
                        <div class="tabs_item custom_row">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="tabs_item_content mt-3">
                                        <form #contactForm="ngForm" novalidate (ngSubmit)="contactApply(contactForm)">
                                            <div class="row mt-5">
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12  mb-3">
                                                    <div class="form-group">
                                                        <input class="form-control" id="fullName" name="fullName" type="text"
                                                            [ngClass]="{'is-invalid': !fullName.valid && (fullName.dirty || fullName.touched || isSubmit)}"
                                                            #fullName="ngModel" required [(ngModel)]="fullNam">
                                                        <label>Full Name</label>
                                                        <span style="font-size: 12px;"
                                                            *ngIf="!fullName.valid && (fullName.dirty || fullName.touched || isSubmit)"
                                                            id="validation-fullName-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">Full
                                                            Name is
                                                            Required.</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12  mb-3">
                                                    <div class="form-group">
                                                        <input class="form-control" id="email" name="email" type="email"
                                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                            [ngClass]="{'is-invalid': !email.valid && (email.dirty || email.touched || isSubmit)}"
                                                            #email="ngModel" required [(ngModel)]="eMail">
                                                        <label>E-mail</label>
                                                        <span style="font-size: 12px;"
                                                            *ngIf="!email.valid && (email.dirty || email.touched || isSubmit)"
                                                            id="validation-email-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">E-mail
                                                            is
                                                            Required.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12  mb-3">
                                                    <div class="form-group">
                                                        <input class="form-control" id="contactNumber" name="contactNumber"
                                                            type="text" maxlength="12" minlength="10"
                                                            (keypress)="keyPressNumbersDecimal($event)"
                                                            [ngClass]="{'is-invalid': !contactNumber.valid && (contactNumber.dirty || contactNumber.touched || isSubmit)}"
                                                            #contactNumber="ngModel" required [(ngModel)]="mobileNumber">
                                                        <label>Mobile Number</label>
                                                        <span style="font-size: 12px;"
                                                            *ngIf="!contactNumber.valid && (contactNumber.dirty || contactNumber.touched || isSubmit)"
                                                            id="validation-contactNumber-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">Mobile
                                                            Number is
                                                            Required.</span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-12 col-12  mb-3">
                                                    <div class="form-group">
                                                        <input class="form-control" id="topic" name="topic"
                                                            type="text"
                                                            [ngClass]="{'is-invalid': !topic.valid && (topic.dirty || topic.touched || isSubmit)}"
                                                            #topic="ngModel" required [(ngModel)]="subjects">
                                                        <label>Subject</label>
                                                        <span style="font-size: 12px;"
                                                            *ngIf="!topic.valid && (topic.dirty || topic.touched || isSubmit)"
                                                            id="validation-topic-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">This
                                                            Field is
                                                            Required.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-5">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12  mb-3">
                                                    <div class="form-group">
                                                        <textarea class="form-control" id="message" name="message"
                                                            type="text" cols="30" rows="3" style="height: 100%;"
                                                            [ngClass]="{'is-invalid': !message.valid && (message.dirty || message.touched || isSubmit)}"
                                                            #message="ngModel" required [(ngModel)]="msg">
                                                        </textarea>
                                                        <label>Your Message</label>
                                                        <span style="font-size: 12px;"
                                                            *ngIf="!message.valid && (message.dirty || message.touched || isSubmit)"
                                                            id="validation-message-error"
                                                            class="warn error jquery-validation-error small form-text invalid-feedback">This
                                                            Field is
                                                            Required.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" style="margin-top: 1rem;">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                    <label id="myLabel" class="main">I want to receive company
                                                        news and product updates via email (optional)
                                                        <input type="checkbox" class="form-control"
                                                            id="recieveUpdateCheckbox" name="recieveUpdateCheckbox"
                                                            #recieveUpdateCheckbox="ngModel"
                                                            [(ngModel)]="optionalUpdate">
                                                        <span style="margin-top: 0;" class="geekmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                                    <label id="myLabel" class="main">I give my consent to
                                                        TSC to store and administrate my personal data with
                                                        the
                                                        purpose of responding to my request.
                                                        <input type="checkbox" class="form-control" id="consentCheckbox"
                                                            name="consentCheckbox" #consentCheckbox="ngModel"
                                                            [(ngModel)]="consent">
                                                        <span style="margin-top: 0;" class="geekmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <!-- <div class="row mt-1">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12" style="text-align: justify; font-size: 16px; padding-left: 2rem;">
                                                    <label>This site is protected by reCAPTCHA and the Google <a>Privacy
                                                            Policy</a> and <a>Terms of Service</a> apply.</label>
                                                </div>
                                            </div> -->
                                            <div class="row mb-4">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12"
                                                    style="text-align: right;">
                                                    <!-- <button type="submit" class="btn btn-primary btn-gradient">Send Message</button> -->

                                                    <button>
                                                        <div class="svg-wrapper-1">
                                                            <div class="svg-wrapper">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24" width="24" height="24">
                                                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                                                    <path fill="currentColor"
                                                                        d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <span>Send</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Microsoft Section -->
                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center" style="padding: 50px;">
                                <div class="col-lg-12 col-md-12">
                                    <div class="tabs_item_content mt-0" style="text-align: center;">
                                        <h2 style="color: #fff;">Book an Appointment.</h2>
                                        <a style="color: #018ff4;" class="mt-3" href="https://outlook.office365.com/owa/calendar/TSCTechnologies@tsctech.in/bookings/s/5GJ5zPMQikK6zm7GksEDZg2" target="_blank">Click Here to book an Appointment.</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div style="width: 85%; margin-top: 110px; margin-left: 0%;">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 col-12 separator-address">
                    <p class="p-header">TSC Tech Labs HEADQUARTERS</p>
                    <p class="p-header">GST 29AAHCT7891C1ZV</p>
                    <p class="mt-4 mb-4" style="line-height: 1.7;">7th Floor, Maruthi Platinum, Road, ITPL Main Rd,<br>
                        Lakshminarayana Pura, Kundalahalli, Brookefield<br>
                        Bengaluru, Karnataka 560037
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 col-12 separator-address">
                    <p class="p-header">TSC Registered Office</p>
                    <p class="p-header">GST 29AAHCT7891C1ZV</p>
                    <p class="mt-4 mb-4" style="line-height: 1.7;">#184C, Shantiniketan,<br>
                        2nd Cross, 4th Main, Gayathri <br> Extn,
                        Devasandra, K.R.Puram, Bangalore - 560036
                    </p>
                </div>
                <!-- <hr class="separator-address"> -->
                <div class="col-lg-3 col-md-6 col-sm-12 col-12 separator-address">
                    <p class="p-header">World CanSat/Rocketery Consortium</p>
                    <p class="mb-4" style="line-height: 1.7; margin-top: 40px;">#3, Fourth Floor, 1st Main Rd,<br>
                         HAL 2nd Stage, BDA<br>
                        Layout, Kodihalli, Bangalore,<br>
                        560008
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 col-12 separator-address last-separator-address">
                    <p class="p-header">UNISEC-INDIA</p>
                    <p class=" mb-4" style="line-height: 1.7; margin-top: 32px;"><br>#3, Fourth Floor, 1st Main Rd,<br>
                        HAL 2nd Stage, BDA<br>
                       Layout, Kodihalli, Bangalore,<br>
                       560008
                    </p>
                </div>
            </div>
        </div>
        <div style="margin-top: 2%" class="bar"></div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start EVENTS Area -->
<section class="saas-tools ptb-100 bg-gray">
    <div class="container">
        <div class="section-title" style="margin-bottom: 20px;">
            <h2>EVENTS</h2>
        </div>

        <div class="tab">
            <!-- <ul class="tabs list-header">
                <li class="list-item-spacing-conf-schedule">
                    <a href="#" style="color: #29B7FF;">
                        PAST EVENTS
                    </a>
                </li>
                <li class="list-item-spacing-conf-schedule">
                    <a href="#" style="color: #29B7FF;">
                        UPCOMING EVENTS
                    </a>
                </li>
            </ul> -->

            <div class="tab_content ">
                <div class="tabs_item">
                    <div class="row justify-content-center past-events-row" *ngFor="let ev of eventsArray | slice:0:3; index as i">
                        <div class="col-lg-1 col-md-2 col-sm-4 col-4 past-events-col-first">
                            <div class="tabs_item_content mt-0 align">
                                <p>{{ev.startDate | date: 'mediumDate'}}</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-8 col-8 past-events-col-others">
                            <div class="tabs_item_content mt-0">
                                <p>{{ev.startTime | slice:0:5 }} - {{ev.endTime | slice:0:5}}</p>
                                <p>{{ev.title | slice:0:20}}</p>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 past-events-col-others">
                            <div class="tabs_item_content mt-0">
                                <p>{{ev.descriptionOne | slice:0:80}}</p>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12" class="events-read-more">
                                    <a [routerLink]="['/event-details']" [queryParams]="{ eventId: ev.id}" class="btn btn-primary btn-gradient"
                                        style="padding: 10px;">Explore</a>
                                    <!-- <a [routerLink]="/gallery" class="btn btn-primary btn-gradient" style="padding: 10px;">Event
                                        Gallery</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row justify-content-center past-events-row mt-2">
                        <div class="col-lg-1 col-md-2 col-sm-4 col-4 past-events-col-first">
                            <div class="tabs_item_content mt-0 align">
                                <p>8</p>
                                <p>Feb,</p>
                                <p>2021</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-8 col-8 past-events-col-others">
                            <div class="tabs_item_content mt-0">
                                <p>04PM - 10PM (CET)</p>
                                <p>SMALLSAT SYMPOSIUM 2021 Day 1</p>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 past-events-col-others">
                            <div class="tabs_item_content mt-0">
                                <p>EnduroSat is showcasing its latest technology and services at the SmallSat Symposium
                                    2021. Meet us at our virtual booth, and don’t miss out on our limited special
                                    offerings during the event</p>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12" class="events-read-more">
                                    <a routerLink="/" class="btn btn-primary btn-gradient"
                                        style="padding: 10px;">Explore</a>
                                    <a routerLink="/gallery" class="btn btn-primary btn-gradient" style="padding: 10px;">Event
                                        Gallery</a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="row justify-content-center past-events-row mt-2">
                        <div class="col-lg-1 col-md-2 col-sm-4 col-4 past-events-col-first">
                            <div class="tabs_item_content mt-0 align">
                                <p>8</p>
                                <p>Feb,</p>
                                <p>2021</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-2 col-sm-8 col-8 past-events-col-others">
                            <div class="tabs_item_content mt-0">
                                <p>04PM - 10PM (CET)</p>
                                <p>SMALLSAT SYMPOSIUM 2021 Day 1</p>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 past-events-col-others">
                            <div class="tabs_item_content mt-0">
                                <p>EnduroSat is showcasing its latest technology and services at the SmallSat Symposium
                                    2021. Meet us at our virtual booth, and don’t miss out on our limited special
                                    offerings during the event</p>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12" class="events-read-more">
                                    <a routerLink="/" class="btn btn-primary btn-gradient"
                                        style="padding: 10px;">Explore</a>
                                    <a routerLink="/gallery" class="btn btn-primary btn-gradient" style="padding: 10px;">Event
                                        Gallery</a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="tabs_item" style="border: 1px solid #191f2b; margin: 0 10%;">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-12 col-md-12">
                            <div class="tabs_item_content mb-0"
                                style="justify-content: center; text-align: center; padding: 2% 35%">
                                <i style="color: #29B7FF; font-size: 30px; margin-bottom: 50px;"
                                    class="icofont-ui-calendar"></i>
                                <p class="mt-3">There are no events available.</p>
                                <p>Check back later.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mt-0">
                                <h3>Calendar</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> Creative Design</li>
                                    <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                    <li><i class="icofont-ui-check"></i> Modern Design</li>
                                    <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/features-img1.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/features-img1.png" alt="img">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mb-0">
                                <h3>Invoicing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> Creative Design</li>
                                    <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                    <li><i class="icofont-ui-check"></i> Modern Design</li>
                                    <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mt-0">
                                <h3>Reporting</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> Creative Design</li>
                                    <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                    <li><i class="icofont-ui-check"></i> Modern Design</li>
                                    <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/features-img1.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 70px;" class="bar"></div>
    </div>
</section>
<!-- End EVENTS Area -->

<!-- Start OUR CONTACT TEAM Area -->
<!-- <section id="sales-team" class="blog-area features-area ptb-100" style="padding-bottom: 1px;">
    <div style="margin-bottom: 200px;">
        <div class="section-title contact-team-title">
            <h2>OUR CONTACT TEAM</h2>
        </div>
        <div class="rn-gradient-circle"></div>
    <div style="z-index: -999;" class="rn-gradient-circle theme-pink"></div>

        <div class="sales-team-slides owl-carousel owl-theme sales-team-padding">
            <div class="single-sales-team single-image">
                <div class="single-image" style="width: 350px; height: 350px;">
                    <img src="assets/img/extras/one.jpg" widt="350px" height="350px" alt="gallery">
                </div>
                <div style="top: 70%; text-align: center; padding: 10px;">
                    <h3 style="color: #e5e5e5; background-color:  bg-color;">John Doe John Doe</h3>
                    <p style="color: #e5e5e5;">Head of Sales <br>
                        Mob. : +91-8877967865 <br>
                        Email : JohnDoe@abc.com
                    </p>
                </div>
            </div>

            <div class="single-sales-team single-image">
                <div class="single-image" style="width: 350px; height: 350px;">
                    <img src="assets/img/extras/two.jpg" width="350px" height="350px" alt="gallery">
                </div>
                <div style="top: 70%; text-align: center; padding: 10px;">
                    <h3 style="color: #e5e5e5; background-color:  bg-color;">John Doe John Doe</h3>
                    <p style="color: #e5e5e5;">Head of Sales <br>
                        Mob. : +91-8877967865 <br>
                        Email : JohnDoe@abc.com
                    </p>
                </div>
            </div>

            <div class="single-sales-team single-image">
                <div class="single-image" style="width: 350px; height: 350px;">
                    <img src="assets/img/extras/three.jpg" width="350px" height="350px" alt="gallery">
                </div>
                <div style="top: 70%; text-align: center; padding: 10px;">
                    <h3 style="color: #e5e5e5; background-color:  bg-color;">John Doe John Doe</h3>
                    <p style="color: #e5e5e5;">Head of Sales <br>
                        Mob. : +91-8877967865 <br>
                        Email : JohnDoe@abc.com
                    </p>
                </div>
            </div>

        </div>
        <div style="margin-top: 70px;" class="bar"></div>

    </div>

    <footer style="width: 100%; background-color: #000000;">
        <div style="text-align: center; padding: 100px 0;">
            <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
                BEYOND THE HORIZON
            </h5>
        </div>
    </footer>
</section> -->
<!-- End OUR CONTACT TEAM Area -->