export class EventRegistrationRequestView {

    id: number;
    firstName:string;
    lastName: string;
    linkedinProfile: string;
    mobileNumber: string;
    city: string;
    email: string;
    eventsDetailId: number;
    createdDateTime: any;
    isDeleted: number;
    updatedDateTime: any;
}
