<section id="target" class="gallery-with-loop">
    
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
        <div class="row mb-4" style="margin-right: 0;">
            <div class="col-lg-12">
                <h2 style="font-size: 25px; font-weight: 700; color: #018ff4;">Gallery</h2>
            </div>
        </div>
    <div class="row">
        <div class="col-lg-4 col-12 row-images" *ngFor="let image of images; index as i">
            <div class="single-album">
                <div class="album-image">
                    <img [src]="imagePaths[image.id]" style="aspect-ratio: 16/9;">
                    <div class="cxDiv" (click)="getAlbumImages(image)">
                        <p class="mb-2" style="color: #fff; word-wrap: break-word; font-size: 26px;"> {{image.name}}</p>
                        <p style=" font-size: 24px;">{{image.createdDate  | date: 'dd/MM/yyyy'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<footer style="width: 100%; background-color: #000000;">
    <div style="text-align: center; padding: 100px 0;">
        <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
            BEYOND THE HORIZON
        </h5>
    </div>
</footer>