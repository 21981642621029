import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { JobServiceService } from "src/app/service/job-service.service";

@Component({
    selector: 'app-career',
    templateUrl: './career.component.html',
    styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {

    jobsDatabase: any;

    p: number = 1;

    constructor(
        private jobService : JobServiceService,
        private router: Router,
    ) {

    }

    ngOnInit(): void {
        this.toTop();

        this.jobService.getAllJobs().subscribe((Response) => {
            console.log(Response);
            this.jobsDatabase = Response.data;
        }, (Error) => {
            console.log(Error);
        })
    }

    toTop() {
        document.getElementById("target").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    goToJobPosition() {
        document.getElementById("jobPositions").scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest"
        });
    }

    openJobPosition(jobsObject) {
        console.log("jobId = " + jobsObject.id);
        this.router.navigate(["/career-details"], {queryParams: {jobId: jobsObject.id}})
    }
}