import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from './components/pages/alert/alert.component';
declare let $: any;

export interface AlertModel {
    title?: string;
    message: string;
  }

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: any;

    browserVersion : any;

    constructor(
        private router: Router,
        private SimpleModalService: SimpleModalService
    ) {
    }

    ngOnInit(){
        this.recallJsFuntions();
        this.get_browser();
        this.checkBrowser();
    }

    checkBrowser() {
          
        // Get the user-agent string
        let userAgentString = 
            navigator.userAgent;
      
        // Detect Chrome
        let chromeAgent = 
            userAgentString.indexOf("Chrome") > -1;
      
        // Detect Internet Explorer
        let IExplorerAgent = 
            userAgentString.indexOf("MSIE") > -1 || 
            userAgentString.indexOf("rv:") > -1;
      
        // Detect Firefox
        let firefoxAgent = 
            userAgentString.indexOf("Firefox") > -1;
      
        // Detect Safari
        let safariAgent = 
            userAgentString.indexOf("Safari") > -1;
              
        // Discard Safari since it also matches Chrome
        if ((chromeAgent) && (safariAgent)) 
            safariAgent = false;
      
        // Detect Opera
        let operaAgent = 
            userAgentString.indexOf("OP") > -1;
              
        // Discard Chrome since it also matches Opera     
        if ((chromeAgent) && (operaAgent)) 
            chromeAgent = false;
      
            console.log("Safari " + safariAgent);
            console.log("Chrome " + chromeAgent);
            console.log("IE " + IExplorerAgent);
            console.log("Opera " + operaAgent);
            console.log("Firefox " + firefoxAgent);
    }

    get_browser() {
        var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: 'IE', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR|Edge\/(\d+)/)
            if (tem != null) { return { name: 'Opera', version: tem[1] }; } 
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }

        console.log("Browser Name " + M[0]);
        console.log("Browser Version " + M[1]);

        this.browserVersion = M[1];

        if((M[0] === "Chrome") && (parseInt(M[1]) < 96)) {
           console.log("Old Version of chrome is found.");
           this.SimpleModalService.addModal(AlertComponent, {title: 'Alert title!', message: 'Alert message!!!'});
        }

        return {
            name: M[0],
            version: M[1]
        };
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if ( event instanceof NavigationStart ) {
                    $('.preloader-area').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $('.preloader-area').fadeOut('slow');
                this.location = this.router.url;
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }
}
