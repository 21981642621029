import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLConfig } from '../url-config';

@Injectable({
  providedIn: 'root'
})
export class LkpCategoryService {
  
  constructor(
    private httpClient: HttpClient,
    private urlConfig: URLConfig
  ) { }

  public getAllCategory(): Observable<any> {
    return this.httpClient.get(this.urlConfig.getAllLkpCategories);
  }

}
