import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SubscriptionRequestView } from "../components/layout/footer/footer.component";
import { URLConfig } from "../url-config";

@Injectable({
    providedIn: 'root'
})
export class subscriptionService {

    constructor(
        private httpClient: HttpClient,
        private urlConfig: URLConfig,
    ) {

    }

    public saveSubscription(subscriptionRequestView: SubscriptionRequestView): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
       
        return this.httpClient.post(this.urlConfig.saveSubscriptions, JSON.stringify(subscriptionRequestView), {headers: headers});
    }

}