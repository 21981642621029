import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-temp-gallery',
    templateUrl: './temp-gallery.component.html',
    styleUrls: ['./temp-gallery.component.scss']
})
export class TempGalleryComponent implements OnInit {

    images = [
        { "id": 1, "imagePath": "assets/img/extras/body-bg-4-1.jpg" },
        { "id": 2, "imagePath": "assets/img/extras/body-bg-6-1.jpg" },
        { "id": 3, "imagePath": "assets/img/extras/lower-resolution 43.png" },
        { "id": 4, "imagePath": "assets/img/extras/body-bg-4.jpg" },
    ]


    public id: number = 1;
    constructor(private titleService: Title) {
        // this.titleService.setTitle('Kilian - Angular 10 Startup Agency Landing Page');
    }

    ngOnInit() {
        this.toTop();
    }

    toTop() {
        document.getElementById("target").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    jumpToProducts(productId) {
        this.id = parseInt(productId);
        document.getElementById(productId).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    drop(ev) {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        ev.target.appendChild(document.getElementById(data));
    }

    allowDrop(ev) {
        ev.preventDefault();
    }

    drag(ev) {
        console.log(ev);
        ev.dataTransfer.setData("text", ev.target.id);
    }

}