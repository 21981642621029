import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface AlertModel {
  title?: string;
  message: string;
}

@Component({
  selector: 'alert',
  template: `
    <div class="modal-content" style="margin-top:250px; width:50%; background-color:#11171f; padding:10px">
      <div class="modal-header" style="border-bottom: 1px solid #19190f">
        <h2>Alert</h2>
      </div>
      <div class="modal-body" style="color:#fff; text-align:center">
        <h4>Your Browser is Outdated. </h4><br>
        <h4>Some Features may not load properly. </h4>
      </div>
      <div class="modal-footer" style="border-top: 1px solid #19190f">
        <button type="button" class="btn btn-primary" style="padding=0.375rem 0.75rem" (click)="close()">OK</button>
      </div>
    </div>
  `,
  styles: ['button { border: none; padding: 0.375rem 1.5rem; border-radius: 5px; text-transform: uppercase; position: relative; z-index: 1; transition: 0.9s; font-weight: 700; font-size: 16px;}', 
            'h2 {color:#018FF4}' ]
})
export class AlertComponent extends SimpleModalComponent<AlertModel, null> implements AlertModel {
  title: string;
  message: string;
  constructor() {
    super();
  }
}
