<div id="target" style="background-color: #000000;">
    
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    <div style="background-color: #000000;">
        <div class="container container-padding">

            <!-- Start join our team Area -->
            <article class="article-padding" style="margin-bottom: 20px;">
                <div class="products-collection-div">
                    <div class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h5 >JOIN OUR TEAM</h5>
                            <h2>Ever Wanted To Join<br>
                                Our Space Team ?</h2>

                            <div style="margin-top: 3rem;">
                                <div class="advantage">
                                   
                                    <p>
                                        Bangalore<br>
                                        India
                                    </p>
                                </div>
                                <div class="advantage">
                                    
                                    <p style="padding: 50px 0 0 0;">
                                        Founded <br>
                                        Fbruary 2020
                                    </p>
                                </div>
                                <div class="advantage">
                                   
                                    <p>
                                        8+ <br>
                                        Employees
                                    </p>
                                </div>
                            </div>
                            <p class="p-content" style="text-align: justify;">Our engineering team works directly with leading educational institutions around the Globe. We provide mentorship and support strongly the development of new space education and research missions.</p>
                            <a style="float: left;" (click)="goToJobPosition()" class="btn btn-primary btn-gradient mb-5">Open Positions</a>
                        </div>
                        <div class="product-img-with-detail-section-right" style="padding: 80px 0;">
                            <picture>
                                <img width="800"  src="assets/img/products/1U.png">
                            </picture>
                        </div>
                    </div>
                    
                </div>
                <div class="bar bar-margin"></div>
            </article>

            <article class="article-padding" style="margin-bottom: 20px;">
                <div class="products-collection-div">
                    <div class="products-div">
                        <div class="product-img-with-detail-section-left">
                            <h5 >WOrk at TSC Tech Labs</h5>
                            <h2>Join a team and inspire the work.</h2>

                            <p class="p-content" style="text-align: justify;">Discover how you can make an impact: see our areas of work, worldwide locations, and opportunities for students.</p>
                            <a style="float: left;" (click)="goToJobPosition()" class="btn btn-primary btn-gradient mb-5">Explore Opportunities at TSC Tech Labs</a>
                        </div>
                        <div class="product-img-with-detail-section-right" style="padding: 80px 0;">
                            <picture>
                                <img width="753" height="624" src="assets/img/extras/team.jpg">
                            </picture>
                        </div>
                    </div>
                    
                </div>
                <div class="bar bar-margin"></div>
            </article>
            <!-- End join our team Area -->

            
<!-- Section 2 Mentor's with Content Start -->
<section class="section_body">
    <div class="container">
        <div >
            <h2 style="font-size: 25px; font-weight: 700; text-transform: uppercase; color: #018FF4; margin-bottom: 2%;">Our values are part of everything built here — including careers.</h2>
        </div>
        <div class="row justify-content-center align-items-center">
            
            <div class="col-lg-6 col-md-12" style="margin-top: 4%;">
                <div class="about-content about-content-padding">
                    <div class="section-title" style="text-align: justify;">
                        <h2 style="text-align: center;">L&D Opportunities</h2>
                        <br>
                        <p>Great L&D Opportunities improving your knowledge, competencies, and skills to boost your productivity and job satisfaction</p>
                    </div>
                    <p></p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12" style="margin-top: 4%;">
                <div class="about-content  about-content-padding">
                    <div class="section-title">
                        <h2 style="text-align: center;">Health Coverage</h2>
                        <br>
                        <p>Paid sick leaves, work from home, and comprehensive health insurance because your health comes first.</p>
                    </div>
                    
                </div>
            </div>
            
        </div>

        <div class="row justify-content-center align-items-center">
           
            <div class="col-lg-6 col-md-12" style="margin-top: 4%;">
                <div class="about-content about-content-padding">
                    <div class="section-title" style="text-align: justify;">
                        <h2 style="text-align: center;">Flexi Working Hours</h2>
                        <br>
                        <p>We believe in a no work timings because you can’t time a creative process.</p>
                    </div>
                    <p></p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12" style="margin-top: 4%;">
                <div class="about-content about-content-padding">
                    <div class="section-title" style="text-align: justify;">
                        <h2 style="text-align: center;">Team Building</h2>
                        <br>
                        <p>A lot of fun team building activites ensuring better team communication and work environment</p>
                    </div>
                    <p></p>
                </div>
            </div>
           
        </div>
        <div style="margin-top: 2%" class="bar"></div>
    </div>
</section>
<!-- Section 2 Mentor's with Content End -->

            <!-- Start Our Core Values Area -->
            <div>
                <div class="container core-values">
                    <h2 style="float: left; font-size: 25px; color: #018FF4;">Our Core Values</h2>
                    <div class="row mt-5">
                        <div class="col-lg-3 col-md-12 text-center col-border">
                            
                            <p>Integrity</p>
                        </div>
                        <div class="col-lg-3 col-md-12 text-center col-border">
                          
                            <p>Teamwork</p>
                        </div>
                        <div class="col-lg-3 col-md-12 text-center col-border">
                           
                            <p>Learning</p>
                        </div>
                        <div class="col-lg-3 col-md-12 text-center col-border">
                         
                            <p>Passion</p>
                        </div>
                        
                    </div>
                    
                </div>
                <div style="margin-top: 2%" class="bar"></div>
            </div>
            <!-- End Our Core Values Area -->

            <!-- Start Highlights Area 
            <section style="margin-top: 4rem; padding: 10px;">
                <div class="container">
                    <div class="section-title">
                        <h2 style="color: #018FF4;">Highlights</h2>
                    </div>
                    <div class="overview-box" style="padding: 50px;">
                        <div class="row h-100 justify-content-center align-items-center">
                            <div class="col-lg-10 col-md-10 overview-img">
                                <img src="assets/img/features-img1.png" alt="img">
                            </div>
            
                            <div class="col-lg-2 col-md-2" style="margin-top: -20%;">
                                <h2 style="font-size: 25px;">Incredible Team</h2>
                            </div>
                        </div>
                    </div>

                    <div class="overview-box" style="padding: 50px;">
                        <div class="row h-100 justify-content-center align-items-center">
                            <div class="col-lg-2 col-md-2" style="margin-top: -20%;">
                                <h2 style="font-size: 25px;">Incredible Team</h2>
                            </div>
                            <div class="col-lg-10 col-md-10 overview-img">
                                <img src="assets/img/features-img1.png" alt="img">
                            </div>
                        </div>
                    </div>

                    <div class="overview-box" style="padding: 50px;">
                        <div class="row h-100 justify-content-center align-items-center">
                            <div class="col-lg-10 col-md-10 overview-img">
                                <img src="assets/img/features-img1.png" alt="img">
                            </div>
            
                            <div class="col-lg-2 col-md-2" style="margin-top: -20%;">
                                <h2 style="font-size: 25px;">Incredible Team</h2>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 2%" class="bar"></div>
                </div>
            </section>
            End Highlights Area -->

            <!-- Start Open Positions Area -->
            <section  id="jobPositions" style="padding-top: 80px;">
                <!-- <div class="container"> -->
                    <div class="section-title" style="margin-bottom: auto; margin-top: -35px;">
                        <h2 style="color: #018FF4;">Open Positions</h2>
                    </div>
                        
                    <div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                               
                            </div>
                        </div>
                        <div class="row h-100 justify-content-center align-items-center" style="margin-top: 20px;">
                            <div class="position-bar" *ngFor="let jobsObj of jobsDatabase | paginate: { itemsPerPage: 100, currentPage: p }; index as i">
                                <div class="job-title">
                                    <h4 style="font-size: 16px;">
                                        {{jobsObj.title}}
                                    </h4>
                                </div>
                                <div class="job-details">
                                    <!-- <div style="float: left; text-align: center;">
                                        <i style="font-size: 30px; color: #018FF4;" class="icofont-briefcase-2"></i>
                                        <p>
                                            {{jobsObj.type}}
                                        </p>
                                    </div> -->
                                    <div class="location text-center">
                                        <div style="display: flex; justify-content: center; align-items: center;">
                                            <i style="font-size: 30px; color: #018FF4;" class="icofont-location-pin"></i>
                                            <p>
                                                {{ jobsObj.location}}
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <a class="btn btn-primary btn-gradient open-position-explore" (click)="openJobPosition(jobsObj)">Explore</a>                                                                
                            </div>

                        </div>
                        <div style="margin-top: 2%" class="bar"></div>
                    </div>
                <!-- </div> -->
            </section>
            <!-- End Open Positions Area -->
            
        </div>

        <footer style="width: 100%; margin-top: 150px;">
            <div style="text-align: center; padding: 100px 0;">
                <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;"> 
                    BEYOND THE HORIZON
                </h5>
            </div>
        </footer>
    </div>
</div>