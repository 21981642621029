import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { URLConfig } from "../url-config";

@Injectable({
    providedIn: 'root'
})
export class PublicationService {

    constructor(
        private httpClient: HttpClient,
        private urlConfig: URLConfig,
    ) {

    }

    public getAllPublications(): Observable<any> {
        return this.httpClient.get(this.urlConfig.getAllPublications);
    }

    public getPublicationsByCategoryId(categoryId: number): Observable<any> {
        return this.httpClient.get(this.urlConfig.getPublicationsByCategoryId + categoryId);
    }

    public getPublicationByPublicationId(publicationId: number): Observable<any> {
        return this.httpClient.get(this.urlConfig.getPublicationsByPublicationId + publicationId);
    }

    public getPublicationImage(imagePath: string): Observable<any> {
        return this.httpClient.get(this.urlConfig.getPublicationImage + imagePath, {responseType: 'blob'});
    }
}