import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EventRegistrationRequestView } from 'src/app/request-view/event-registration-request-view';
import { CareerFormTitleService } from 'src/app/service/career-form-title-service';
import { EventService } from 'src/app/service/event.service';
import { CareerForm } from '../career-details/career-form/career-form.component';

@Component({
  selector: 'app-event-register-form',
  templateUrl: './event-register-form.component.html',
  styleUrls: ['./event-register-form.component.scss']
})
export class EventRegisterFormComponent implements OnInit {

  @Input() careerTitle;
  
  public i: any;

  // careerTitle:any;

  careerForms: CareerForm;
  careerCvFile:any;

  isSubmit: boolean;

  firstName:any;
  lastName:any;
  mobileNumber:any; 
  eMail:any;
  linkedinProfile:any;
  userCity:string;

  filePath: any = "Upload Your CV (.pdf up to 4MB) *";

  eventId: number;
  eventObj:any;

  public eventRegistrationRequestView = new EventRegistrationRequestView();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private careerFormService: CareerFormTitleService,
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(param => {
      console.log(param);
      this.eventId = param.eventId;
    }) 
  }

  ngOnInit() {
    this.toTop();
    this.careerFormService.message$.subscribe(message => {
      console.log(message);
      this.careerTitle = message;
    });

    this.eventService.getEventsById(this.eventId).subscribe((Response) => {
      console.log(Response);

      this.eventObj = Response.data;
    }, (Error) => {
      console.log(Error);
    }) 
  }

  toTop() {
    document.getElementById("target").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  fileChange(event) {
    console.log(event);
    console.log(event.target.files[0]);
    this.careerCvFile = event.target.files[0];
    
    if(this.careerCvFile.type != "application/pdf") {
      console.log("File Not Match");
      this.filePath = "Invalid File Type";
      return;
    } else {
      this.filePath = event.target.files[0].name;
    }
  }

  eventRegistration(careerForm:NgForm) {
    console.log(careerForm.value);
    console.log(this.careerCvFile);

    this.eventRegistrationRequestView.eventsDetailId = this.eventId;
    this.eventRegistrationRequestView.firstName = careerForm.value.fName;
    this.eventRegistrationRequestView.lastName = careerForm.value.lName;
    this.eventRegistrationRequestView.email = careerForm.value.email;
    this.eventRegistrationRequestView.linkedinProfile = careerForm.value.linkedIn
    this.eventRegistrationRequestView.mobileNumber = careerForm.value.mobile;
    this.eventRegistrationRequestView.city = careerForm.value.city;

    if(this.filePath === "Invalid File Type") {
      console.log("Abort Calling API.");
    } else {
      this.eventService.eventRegistration(this.eventRegistrationRequestView).subscribe((Response)=> {
        console.log(Response);

        careerForm.resetForm(careerForm);
      }, (Error) => {
        console.log(Error);
        alert("Please Try Again Later.");
      })
    }
  }
}
