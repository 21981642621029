import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
   
  newsContent: any[] = [
    {
        "title": "The Most Popular New Business Apps", //title requires min 34 characters.
        "date": "Dec 1, 2021",
        "content": "",
        "image" : "assets/img/logos/body-bg-6.jpg"
    },
    {
        "title": "The Most Popular New Business Apps",
        "date": "Dec 1, 2021",
        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.",
        "image" : "assets/img/logos/body-bg-6.jpg"
    },
    {
        "title": "The Most Popular New Business Apps",
        "date": "Dec 1, 2021",
        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "image" : "assets/img/extras/body-bg-4.jpg"
    },
]


public customOptions: OwlOptions = {
    loop: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    autoplayTimeout: 5000,
    navText: ['', ''],
    responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 4
        }
      },
    nav: false
};    
    constructor( private titleService: Title ) {
        // this.titleService.setTitle('Kilian - Angular 10 Blog Details Page');
    }

    ngOnInit() {
    }
}
