export class ContactRequestView {

    public id: number;
    public contactNumber: string;
    public email: string;
    public fullName: string;
    public message: string;
    public topic: string;
    public createdDate: string;
    public updatedDate: string;
    public isDeleted: number;
}