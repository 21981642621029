import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { FunfactComponent } from './components/common/funfact/funfact.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { StartupAgencyComponent } from './components/pages/home/startup-agency.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { CareerComponent } from './components/pages/career/career.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { AlertComponent } from './components/pages/alert/alert.component';
import { TempGalleryComponent } from './components/pages/temp-gallery/temp-gallery.component';
import { NewsComponent } from './components/pages/news/news.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PartnersComponent } from './components/pages/partners/partners.component';
import { NewsDetailComponent } from './components/pages/news-detail/news-detail.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { GalleryDetailComponent } from './components/pages/gallery-detail/gallery-detail.component';
import { EventsLandingComponent } from './components/pages/events-landing/events-landing.component';
import { EventsDetailComponent } from './components/pages/events-detail/events-detail.component';
import { EventRegisterFormComponent } from './components/pages/event-register-form/event-register-form.component';
import { CsrFundingLandingComponent } from './components/pages/csr-funding-landing/csr-funding-landing.component';
import { CsrFundingDetaiComponent } from './components/pages/csr-funding-detai/csr-funding-detai.component';
import { AccordionModule } from '@andreagrossetti/ngx-accordion';
import { NgxPaginationModule } from 'ngx-pagination';
import {NgbAccordionModule, NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import { NewsDetailDemoOneComponent } from './components/pages/news-detail-demo-one/news-detail-demo-one.component';
import { NewsDetailDemoTwoComponent } from './components/pages/news-detail-demo-two/news-detail-demo-two.component';
import { NewsDetailDemoThreeComponent } from './components/pages/news-detail-demo-three/news-detail-demo-three.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PublicationsComponent } from './components/pages/publications/publications.component';
import { NgxTinymceModule } from 'ngx-tinymce';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    FunfactComponent,
    PartnerComponent,
    StartupAgencyComponent,
    AboutUsComponent,
    GalleryComponent,
    BlogComponent,
    BlogDetailsComponent,
    ContactUsComponent,
    ComingSoonComponent,
    ProjectsComponent,
    ProductsComponent,
    CareerComponent,
    AlertComponent,
    TempGalleryComponent,
    PartnersComponent,
    GalleryDetailComponent,
    EventsLandingComponent,
    EventsDetailComponent,
    EventRegisterFormComponent,
    CsrFundingLandingComponent,
    CsrFundingDetaiComponent,
    NewsComponent,
    NewsDetailComponent,
    NewsDetailDemoOneComponent,
    NewsDetailDemoTwoComponent,
    NewsDetailDemoThreeComponent,
    PublicationsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    SimpleModalModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    AngularEditorModule ,
    HttpClientModule,
    AccordionModule,
    NgxPaginationModule,
    NgbAccordionModule,
    NgbCollapseModule,
    LazyLoadImageModule,
    
    NgxTinymceModule.forRoot({ 
      // baseURL: './assets/tinymce/',
      // or cdn
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/5.7.1/'
    })
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
