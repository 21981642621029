import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

    imageSourceOne = "assets/img/about/iei.png";
    imageSourceTwo = "assets/img/about/75sats.png";
    imageSourceThree = "assets/img/about/foreign.png";
    imageSourceFour = "assets/img/about/flight.png";

    public isActive: boolean;

    linkOne: boolean = true;
    linkTwo: boolean = false;
    linkThree: boolean = false;
    linkFour: boolean = false;

    activeLink: any;

    investorOne: boolean = true;
    investorTwo: boolean = false;
    investorThree: boolean = false;
    investorFour: boolean = false;
    selectedInvestor:any;

    teamId: any = 1;

    constructor(private route: ActivatedRoute) {
        this.route.queryParams.subscribe( params => {
            console.log(params);
            if(params.teamId == null || params.teamId == undefined){
                this.toTop();
            } else {
                this.teamId = parseInt(params.teamId);
            }
        });

        this.activeLink = '1';
        this.selectedInvestor = '1';
    }

    ngOnInit() {
       
        setTimeout(()=> {
            if(this.teamId == 1) {
                this.toTop();
            } else {
                this.jumpToProducts(this.teamId);
            }
        },1)
    }

    toTop() {
        document.getElementById("target").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    jumpToProducts(teamId) {
        document.getElementById(teamId).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    switchClasses(num) {
        this.activeLink = num;

        if(this.activeLink == 1) {
            this.linkOne = true;
            this.linkTwo = false;
            this.linkThree= false;
            this.linkFour = false;
        } if (this.activeLink == 2) {
            this.linkOne = false;
            this.linkTwo = true;
            this.linkThree= false;
            this.linkFour = false;
        } if (this.activeLink == 3) {
            this.linkOne = false;
            this.linkTwo = false;
            this.linkThree= true;
            this.linkFour = false;
        } if (this.activeLink == 4) {
            this.linkOne = false;
            this.linkTwo = false;
            this.linkThree= false;
            this.linkFour = true;
        }
    }

    switchInvestor(num) {
        this.selectedInvestor = num;
        if(num == 1) {
            this.investorOne = true;
            this.investorTwo = false;
            this.investorThree= false;
            this.investorFour = false;
        } if (num == 2) {
            this.investorOne = false;
            this.investorTwo = true;
            this.investorThree= false;
            this.investorFour = false;
        } if (num == 3) {
            this.investorOne = false;
            this.investorTwo = false;
            this.investorThree= true;
            this.investorFour = false;
        } if (num == 4) {
            this.investorOne = false;
            this.investorTwo = false;
            this.investorThree= false;
            this.investorFour = true;
        }
    }
}