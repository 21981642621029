import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartupAgencyComponent } from './components/pages/home/startup-agency.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { CareerComponent } from './components/pages/career/career.component';
import { PublicationsComponent } from './components/pages/publications/publications.component';
import { TempGalleryComponent } from './components/pages/temp-gallery/temp-gallery.component';
import { NewsComponent } from './components/pages/news/news.component';
import { PartnersComponent } from './components/pages/partners/partners.component';
import { NewsDetailComponent } from './components/pages/news-detail/news-detail.component';
import { GalleryDetailComponent } from './components/pages/gallery-detail/gallery-detail.component';
import { EventsLandingComponent } from './components/pages/events-landing/events-landing.component';
import { EventsDetailComponent } from './components/pages/events-detail/events-detail.component';
import { EventRegisterFormComponent } from './components/pages/event-register-form/event-register-form.component';
import { CsrFundingLandingComponent } from './components/pages/csr-funding-landing/csr-funding-landing.component';
import { CsrFundingDetaiComponent } from './components/pages/csr-funding-detai/csr-funding-detai.component';
import { NewsDetailDemoOneComponent } from './components/pages/news-detail-demo-one/news-detail-demo-one.component';
import { NewsDetailDemoThreeComponent } from './components/pages/news-detail-demo-three/news-detail-demo-three.component';
import { NewsDetailDemoTwoComponent } from './components/pages/news-detail-demo-two/news-detail-demo-two.component';


const routes: Routes = [
    { path: '', component: StartupAgencyComponent },
    { path: 'products', component: ProductsComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'careers', component: CareerComponent },
    { path: 'publications', component: PublicationsComponent },
    { path: 'headlines', component: NewsComponent },
    { path: 'news-details', component: NewsDetailComponent },
    { path: 'news-details-demo-one', component: NewsDetailDemoOneComponent },
    { path: 'news-details-demo-two', component: NewsDetailDemoTwoComponent },
    { path: 'news-details-demo-three', component: NewsDetailDemoThreeComponent },
    { path: 'temp', component: TempGalleryComponent },
    { path: 'gallery-detail', component: GalleryDetailComponent },
    { path: 'partners', component: PartnersComponent },
    { path: 'events', component: EventsLandingComponent },
    { path: 'event-details', component: EventsDetailComponent},
    { path: 'event-register', component: EventRegisterFormComponent},
    { path: 'csr-funding', component: CsrFundingLandingComponent },
    { path: 'csr-funding-detail', component: CsrFundingDetaiComponent },

    {
      path: 'product-variants',
      loadChildren: () => import('./components/pages/product-variants/product-variants.module').then(module => module.ProductVariantsModule)
    },
    {
      path: 'services',
      loadChildren: () => import('./components/pages/services/services.module').then(module => module.ServicesModule)
    },
    {
      path: 'career-details',
      loadChildren: () => import('./components/pages/career-details/career-details.module').then(module => module.CareerDetailsModule)
    },
    {
      path: 'project',
      loadChildren: () => import('./components/pages/project/project.module').then(module => module.ProjectModule)
    },
    {
      path: 'contact-team',
      loadChildren: () => import('./components/pages/contact-team/contact-team.module').then(module => module.ContactTeamModule)
    },

    { path: 'about-us', component: AboutUsComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'projects', component: ProjectsComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
