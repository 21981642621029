import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ContactRequestView } from 'src/app/request-view/contact-request-view';
import { ContactServiceService } from 'src/app/service/contact-service.service';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  isSubmit: boolean;

  fullNam: string;
  eMail: string;
  mobileNumber: number;
  subjects: string;
  msg: string;
  optionalUpdate: any;
  consent: any;

  public contactRequestView = new ContactRequestView();

  eventsArrayLength: boolean = false;

  eventsDatabase: any[] = [];
  eventsArray: any[] = [];
  temp: any[] = [];

  origiImg: any;

  imagePaths = [];
  p: number = 1;

  constructor(
    private titleService: Title,
    private eventService: EventService,
    private router: Router,
    private contactService: ContactServiceService) {
    // this.titleService.setTitle('Kilian - Angular 10 Contact Us Page');
  }

  ngOnInit() {
    this.toTop();

    this.eventService.getAllEvents().subscribe((Response) => {
      console.log(Response);
      this.eventsDatabase = Response.data;
      this.eventsArray = Response.data;
      if (this.eventsArray.length >= 5) {
        this.eventsArrayLength = true;
      }
      Response.data.forEach(element => {
        this.downloadImage(element.imagePath, element.id);
      });

    }, (Error) => {
      console.log(Error)
    })

    console.log(this.eventsArray);
  }

  toTop() {
    document.getElementById("target").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  downloadImage(photoPath, id) {
    console.log(photoPath);
    if (photoPath != null) {
      let imagePath = photoPath.split("eventsDetail/image/");
      this.eventService.getImage(imagePath[1]).subscribe((Response) => {
        this.createImageFromBlob(Response, id);
      }, (Error) => {
        console.log(Error);
      })
    } else {
      this.imagePaths.push("");
      this.imagePaths[id] = '';
    }
  }

  createImageFromBlob(image: Blob, id: number) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.origiImg = reader.result;
      this.imagePaths.push(this.origiImg);
      this.imagePaths[id] = this.origiImg;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  contactApply(contactForm: NgForm) {
    console.log(contactForm.value);

    if (contactForm.valid) {
      if (contactForm.value.consentCheckbox == undefined || contactForm.value.consentCheckbox == false) {
        console.log("Select Consent first");
        alert("Select Consent first");
        return;
      }

      this.contactRequestView.contactNumber = contactForm.value.contactNumber;
      this.contactRequestView.email = contactForm.value.email;
      this.contactRequestView.fullName = contactForm.value.fullName;
      this.contactRequestView.message = contactForm.value.message;
      this.contactRequestView.topic = contactForm.value.topic;
      console.log(this.contactRequestView);

      this.contactService.saveContact(this.contactRequestView).subscribe((Response) => {
        console.log(Response);
        alert("Saved Successfully");

        contactForm.resetForm();
      }, (Error) => {
        console.log(Error);
        alert("Please Try Again Later.");
      })
    } else {
      alert("Please Filled Required Fields.");
      return;
    }
  }
}
