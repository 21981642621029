import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLConfig } from '../url-config';

@Injectable({
  providedIn: 'root'
})
export class JobServiceService {
  
  constructor(
    private httpClient: HttpClient,
    private urlConfig: URLConfig
  ) { }

  public getAllJobs(): Observable<any> {
    return this.httpClient.get(this.urlConfig.getAllJobs);
  }

  public getJobById(jobId:number): Observable<any> {
    return this.httpClient.get(this.urlConfig.getJobById + jobId);
  }
}
