<section id="target" class="image-section">
    
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    <div class="image-container" style="margin-bottom: 5%;">
        <div class="container">
            <div class="row" style="margin-right: 0;">
                <div class="col-lg-12">
                    <h2>Projects</h2>
                </div>
            </div>
        </div>

        <!-- Page One -->
        <div *ngIf="isFirstProjectPage" class="container">
            <div class=" event-container-main">
                <div class="col-lg-6 col-md-6 event-image-main">
                    <div class="image">
                        <img src="{{projectsDB[0].image}}" alt="Avatar" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 event-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1>
                               {{projectsDB[0].title}}
                            </h1>
                            <h6 class="event-location">{{projectsDB[0].date}}</h6>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p class="project-description">
                                {{projectsDB[0].description}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a class="btn btn-primary btn-gradient" (click)="sendRouteObject(0)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[1].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[1].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[1].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(1)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[2].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[2].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[2].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(2)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[3].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[3].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[3].date}}</h6>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(3)">Read More</a>
                        </div>
                    </div> 
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[4].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[4].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[4].date}}</h6>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(4)">Read More</a>
                        </div>
                    </div> 
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[5].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[5].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[5].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(5)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[6].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[6].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[6].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(6)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 10%;">
                <div style="text-align: center;">
                    <!-- <ul class="work-list">
                        <div style="display: inline-block">
                            <li class="active">
                                <span class="utl-num" (click)="changePage(2)">02</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(3)">03</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(4)">04</span>
                            </li>
                        </div>
                    </ul> -->
                </div>
            </div>
        </div>

        <!-- Page Two -->
        <!-- <div *ngIf="isSecondProjectPage" class="container">
            <div class=" event-container-main" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                <div class="col-lg-6 col-md-6 event-image-main">
                    <div class="image">
                        <img src="{{projectsDB[0].image}}" alt="Avatar" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 event-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1>
                                {{projectsDB[8].title}}
                            </h1>
                            <h6 class="event-location">{{projectsDB[8].date}}</h6>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p>
                                {{projectsDB[8].description}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(7)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[9].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                           {{projectsDB[9].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[9].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(8)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[10].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                           {{projectsDB[10].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[10].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(9)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[11].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                           {{projectsDB[11].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[11].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(10)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[12].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                           {{projectsDB[12].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[12].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(11)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[13].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                           {{projectsDB[13].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[13].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(12)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[14].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                           {{projectsDB[14].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[14].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(13)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 10%;">
                <div style="text-align: center;">
                    <ul class="work-list">
                        <div style="display: inline-block">
                            <li class="active">
                                <span class="utl-num" (click)="changePage(1)">01</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(3)">03</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(4)">04</span>
                            </li>
                        </div>

                    </ul>
                </div>
            </div>
        </div> -->

        <!-- Page Three -->
        <!-- <div *ngIf="isThirdProjectPage" class="container">
            <div class=" event-container-main" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                <div class="col-lg-6 col-md-6 event-image-main">
                    <div class="image">
                        <img src="{{projectsDB[15].image}}" alt="Avatar" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 event-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1>
                                {{projectsDB[15].title}}
                            </h1>
                            <h6 class="event-location">{{projectsDB[15].date}}</h6>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p>
                                {{projectsDB[15].description}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(14)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[16].title}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[16].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[16].title}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(15)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[17].title}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[17].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[17].title}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(16)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[18].title}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[18].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[18].title}}</h6>
                    </div> <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(17)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[19].title}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[19].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[19].title}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(18)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[20].title}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[20].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[20].title}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(19)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[21].title}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[21].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[21].title}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(20)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 10%;">
                <div style="text-align: center;">
                    <ul class="work-list">
                        <div style="display: inline-block">
                            <li class="active">
                                <span class="utl-num" (click)="changePage(2)">02</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(4)">04</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(5)">05</span>
                            </li>
                        </div>

                    </ul>
                </div>
            </div>
        </div> -->

        <!-- Page Four -->
        <!-- <div *ngIf="isFourthProjectPage" class="container">
            <div class=" event-container-main" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                <div class="col-lg-6 col-md-6 event-image-main">
                    <div class="image">
                        <img src="{{projectsDB[22].image}}" alt="Avatar" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 event-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1>
                                {{projectsDB[22].title}}
                            </h1>
                            <h6 class="event-location">{{projectsDB[22].date}}</h6>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p>
                                {{projectsDB[22].description}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(21)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[23].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[23].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[23].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(22)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[24].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[24].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[4].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(23)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[25].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[25].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[25].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(24)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[26].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[26].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[26].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(25)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[27].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[27].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[27].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(26)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[28].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[28].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[28].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(27)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 10%;">
                <div style="text-align: center;">
                    <ul class="work-list">
                        <div style="display: inline-block">
                            <li class="active">
                                <span class="utl-num" (click)="changePage(3)">03</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(5)">05</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(6)">06</span>
                            </li>
                        </div>

                    </ul>
                </div>
            </div>
        </div> -->
        
        <!-- Page Fifth -->
        <!-- <div *ngIf="isFifthProjectPage" class="container">
            <div class=" event-container-main" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                <div class="col-lg-6 col-md-6 event-image-main">
                    <div class="image">
                        <img src="{{projectsDB[29].title}}" alt="Avatar" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 event-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1>
                                {{projectsDB[29].title}}
                            </h1>
                            <h6 class="event-location">{{projectsDB[29].date}}</h6>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p>
                                {{projectsDB[29].description}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(28)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[30].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[30].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[30].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(29)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[31].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[31].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[31].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(30)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[32].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[32].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[32].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(31)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[33].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[33].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[33].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(32)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[34].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[34].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[34].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(33)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[35].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[35].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[35].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(34)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 10%;">
                <div style="text-align: center;">
                    <ul class="work-list">
                        <div style="display: inline-block">
                            <li class="active">
                                <span class="utl-num" (click)="changePage(4)">04</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(6)">06</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(7)">07</span>
                            </li>
                        </div>

                    </ul>
                </div>
            </div>
        </div> -->

        <!-- Page Sixth -->
        <!-- <div *ngIf="isSixthProjectPage" class="container">
            <div class=" event-container-main" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                <div class="col-lg-6 col-md-6 event-image-main">
                    <div class="image">
                        <img src="{{projectsDB[36].title}}" alt="Avatar" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 event-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1>
                                {{projectsDB[36].title}}
                            </h1>
                            <h6 class="event-location">{{projectsDB[36].date}}</h6>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p>
                                {{projectsDB[36].description}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(35)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[37].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[37].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[37].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(36)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[38].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[38].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[38].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(37)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[39].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[39].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[39].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(38)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[40].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[40].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[40].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(39)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[41].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[41].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[41].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(40)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[42].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[42].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[42].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(41)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 10%;">
                <div style="text-align: center;">
                    <ul class="work-list">
                        <div style="display: inline-block">
                            <li class="active">
                                <span class="utl-num" (click)="changePage(5)">05</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(7)">07</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(8)">08</span>
                            </li>
                        </div>

                    </ul>
                </div>
            </div>
        </div> -->

        <!-- Page Seventh -->
        <!-- <div *ngIf="isSeventhProjectPage" class="container">
            <div class=" event-container-main" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                <div class="col-lg-6 col-md-6 event-image-main">
                    <div class="image">
                        <img src="{{projectsDB[43].title}}" alt="Avatar" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 event-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1>
                                {{projectsDB[43].title}}
                            </h1>
                            <h6 class="event-location">{{projectsDB[43].date}}</h6>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p>
                                {{projectsDB[43].description}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(42)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[44].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[44].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[44].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(43)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[45].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[45].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[45].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(44)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[46].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[46].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[46].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(45)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[47].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[47].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[47].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(46)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[48].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[48].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[48].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(47)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[49].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[49].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[49].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(48)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 10%;">
                <div style="text-align: center;">
                    <ul class="work-list">
                        <div style="display: inline-block">
                            <li class="active">
                                <span class="utl-num" (click)="changePage(6)">06</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(8)">08</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(9)">09</span>
                            </li>
                        </div>

                    </ul>
                </div>
            </div>
        </div> -->

        <!-- Page Eigth -->
        <!-- <div *ngIf="isEighthProjectPage" class="container">
            <div class=" event-container-main" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                <div class="col-lg-6 col-md-6 event-image-main">
                    <div class="image">
                        <img src="{{projectsDB[50].title}}" alt="Avatar" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 event-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1>
                                {{projectsDB[50].title}}
                            </h1>
                            <h6 class="event-location">{{projectsDB[50].date}}</h6>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p>
                                {{projectsDB[50].description}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(49)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[51].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[51].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[51].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(50)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[52].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[52].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[52].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(51)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[53].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[53].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[53].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(52)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[54].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[54].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[54].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(53)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[55].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[55].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[55].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(54)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[56].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[56].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[56].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(55)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 10%;">
                <div style="text-align: center;">
                    <ul class="work-list">
                        <div style="display: inline-block">
                            <li class="active">
                                <span class="utl-num" (click)="changePage(7)">07</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(9)">09</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(10)">10</span>
                            </li>
                        </div>

                    </ul>
                </div>
            </div>
        </div> -->
        
        <!-- Page Nineth -->
        <!-- <div *ngIf="isNinethProjectPage" class="container">
            <div class=" event-container-main" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                <div class="col-lg-6 col-md-6 event-image-main">
                    <div class="image">
                        <img src="{{projectsDB[57].title}}" alt="Avatar" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 event-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1>
                                {{projectsDB[57].title}}
                            </h1>
                            <h6 class="event-location">{{projectsDB[57].date}}</h6>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p>
                                {{projectsDB[57].description}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(56)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[58].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[58].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[58].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(57)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[59].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[59].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[59].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(58)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[60].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[60].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[60].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(59)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[61].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[61].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[61].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(60)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[62].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[62].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[62].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(61)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[63].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[63].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[63].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(62)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 10%;">
                <div style="text-align: center;">
                    <ul class="work-list">
                        <div style="display: inline-block">
                            <li class="active">
                                <span class="utl-num" (click)="changePage(7)">07</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(8)">07</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(10)">10</span>
                            </li>
                        </div>

                    </ul>
                </div>
            </div>
        </div> -->
        
        <!-- Page Tenth -->
        <!-- <div *ngIf="isTenthProjectPage" class="container">
            <div class=" event-container-main" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                <div class="col-lg-6 col-md-6 event-image-main">
                    <div class="image">
                        <img src="{{projectsDB[64].title}}" alt="Avatar" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 event-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1>
                                {{projectsDB[64].title}}
                            </h1>
                            <h6 class="event-location">{{projectsDB[64].date}}</h6>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <p>
                                {{projectsDB[64].description}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(63)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[65].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[65].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[65].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(64)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[66].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[66].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[66].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(65)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[67].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[67].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[67].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(66)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[68].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[68].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[68].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(67)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="event-container-sub">
                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[69].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[69].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[69].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(68)">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="event-image-sub" style="background: #191f2b; box-shadow: 10px 16px 13px 0 rgba(41, 183, 255, 0.35);">
                    <div class="image">
                        <img src="{{projectsDB[70].image}}" alt="Avatar" >
                    </div>
                    <div class="event-content">
                        <h1>
                            {{projectsDB[70].title}}
                        </h1>
                        <h6 class="event-location">{{projectsDB[70].date}}</h6>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="padding: 20px 0;">
                            <a style="margin-left: 5%;" class="btn btn-primary btn-gradient" (click)="sendRouteObject(69)">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 10%;">
                <div style="text-align: center;">
                    <ul class="work-list">
                        <div style="display: inline-block">
                            <li class="active">
                                <span class="utl-num" (click)="changePage(7)">07</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(8)">08</span>
                            </li>
                            <li class="active">
                                <span class="utl-num" (click)="changePage(9)">09</span>
                            </li>
                        </div>

                    </ul>
                </div>
            </div>
        </div> -->
    </div>
    
</section>

<footer style="width: 100%; background-color: #000000;">
    <div style="text-align: center; padding: 100px 0;">
        <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
            BEYOND THE HORIZON
        </h5>
    </div>
</footer>
