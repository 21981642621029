import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class URLConfig {

    
    // public readonly baseUrl = "http://192.168.1.76:7878/tsc-web-api/";
    public readonly baseUrl = "https://admin.tsctech.in/tsc-web-api/";
    
    //Album
    public readonly getAllAlbum = this.baseUrl + "album/all";

    public readonly getAlbumById = this.baseUrl + "album/byAlbumId?albumId=";

    public readonly getImage = this.baseUrl + "album/image/"

    //AlbumImage
    public readonly getAllAlbumImages = this.baseUrl + "albumImage/allalbumImage/all";

    public readonly getImagesByAlbumId = this.baseUrl + "albumImage/byAlbumId?albumId=";
    
    public readonly getByAlbumImageId = this.baseUrl + "albumImage/byAlbumImgaeId?albumImageId=";

    public readonly getAlbumImage = this.baseUrl + "albumImage/image/";

    //Career
    public readonly saveCareer = this.baseUrl + "career/saveCareer";

    //Contact
    public readonly saveContact = this.baseUrl + "contact/save";

    //Event-Details
    public readonly getAllEvents = this.baseUrl + "events/all";

    public readonly getEventById = this.baseUrl + "events/byEventId?eventId=";

    public readonly getEventImage = this.baseUrl + "events/image/";

    //Event-Schedule
    public readonly getAllEventSchedule = this.baseUrl + "eventSchedule/all";
    public readonly getEventScheduleByEventId = this.baseUrl + "eventSchedule/byEventId?eventsDetailId="
    public readonly getEventScheduleById = this.baseUrl + "eventSchedule/by?id=";

    //Event-Registration
    public readonly saveEventRegistration = this.baseUrl + "eventRegistration/save";

    //Event-Lecturer
    public readonly getAllEventLecturer = this.baseUrl + "eventLecturer/all";
    public readonly getEventlecturerByEventId = this.baseUrl + "eventLecturer/byEventId?eventsDetailId=";
    public readonly getEventLecturerById = this.baseUrl + "eventLecturer/by?id=";
    public readonly getEventLecturerImage = this.baseUrl + "eventLecturer/image/";

    //Job
    public readonly getAllJobs = this.baseUrl + "job/all";

    public readonly getJobById = this.baseUrl + "job/byJobId?jobId=";

    //News
    public readonly getAllNews = this.baseUrl + "news/all";

    public readonly getNewsById = this.baseUrl + "news/byNewsId?newsId=";

    public readonly getNewsImage = this.baseUrl + "news/image/";

    //Product-Variant
    public readonly saveProductVariant = this.baseUrl + "productVariant/save";

    //Publications
    public readonly getAllPublications = this.baseUrl + "publication/all";

    public readonly getPublicationsByCategoryId = this.baseUrl + "publication/byLkpCategoryId?categoryId=";

    public readonly getPublicationsByPublicationId = this.baseUrl + "publication/byPublicationId?publicationId=";

    public readonly getPublicationImage = this.baseUrl + "publication/image/";
    
    //lkpCategories
    public readonly getAllLkpCategories = this.baseUrl + "lkpCategory/all";

    
    //subscriptions
    public readonly saveSubscriptions = this.baseUrl + "subscriptions";
}