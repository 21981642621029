<!-- Section 1 Start -->
<section id="1"  class="overview-section ptb-100" style="margin-right: 0;">
    <div class="rn-gradient-circle"></div>
    <div class="rn-gradient-circle theme-pink"></div>
    
    <section class="pdfs">
        <div id="showDownloads" class="row mb-5 download_publication_toggle_button" style="">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 mb-4" >
                <div style="float: right;">
                    <a class="nav-link" style="margin-right: 10px;"
                        class="btn btn-primary btn-gradient service-more-desktop-btn" (click)="toDownloads()">Downlads</a>
                    <a class="nav-link"
                        class="btn btn-primary btn-gradient service-more-desktop-btn" (click)="toPublications()">Publications</a>                                            
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h2 style="font-weight: 700; font-size: 25px; color: #018FF4;">
                        Quick Downloads
                    </h2>
                </div>
            </div>
            <section class="card-list">
                <article class="card">
                    <header class="card-header">
                        <h2>TSC Evolution</h2>
                        <p class="mt-3">Evolution of TSC Tech Labs from 2018-Present</p>
                        <a href="assets/book/TSC-Evolution.pdf" target="_blank">Download Now</a>
                    </header>
                </article>
                <article class="card">
                    <header class="card-header">
                        <h2>Compendium of Small Satellites</h2>
                        <p class="mt-3">Published by World Federation of Engineering Organizations-Committee on Information and Communication (WFEO-CIC)</p>
                        <a href="assets/book/" target="_blank">Download Now</a>
                    </header>
                </article>
                <article class="card">
                    <header class="card-header">
                        <h2>TIP - Volume 8</h2>
                        <p class="mt-3">75 Students' Satellite Mission: 2022 ; Satellites & Space for Everyone</p>
                        <a href="assets/book/TIP2021-Volume8.pdf" target="_blank">Download Now</a>
                    </header>
                </article>
                <article class="card">
                    <header class="card-header">
                        <h2>TIP - Volume 9</h2>
                        <p class="mt-3">Engineering for NewSpace: Era of Small Satellites</p>
                        <a href="assets/book/TIP2022-Volume9.pdf" target="_blank">Download Now</a>
                    </header>
                </article>
            </section>

            <section class="card-list mt-4">
                <article class="card">
                    <header class="card-header">
                        <h2>TIP - Volume 8</h2>
                        <a href="assets/book/sample.pdf" target="_blank">Download Now</a>
                    </header>
                </article>
                <article class="card">
                    <header class="card-header">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">Download Now</a>
                    </header>
                </article>
                <article class="card">
                    <header class="card-header">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">Download Now</a>
                    </header>
                </article>
            </section>

                <div class="row xs-container">
                    <div class="col-12 xs-card">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">/book/sample.pdf</a>
                    </div>
                    <div class="col-12 xs-card">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">/book/sample.pdf</a>
                    </div>
                    <div class="col-12 xs-card">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">/book/sample.pdf</a>
                    </div>
                    <div class="col-12 xs-card">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">/book/sample.pdf</a>
                    </div>
                    <div class="col-12 xs-card">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">/book/sample.pdf</a>
                    </div>
                    <div class="col-12 xs-card">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">/book/sample.pdf</a>
                    </div>
                    <div class="col-12 xs-card">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">/book/sample.pdf</a>
                    </div>
                    <div class="col-12 xs-card">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">/book/sample.pdf</a>
                    </div>
                    <div class="col-12 xs-card">
                        <h2>Committee for Space Development (CSPD, Serbia)</h2>
                        <a href="assets/book/sample.pdf" target="_blank">/book/sample.pdf</a>
                    </div>

                </div>
            <div style="margin-top: 2%" class="bar"></div>

        </div>
        
    </section>


    <section id="showPublications" class="section_body">
        
        <!-- <div class="rn-gradient-circle"></div>
        <div class="rn-gradient-circle theme-pink"></div> -->
        <div class="container" style="padding: 30px 10px;">
            <div class="header">
                <h2>Publications</h2>
                <a style="float: right; color: #e5e5e5;" 
                    class="btn btn-primary btn-gradient" (click)="openModal()" data-toggle="modal"
                    data-target="#myModal">View All</a>
            </div>

            <div class="frame_width" style="overflow:auto;-webkit-overflow-scrolling:touch">
                <iframe src="https://pubhtml5.com/bookcase/rfbs">
    
                </iframe>
            </div>
        </div>
    </section>
    <!-- Section 1 End -->

    <section *ngIf="showCategoryOne" class="project-area ptb-100" style="padding-top: 70px;">

        
        <div class="container" style="margin-bottom: 40px;">

            <div class="section-title" style="margin-bottom: 20px;">
                <h2>Category One</h2>
            </div>

            <div class="col-sm-12 mt-2" style="padding-left: 5px;">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let image of categoryOnePublications[0].publicationList; index as i">
                        <div class="single-album">
                            <div class="album-image">
                                <img [src]="imagePaths[image.id]" width="100%"><br>
                                <a href="{{image.link}}"> {{image.link}}</a>
                            </div>
                        </div>
                        <!-- <div class="news-div">
                            <div class="post-image">
                                <img [src]="imagePaths[image.id]">
                                <div class="post-image-link">
                                    <p>Hello</p>
                                </div>    
                            </div>
                        </div> -->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <div style="margin-top: 2%" class="bar"></div>
        </div>

        <div *ngIf="showCategoryTwo" class="container" style="margin-bottom: 40px;">
            <div class="section-title" style="margin-bottom: 20px;">
                <h2 style="font-size: 23px;">Category Two</h2>
            </div>

            <div class="col-sm-12 mt-2" style="padding-left: 5px;">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide  *ngFor="let imageTwo of categoryTwoPublications[0].publicationList; index as i">
                        <div class="single-album">
                            <div class="album-image">
                                <img [src]="imagePathsTwo[imageTwo.id]" width="100%"><br>
                                <a href="{{imageTwo.link}}"> {{imageTwo.link}}</a>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!-- <div class="publication-slides owl-carousel owl-theme">
                <div class="single-project" *ngFor="let imageTwo of categoryTwoPublications[0].publicationList; index as i">
                    <div class="project-image">
                        <img [src]="imagePathsTwo[imageTwo.id]" width="100%" height="100%">
                        <a href="assets/img/work-img1.jpg" class="popup-btn"></a>
                    </div>
                </div>
            </div> -->
            <div style="margin-top: 2%" class="bar"></div>
        </div>

        <div *ngIf="showCategoryThree" class="container" style="margin-bottom: 40px;">
            <div class="section-title" style="margin-bottom: 20px;">
                <h2 style="font-size: 23px;">Category Three</h2>
            </div>

            <div class="publication-slides owl-carousel owl-theme">
                <div class="col-sm-12 mt-2" style="padding-left: 5px;">
                    <owl-carousel-o [options]="customOptions">
                        <ng-template carouselSlide  *ngFor="let imageThree of categoryThreePublications[0].publicationList; index as i">
                            <div class="single-album">
                                <div class="album-image">
                                    <img [src]="imagePathsThree[imageThree.id]" width="100%"><br>
                                    <a href="{{imageThree.link}}"> {{imageThree.link}}</a>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>

                <!-- <div class="single-project" *ngFor="let imageThree of categoryThreePublications; index as i">
                    <div class="project-image">
                        <img [src]="imagePathsThree[imageThree.id]" width="100%" height="100%">
                        <a href="{{imagePathsThree.link}}"> {{imagePathsThree.link}}</a>
                    </div>
                </div> -->
            </div>
            <div style="margin-top: 2%" class="bar"></div>
        </div>

        <footer style="width: 100%; margin-top: 150px;">
            <div style="text-align: center; padding: 50px 0;">
                <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
                    BEYOND THE HORIZON
                </h5>
            </div>
        </footer>
    </section>

</section>
<div id="myModal" class="modal publications-container">

    <!-- Modal content -->
    <div class="modal-contents" style="width: auto;">
        <div style="text-align: center;">
            <span class="close" (click)="closeModal()">&times;</span>
            <h3 style="color: #018FF4; font-weight: 700;">All Publications</h3>
        </div>
        <div class="frame_width" style="overflow:auto;-webkit-overflow-scrolling:touch">
            <iframe src="https://pubhtml5.com/bookcase/rfbs">

            </iframe>
        </div>
    </div>

</div>