
<section id="target">
    <div class="startup-home">
        <div class="main-banner startup-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row" style="padding-right: 10px;">
                            <div class="col-lg-12 col-md-12">
                                <div class="hero-content">
                                    <p class="home-screen-message">CONNECT AND PROGRESS THROUGH SPACE TECHNOLOGY</p>
                                    <p class="move-with-h1">TSC provides turnkey project support with respect to
                                        nanosatellites globally to meet the needs and demands of our clients. <br> Delivered
                                        through a single engagement, multi-source model, our Team works closely in liaison
                                        with our clients to provide <br> excellent service that significantly reduces costs
                                    </p>
                                    <div class="copyright-area" style="border-top: 0; margin-top: 50px; width: 100%;">
                                        <div class="container">
                                            <div class="row h-100">
                                                <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                                                    <ul>
                                                        <li class="move-li-3" style="border: 0; cursor: pointer;"><a
                                                                href="https://play.google.com/store/apps/details?id=com.tsctech.satnav" target="_blank"><img
                                                                    src="assets/img/logos/SatNav.png"
                                                                    class="copyright-area-li-img" alt="SatNav"></a></li>
                                                        <li class="move-li-2" style="border: 0; cursor: pointer;"><a
                                                            href="https://edunav.tsctech.in" target="_blank"><img
                                                                src="assets/img/logos/EduNav.png"
                                                                class="copyright-area-li-img" alt="EduNav"></a></li>
                                                        <!-- <li class="move-li-1" style="border: 0; cursor: pointer;"><img
                                                                src="assets/img/logos/EduNav.png"
                                                                class="copyright-area-li-img" alt="EduNav"></li> -->
                                                    </ul>
                                                </div>
                                                <div class="col-lg-4 col-md-12 col-sm-12 col-12"
                                                    style="text-align: right; margin-top: 10%;">
                                                    <!-- <iframe style="border-radius:150%; border: 1px solid #018FF4;" width="200" height="200" src="https://www.youtube.com/embed/dwLHaS6-tUo"></iframe> -->
                                                    <a href="https://www.youtube.com/watch?v=dwLHaS6-tUo"
                                                        class="video-btn popup-youtube"> Watch Video <i
                                                            style="border-radius: 100%;" class="icofont-play-alt-3"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: 2%" class="bar"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- End Startup Main Banner -->


<!-- Start News Area -->
<section class="news-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>LATEST HEADLINES</h2>
        </div>

        <div class="col-sm-12 mt-2" style="padding-left: 5px;">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let news of newsContent | slice:0:8">
                    <div class="news-div">
                        <div class="post-image" style="background-color: #018FF4; height: 316px;">
                            <img [src]="imagePaths[news.id]" alt="image">
                        </div>
                        <div class="news-post-content" style="padding: 15px 10px;">
                            <h5 style="padding-left:40px; color: #e5e5e5;">{{news.title}}
                            </h5>
                            <p class="news-date">{{news.createdDate | date: 'dd/MM/yyyy'}}</p>
                            <p class="news-content">
                                {{news.shortDescription | slice:0:98}}</p>
                            <div class="blog-btn">
                                <a style="cursor: pointer;" (click)="goToNewsDetail(news.id)">
                                    <i class="icofont-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>




        <!-- <div class="news-slides owl-carousel owl-theme mt-5">
            <div class="single-news-post">
                <div class="post-image " style="background-color: #018FF4; height: 316px;">
                    <img src="assets/img/logos/body-bg-6.jpg" alt="blog-image">
                </div> -->
        <!-- <a routerLink="/blog-details" class="post-image"><img src="assets/img/logos/body-bg-6.jpg"
                        alt="blog-image"></a> -->
        <!-- <div class="news-post-content" style="padding: 15px 10px;">
                    <h5 style="padding-left:40px; color: #e5e5e5;"><a routerLink="/blog-details"
                            style=" color: #e5e5e5;">The Most Popular New Business Apps</a></h5>
                    <p class="news-date"> Aug 6, 2021</p>
                    <div class="blog-btn">
                        <a routerLink="/blog-details">
                            <i class="icofont-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="single-news-post">
                <div class="post-image " style="background-color: #018FF4; height: 316px;">
                    <img src="assets/img/extras/body-bg-6.jpg" alt="blog-image">
                </div>
                <div class="news-post-content" style="padding: 15px 10px;">
                    <h5 style="padding-left:40px; color: #e5e5e5;"><a routerLink="/news"
                            style=" color: #e5e5e5;">The Most Popular New Business Apps</a></h5>
                    <p class="news-date"> Aug 6, 2021</p>
                    <p class="news-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <div class="blog-btn">
                        <a routerLink="/news">
                            <i class="icofont-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="single-news-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/news.png"
                        alt="blog-image"></a>

                <div class="news-post-content" style="padding: 15px 10px;">
                    <h5 style="padding-left:40px; color: #e5e5e5;"><a routerLink="/blog-details"
                            style=" color: #e5e5e5;">The Most Popular New Business Apps</a></h5>
                    <p class="news-date"> Aug 6, 2021</p>
                    <div class="blog-btn">
                        <a>
                            <i class="icofont-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="single-news-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/news.png"
                        alt="blog-image"></a>

                <div class="news-post-content" style="padding: 15px 10px;">
                    <h5 style="padding-left:40px; color: #e5e5e5;"><a routerLink="/blog-details"
                            style=" color: #e5e5e5;">The Most Popular New Business Apps</a></h5>
                    <p class="news-date"> Aug 6, 2021</p>
                    <div class="blog-btn">
                        <a>
                            <i class="icofont-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="single-news-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/news.png"
                        alt="blog-image"></a>

                <div class="news-post-content" style="padding: 15px 10px;">
                    <h5 style="padding-left:40px; color: #e5e5e5;"><a routerLink="/blog-details"
                            style=" color: #e5e5e5;">The Most Popular New Business Apps</a></h5>
                    <p class="news-date"> Aug 6, 2021</p>
                    <div class="blog-btn">
                        <a>
                            <i class="icofont-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div> -->
        <div style="margin-top: 100px;" class="bar"></div>
    </div>
</section>
<!-- End News Area -->


<!-- TSC Tech Labs in numbers start -->
<section style="background-color: #000;">
    <div class="tsc-in-numbers">
        <div class="container">
            <div class="row h-100">
                <div class="col-lg-12 col-md-12 text-center">
                    <p class="hiring_message">TSC Tech Labs in numbers</p>
                </div>
            </div>
            <div class="tsc-grid">
                <div class="tsc-grid-item">
                    <p class="p-font-one" style="font-size:26px;"> 3</p>
                    <p class="p-font-two" style="font-size:16px;">
                        Satellites Launched
                    </p>
                </div>
                <div class="tsc-grid-item">
                    <p class="p-font-one" style="font-size:26px;">35 +</p>
                    <p class="p-font-two" style="font-size:16px;">
                        Patents Filed
                    </p>
                </div>
                <div class="tsc-grid-item">
                    <p class="p-font-one" style="font-size:26px;">2000 +</p>
                    <p  class="p-font-two" style="font-size:16px;">
                        Students Trained
                    </p>
                </div>
                <div class="tsc-grid-item">
                    <p class="p-font-one"  style="font-size:26px;">30 +</p>
                    <p  class="p-font-two" style="font-size:16px;">
                        Products
                    </p>
                </div>
                <div class="tsc-grid-item">
                    <p class="p-font-one" style="font-size:26px;">40 +</p>
                    <p class="p-font-two" style="font-size:16px;">
                        International Partners
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- TSC Tech Labs in numbers end -->

<!-- Section mission-details -->
<section class="mission-details">
    <div class="container">

        <div class="row mt-5 mission-book mb-5">
            <h2>
                We’ll Take Care Of The Everything
            </h2>
            <p>
                ALL COVERED BY TSC'S END TO END SERVICE
            </p>
            <div class="row" style="margin-right: 0;">
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Complete Satellite Integration

                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="46" viewBox="0 0 38 46"><defs><style>.integration-a,.integration-b{fill:none;stroke:#29b7ff;stroke-miterlimit:10;stroke-width:2px}.integration-a{stroke-linecap:square}</style></defs><g transform="translate(3 11)"><g transform="translate(-4 -4)"><path class="integration-a" d="M31.428,25v6.427H25" transform="translate(6.572 6.572)"></path><line class="integration-a" x1="8" transform="translate(16 38)"></line><path class="integration-a" d="M8.43,31.428H2V25" transform="translate(0 6.572)"></path><path class="integration-a" d="M2,8.43V2"></path><path class="integration-a" d="M30,2V8.43" transform="translate(8)"></path><line class="integration-a" y1="8" transform="translate(38 16)"></line><line class="integration-a" y1="8" transform="translate(2 16)"></line></g><g transform="translate(0 36)"><line class="integration-b" y2="10" transform="translate(16 -19)"></line><path class="integration-a" d="M17.454,18l-3.727,3.727L10,18" transform="translate(2.273 -30.201)"></path></g><path class="integration-a" d="M30,8V2H2V30H30V24" transform="translate(32 -12) rotate(90)"></path></g></svg>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Space Qualification & Testing
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.2" height="46" viewBox="0 0 37.2 46"><defs><style>.space-q-a{fill:none;stroke:#29b7ff;stroke-linecap:square;stroke-miterlimit:10;stroke-width:2px}</style></defs><g transform="translate(-3)"><path class="space-q-a" d="M23.2,5.4a4.4,4.4,0,1,0-8.8,0H10v5.867H27.6V5.4Z" transform="translate(2.8)"></path><path class="space-q-a" d="M34.8,5h4.4V43.133H4V5H8.4" transform="translate(0 1.867)"></path><line class="space-q-a" x2="6" transform="translate(24.6 21)"></line><path class="space-q-a" d="M9,9l1,1,3-3" transform="translate(3.6 12)"></path><line class="space-q-a" x2="6" transform="translate(24.6 28)"></line><line class="space-q-a" x2="6" transform="translate(24.6 35)"></line><path class="space-q-a" d="M9,16l1,1,3-3" transform="translate(3.6 12)"></path><path class="space-q-a" d="M9,16l1,1,3-3" transform="translate(3.6 19)"></path></g></svg>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Sat Registration & Legal Work
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="46" viewBox="0 0 44 46"><defs><style>.legal-a,.legal-b{fill:none;stroke:#29b7ff;stroke-miterlimit:10;stroke-width:2px}.legal-a{stroke-linecap:square}</style></defs><g transform="translate(-2 -1)"><path class="legal-a" d="M13,39v2c0,4.5-5,5-5,5H38a5,5,0,0,0,5-5V39Z"></path><path class="legal-a" d="M37,2H3V41a5,5,0,0,0,5,5"></path><line class="legal-a" x2="12" transform="translate(11 12)"></line><line class="legal-a" x2="12" transform="translate(11 20)"></line><line class="legal-a" x2="9" transform="translate(11 28)"></line><path class="legal-b" d="M43,20.291V33l-6-2-6,2V20.291"></path><circle class="legal-a" cx="8" cy="8" r="8" transform="translate(29 7)"></circle></g></svg>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Launch Provision & Support</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 46.225 46.227"><defs><style>.launch-a{fill:#030504}.launch-a,.launch-b,.launch-c{stroke:#29b7ff;stroke-width:2px}.launch-b{fill:none}.launch-c{fill:rgba(0,0,0,0)}</style></defs><g transform="translate(230.631 189.466)"><line class="launch-a" x2="12.177" y2="12.177" transform="translate(-213.306 -172.74)"></line><path class="launch-b" d="M-226.7-136.5a4.318,4.318,0,0,1,6.1.183,4.318,4.318,0,0,1,0,5.921c-2.156,2.161-8.807,2.706-8.807,2.706S-228.853-134.345-226.7-136.5Z" transform="translate(0 -16.774)"></path><path class="launch-b" d="M-208.05-168.9a3.312,3.312,0,0,0,0-4.683h0a3.311,3.311,0,0,0-4.683,0l-16.392,16.391,2.1,2.1" transform="translate(-0.092 -4.323)"></path><path class="launch-b" d="M-193.03-155.2a3.311,3.311,0,0,1,4.683,0h0a3.311,3.311,0,0,1,0,4.683l-16.391,16.392-2.1-2.1" transform="translate(-7.617 -10.531)"></path><path class="launch-c" d="M-210.4-152.659l26.7-26.7c1.333-1.333,1.919-5.027,2.169-7.489a1.983,1.983,0,0,0-2.174-2.174c-2.462.25-6.156.837-7.489,2.169l-26.7,26.7" transform="translate(-3.885 0.564)"></path></g></svg>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Ground Station Equipment
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45"><defs><style>.operations-a,.operations-b{fill:none;stroke:#29b7ff;stroke-miterlimit:10;stroke-width:2px}.operations-b{stroke-linecap:square}</style></defs><g transform="translate(-1003 -10738)"><path class="operations-a" d="M44.016,17.051A21.5,21.5,0,1,1,36.2,6.149" transform="translate(1002 10737)"></path><circle class="operations-b" cx="4.3" cy="4.3" r="4.3" transform="translate(1036.966 10741.867)"></circle><circle class="operations-b" cx="12.5" cy="12.5" r="12.5" transform="translate(1013 10748)"></circle></g></svg>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="book-steps">
                        <p class="point-name">
                            Post-Launch Operations
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46.227" viewBox="0 0 46 43.492"><defs><style>.downlink-a,.downlink-b{fill:none;stroke:#29b7ff;stroke-miterlimit:10;stroke-width:2px}.downlink-b{stroke-linecap:square}</style></defs><g transform="translate(-1 -5)"><line class="downlink-a" y2="23.65" transform="translate(24 23.763)"></line><path class="downlink-b" d="M22,28l7.4,7.4,7.4-7.4" transform="translate(-5.403 11.674)"></path><path class="downlink-b" d="M29.867,35.237h8.8a8.071,8.071,0,0,0-.073-16.08,14.658,14.658,0,0,0-28.6-2.778A9.456,9.456,0,0,0,2,25.735a9.565,9.565,0,0,0,8.8,9.5h7.333" transform="translate(0 0)"></path></g></svg>
                    </div>
                </div>
            </div>

        </div>
        <div class="mt-5 bar"></div>
    </div>
</section>

<!-- Start FEATURED PRODUCTS Area -->
<section class="overview-section ptb-100" style="margin-right: 0">
    <div class="container">
        <div class="section-title">
            <h2>FEATURED PRODUCTS</h2>
        </div>
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <h1>CubeSat Platforms</h1>
                    <p>Our CubeSat platform is a flight-proven, cost-effective system with a
                        short delivery time that meets the performance required for a wide range of applications.</p>
                    <ul>
                        <li>1U, 2U, 3U, 6U & 12U form factors available</li>
                        <li>Compliant with CubeSat PC 104 design specification</li>
                        <li>Reliable high-performance space data handling avionics</li>
                        <li>Modular, compatible, mechanical design</li>
                    </ul>
                    <a routerLink="/products" class="btn btn-primary btn-gradient">Read More</a>
                </div>
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/products/1U.png">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/products/TSC_Sat_Two.png">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <h1>CubeSat Sub-Systems </h1>
                    <p>High-performance CubeSat modules with flight heritage. Compliant with the CubeSat standard.
                        Software-driven approach, resulting in multiple redundancies. Extremely easy to integrate and
                        operate in space.</p>
                    <ul>
                        <li>All System Modules are PC/104 “Bus Compatible”</li>
                        <li>Flight proven CubeSat Sub Sytem Modules</li>
                        <li>Custom Design of Modules supported</li>
                        <li>Support and Integration Documentation Provided</li>
                    </ul>
                    <a routerLink="/products" class="btn btn-primary btn-gradient">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <h1>Ground Station Control Box</h1>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus
                        in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/products" class="btn btn-primary btn-gradient">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/products/GSFinal.PNG">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img style="transform: scaleX(-1);" src="assets/img/products/GSFullBody.PNG">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <h1>Ground Station System</h1>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus
                        in on anything form a single project to an individual person's workload.</p>
                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a routerLink="/products" class="btn btn-primary btn-gradient">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <h1>CanSat Platforms</h1>
                    <p>We provide fully advanced and functional simulation of a CubeSat, called CanSats. It comes with
                        all the subsystems - EPS, OBC Comms, GPS, and Ground Control Station.</p>
                    <ul>
                        <li>Major SubSystems: OBC, EPS, Comms, Payload, and GPS</li>
                        <li>Xtensa dual-core 32-bit LX6 Microprocessor</li>
                        <li>Multiple Sensors and Interfaces Provided</li>
                        <li>Proto Board Included for secondary missions.</li>
                    </ul>
                    <a routerLink="/products" class="btn btn-primary btn-gradient">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/products/cansat.png">
                </div>
            </div>
        </div>
        <div class="bar"></div>
    </div>
</section>
<!-- End FEATURED PRODUCTS Area -->

<!-- Start OUR SERVICES Area -->
<section class="saas-tools ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>OUR SERVICES</h2>
            <!-- <div class="bar"></div> -->
        </div>

        <div class="tab">
            <ul class="tabs">
                <li class="list-item-spacing"><a href="#"  style="color: #018FF4;">
                        <i class="icofont-satellite"></i>
                        <br>
                        TSC EduNav
                    </a></li>

                <li class="list-item-spacing"><a href="#" style="color: #018FF4;">
                        <i class="icofont-network-tower"></i>
                        <br>
                        Ground Station
                    </a></li>

                <!-- TEMPORARY COMMENTED OUT ,  NEED TO BE REPLACED BY NEW SERVICE NAME-->
                <!-- <li class="list-item-spacing"><a href="#">
                        <i class="icofont-electron"></i>
                        <br>
                        TSC Patent Application
                    </a></li> -->

                <li class="list-item-spacing li-item-fourth"><a href="#" style="color: #018FF4;">
                        <i class="icofont-satellite"></i>
                        <br>
                        TSC SatNav
                    </a></li>

                <li class="list-item-spacing"><a href="#" style="color: #018FF4;">
                        <i class="icofont-satellite"></i>
                        <br>
                        TSC Space Community
                    </a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mt-0">
                                <h3>TSC EduNav<a [routerLink]="['/services/tsc-edunav']" [queryParams]="{ s: '1'}" class="nav-link"
                                        class="btn-primary btn-gradient service-more-mobile-btn"><i
                                            class="icofont-swoosh-right"></i></a></h3>
                                <p style="text-align: justify;">TSC EduNav is a Learning Management Solution brought to you by TSC Tech Labs in collaboration with GeekSpace Labs. TSC EduNav brings complete space training and program course where, the Trainee will engage in Learning the Methods & Processes for Designing and Experiencing Hands on Training in Development, Assembling, and Testing of Small Satellites. This extensive course is jointly developed and brought by:</p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> TSC Technologies Pt. Ltd. Core Team,<br>
                                        <!-- <a style="margin-left: 10%; color: #018FF4;" href="https://tsctech.in/"
                                            target="_blank">https://tsctech.in/
                                        </a> -->
                                    </li>
                                    <li><i class="icofont-ui-check"></i> GeekSpace Labs, <br>
                                        <!-- <a style="margin-left: 10%; color: #018FF4;"
                                            href=" http://2comnet.info/komsat/en/" target="_blank">
                                            https://www.geekspacelabs.in/
                                        </a> -->
                                    </li>
                                    <li><i class="icofont-ui-check"></i> Committee for Space Development (CSPD, Serbia),
                                        <br>
                                        <!-- <a style="margin-left: 10%; color: #018FF4;"
                                            href=" http://2comnet.info/komsat/en/" target="_blank">
                                            http://2comnet.info/komsat/en/
                                        </a> -->
                                    </li>
                                    <li><i class="icofont-ui-check"></i> UNISEC India, <br>
                                        <!-- <a style="margin-left: 10%; color: #018FF4;"
                                            href=" http://2comnet.info/komsat/en/"
                                            target="_blank">https://www.unisec-india.in/
                                        </a> -->
                                    </li>
                                    <li><i class="icofont-ui-check"></i> Indian Technology Congress Association (ITCA,
                                        Bangalore),<br>
                                        <!-- <a style="margin-left: 10%; color: #018FF4;"
                                            href=" http://2comnet.info/komsat/en/" target="_blank">
                                            https://www.itca.org.in/
                                        </a> -->
                                    </li>
                                  
                                    <li><i class="icofont-ui-check"></i> Eminent Ex-ISRO Scientists</li>
                                    <li>
                                        <a [routerLink]="['/services/tsc-edunav']" [queryParams]="{ s: '1'}" class="nav-link"
                                            class="btn btn-primary btn-gradient service-more-desktop-btn">Read More</a>
                                    </li>
                                </ul>
                                <!-- <a style="margin-top: 20px;" routerLink="/products" class="btn btn-primary btn-gradient">Read More</a> -->
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/service/edunav.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/service/satnet.png" alt="img">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mb-0">
                                <h3>Ground Station<span><a [routerLink]="['/services/digital-ground-station']" [queryParams]="{ s: '2'}" class="nav-link"
                                            class="btn-primary btn-gradient service-more-mobile-btn"><i
                                                class="icofont-swoosh-right"></i></a></span></h3>
                                <p style="text-align: justify;">TSC Ground Station is a fully managed product that lets you control satellite communications - receive and transmit, process data, and scale your operations with our networked ground station cloud support keeping your operations running 24*7</p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> Ground Control Station Systems</li>
                                    <li><i class="icofont-ui-check"></i> Antennas - VHF, UHF, QFH</li>
                                    <li><i class="icofont-ui-check"></i> Compact and Modular Design</li>
                                    <li><i class="icofont-ui-check"></i> Hardware/Software Systems Provided
                                        <a [routerLink]="['/services/digital-ground-station']" [queryParams]="{ s: '2'}" class="nav-link"
                                            class="btn btn-primary btn-gradient service-more-desktop-btn">Read More</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- TEMPORARY COMMENTED OUT,  NEED TO BE REPLACED BY NEW SERVICE CONTETN and IMAGE-->
                <!-- <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mt-0">
                                <h3>TSC Patent Application<span><a routerLink="/products"
                                            class="btn-primary btn-gradient service-more-mobile-btn"><i
                                                class="icofont-swoosh-right"></i></a></span></h3>
                                <p style="text-align: justify;">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> Creative Design</li>
                                    <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                    <li><i class="icofont-ui-check"></i> Modern Design</li>
                                    <li><i class="icofont-ui-check"></i> Digital Marketing & Branding
                                        <a routerLink="/products"
                                            class="btn btn-primary btn-gradient service-more-desktop-btn">Read More</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/features-img1.png" alt="img">
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/service/satnav.png" alt="img">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mb-0">
                                <h3>TSC SatNav<span><a [routerLink]="['/services/tsc-satnav']" [queryParams]="{ s: '4'}" class="nav-link"
                                            class="btn-primary btn-gradient service-more-mobile-btn"><i
                                                class="icofont-swoosh-right"></i></a></span></h3>
                                <p style="text-align: justify;">Satnav is an amateur radio satellite tracker and pass predictor for Android Devices. This app provides several search methods to track satellites in real time. It allows users to search from a database of over 15,000 tracked space objects, including the International Space Station, Hubble Space Telescope, surveillance satellites, and other popular satellites. Users can search the entire Database using a satellite name or by NORAD catalog number. Track them with ease!</p>
                                <ul>
                                    <li><i class="icofont-ui-check"></i> Radius Search</li>
                                    <li><i class="icofont-ui-check"></i> Global Search</li>
                                    <li><i class="icofont-ui-check"></i> Search by Satellite</li>
                                    <li><i class="icofont-ui-check"></i> Custom Maps Search</li>
                                    <li><i class="icofont-ui-check"></i> Rotator and Altitude Control
                                        <a [routerLink]="['/services/tsc-satnav']" [queryParams]="{ s: '4'}" class="nav-link"
                                            class="btn btn-primary btn-gradient service-more-desktop-btn">Read More</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_content mt-0">
                                <h3>TSC Space Community<span><a [routerLink]="['/services/shared-satellite-service']" [queryParams]="{ s: '5'}" class="nav-link"
                                            class="btn-primary btn-gradient service-more-mobile-btn"><i
                                                class="icofont-swoosh-right"></i></a></span></h3>
                                <p style="text-align: justify;">A space to connect, network and learn with fellow space explorers. Join us in upcoming STEM activities, team-building exercises and in-person networking events to learn more about space and meet the community that is building the New Way to Space. Curiosity drives our daily work and we want to share it with you too.</p>

                                <p><br>Connect with people that love space technology and engineering as much as you do on our platform now!</p>
                                <a [routerLink]="['/services/tsc-edunav']" [queryParams]="{ s: '1'}" class="nav-link"
                                            class="btn btn-primary btn-gradient service-more-desktop-btn">Read More</a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="tabs_item_img">
                                <img src="assets/img/service/satlink.png" alt="img">
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 70px;" class="bar"></div>
    </div>
</section>
<!-- End OUR SERVICES Area -->

<!-- Start New Section OUR CLIENTs Area -->
<section class="project-area ptb-100" style="padding-left: 10px; padding-right: 10px;">
    <div class="container">
        <div class="section-title">
            <h2>OUR CLIENTS</h2>
        </div>
    </div>
    <!-- KINDLY CHECK THISSSSSS! -->
    <div style="padding: 0px 50px;">
        <div class="ourClients" >
            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '101'}"><img src="assets/img/clients/cu.png" height="300px" width="300px"></a>
            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '102'}"><img src="assets/img/clients/jit.png" height="300px" width="300px"></a>
            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '101'}"> <img src="assets/img/clients/rgi.png" height="180px" ></a>
            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '104'}"> <img src="assets/img/clients/nttf.png" height="300px" width="300px"></a>
            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '105'}">  <img src="assets/img/clients/mgr.png" height="300px" width="85%"></a>
            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '106'}">  <img src="assets/img/clients/hindustan.png" height="300px" width="85%" alt="client"></a>
            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '101'}"> <img src="assets/img/clients/anurag.png" height="300px" alt="client"></a>
            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '101'}"> <img src="assets/img/clients/clients.png" height="300px" alt="client"></a>
        </div>
    </div>
    <div style="margin-top: 70px; margin-right: 10%;" class="bar"></div>

    <!-- KINDLY CHECK THISSSSSS! -->

</section>
<!-- End New Section OUR CLIENTs Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-black">
    <div class="container">
        <div class="section-title">
            <h2>TESTIMONIALS</h2>
        </div>

        <div class="feedback-slides owl-carousel">
            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/testimonials/rmv.png" alt="client">
                    <h3 style="text-align: right;">Padma Shri. Prof. R M Vasagam</h3>
                    <p style="text-align: right; margin-top: 10px; color: #018FF4;">&nbsp;&nbsp;&nbsp;Former Project Director, India’s First Geo Stationary Comm Satellite “APPLE”, ISRO</p>
                </div>
                <p style="text-align: justify;">R&D in emerging interdisciplinary high technology areas by faculty and students is becoming the hallmark of TSC Tech Labs. The focussed efforts have led to patents, publications and expertise to serve the needs of industry and academia. The sustained efforts will make strong ties with nation and mission!</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/testimonials/ma.png" alt="client">
                    <h3 style="text-align: right;">Padma Shri. Dr. Mylswamy Annadurai</h3>
                    <p style="text-align: right; margin-top: 10px; color: #018FF4;">Former Director, ISRO Satellite Centre, Project Director, Chandrayaan1 & 2 and Mangalyaan, ISRO, Chairman</p>
                </div>
                <p style="text-align: justify;">I have been watching TSC Startup growing leap and bound over the years while
                    connecting community and keeping the trends in technology development. My best wishes to
                    the TSC Startup Team</p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/testimonials/wooday.jpg" alt="client">
                    <h3 style="text-align: right;">Dr. Wooday P Krishna</h3>
                    <p style="text-align: right; margin-top: 10px; color: #018FF4;">&nbsp;&nbsp;National President, Indian Institution of Production Engineers</p>
                </div>
                <p style="text-align: justify;">The achievements of TSC Tech, Bengaluru, are a pointer towards excellence in R&D. Be it Hackathons Int'l Internships, Satellite Project Initiatives, Start-ups, Publications, Patents and the like, the Team has carved a niche for itself among the top space startups in India.
                    </p>
            </div>

            <div class="single-feedback-box">
                <div class="client-info">
                    <img src="assets/img/testimonials/lvm.jpg" alt="client">
                    <h3 style="text-align: right;">Dr. L V Muralikrishna Reddy </h3>
                    <p style="text-align: right; margin-top: 10px; color: #018FF4;">President, ITCA and BRICS Federation of Engineering Organisations </p>
                </div>
                <p style="text-align: justify;">I am very happy to note the strident progress made by TSC Tech Labs consistently and their active role played during the evolution of 75 Students’ Satellites Programme: Mission 2022 has proved the vibrant R&D Culture! I congratulate the TSC Team and wish them best of luck for their future endeavours.</p>
            </div>
        </div>
        <div style="margin-top: 70px;" class="bar"></div>
    </div>

    <!-- svg Adds Background Image not color -->

    <!-- <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#0d1016"/>
    </svg> -->
</section>
<!-- End Feedback Area -->

<!-- Start OUR PARTNERS Area -->
<section class="feedback-area ptb-100 bg-black">
    <div class="container">
        <div class="section-title">
            <h2>OUR PARTNERS</h2>
        </div>

        <!-- WAY 1 -->
        <!-- <app-partner></app-partner>
        <app-partner></app-partner> -->

        <!-- WAY 2 -->
        <!-- Start Gallery Area -->
        <section class="gallery-area ptb-100" style="padding: 5px 5px;">
            <div class="row m-0">

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/itca.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Indian Technology Congress Association (ITCA)
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '1'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/isro.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                ISRO
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '2'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/wcrc1.png" alt="gallery">
                        <a>
                            <p style="font-size: 18px; font-weight: 700; color: #fff;">
                                World CanSat/Rocketry Consortium (WCRC)
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '3'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/nsil.png" alt="gallery">
                        <a>
                            <p style="font-size: 18px; font-weight: 700; color: #fff;">
                                NewSpace India Limited (NSIL)
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '4'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/geek.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                GeekSpace Labs
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '5'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/atl.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Ananth Technologies Limited (ATL)
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '6'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/cspd.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Committee for Space Programme Development (CSPD)
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '7'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/adt.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Alpha Design Technologies
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '8'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/nts.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                New Tech Solutions (NTS)
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '9'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/khmd.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                karnataka Hybrid Micro Devices Ltd. (KHMD)
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '10'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/ducom.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Ducom Aerospace
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '11'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/wcrc2.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                World CanSat/Rocketry Championship
                            </p>
                            <br><br>
                            <a routerLink="/partners"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/nttf.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Nettur Technical Training Foundation (NTTF)
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '12'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/vm.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Valles Marineris Int'l
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '13'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/brics.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                BRICS-FEO
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '14'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/mp.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Micropack Private Ltd.
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '15'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/spaceway.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Spaceway Limited
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '16'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/acs.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Aero Club Sremska Mitrovica
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '17'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/aus.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                Aeronautical Union of Serbia
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '18'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="single-partner">
                        <img src="assets/img/partners/rse.png" alt="gallery">
                        <a>
                            <p style="font-size: 16px; font-weight: 700;">
                                RSE, Serbia
                            </p>
                            <br><br>
                            <a [routerLink]="['/partners']" [queryParams]="{ partnerId: '19'}"
                                style="font-size: 16px; word-break: break-all; padding: 0 10px; position: absolute;">Read
                                More</a>
                        </a>
                    </div>
                </div>


            </div>
        </section>
        <!-- End Gallery Area -->

        <!-- WAY 3 -->
        <!-- <div class="container">
            <div class="divs contents">


                <div class="categories">
                    <div id="row">
                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item 2 </span>
                            </a>
                        </div>

                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item 3 </span>
                            </a>
                        </div>


                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item 4</span>
                            </a>
                        </div>
                    </div>
                    <div id="row">
                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item</span>
                            </a>
                        </div>


                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item</span>
                            </a>
                        </div>
                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item</span>
                            </a>
                        </div>
                    </div>
                    <div id="row">
                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item</span>
                            </a>
                        </div>

                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item</span>
                            </a>
                        </div>

                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item</span>
                            </a>
                        </div>
                    </div>
                    <div id="row">
                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item</span>
                            </a>
                        </div>

                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item</span>
                            </a>
                        </div>

                        <div class="categories_item">
                            <a href="" class="">
                                <span>Item</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div> -->
        <div style="margin-top: 70px;" class="bar"></div>
    </div>
</section>

<!-- Start We Are Hiring Area -->
<section style="background: #000; padding-top: 5%;">
    <div class="cta-area">

        <div class="rn-gradient-circle"></div>
        <div class="rn-gradient-circle theme-pink"></div>
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-7 col-md-7">
                    <p class="hiring_message">WE ARE HIRING</p>
                </div>
    
                <div class="col-lg-5 col-md-5 text-end">
                    <a routerLink="/careers" class="btn btn-primary">Dare and contact us immediately.</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End We Are Hiring Area -->


<footer style="width: 100%; padding-top: 50px; background-color: #000;">
    <div style="text-align: center; padding: 100px 0;">
        <h5 style="font-family: none; letter-spacing: 12px; font-size: 2em; color: #e5e5e5;">
            BEYOND THE HORIZON
        </h5>
    </div>
</footer>