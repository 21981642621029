import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  isRadiusSearch: boolean = true;
  isGlobalSearch: boolean = false;
  isSatelliteSearch: boolean = false;
  isCustomSearch: boolean = false;

  isFirstProjectPage: boolean = true;
  isSecondProjectPage: boolean = false;
  isThirdProjectPage: boolean = false;
  isFourthProjectPage: boolean = false;
  isFifthProjectPage: boolean = false;
  isSixthProjectPage: boolean = false;
  isSeventhProjectPage: boolean = false;
  isEighthProjectPage: boolean = false;
  isNinethProjectPage: boolean = false;
  isTenthProjectPage: boolean = false;


  projectsDB: any[] = [//| slice:1:3
    {
      "id":1,
      "image":"assets/img/extras/sl3.jpg",
      "title":"First Project Name",
      "date": "Jan 25, 2022",
      "description": "This Crash Course has the goal of providing the participants with knowledge in Earth Observation, from satellite to image to information delivery with application to agriculture and biodiversity You will learn about remote sensing techniques, geospatial analysis, GIS, Big Data, Machine Learning, Artificial Intelligence, and all the potential value of these technologies for Earth applications.",
      "deliverables": [
        {
          "number": "01",
          "content" : "DELIVERED TO MARKET OUTSTANDING SPACE COMMUNICATION MODULES AND GROUND STATION AT A FRACTION OF THE COST AND IN SIGNIFICANTLY SHORTER TIME."
        },
        {
          "number":"02",
          "content" : "REACHED OVER 1500 SPACE AND NON-SPACE ORGANIZATIONS AS CLEAR AND POTENTIAL STAKEHOLDERS THROUGH OUR ONLINE SATELLITE STORE AND DIRECT ONLINE MARKETING."
        },
        {
          "number":"03",
          "content" : "ACCOMPLISHED OVER 50 LIVE DEMONSTRATIONS OF THE ADVANCED MODULES AND THIS CAPABILITY AS WELL AS 10 DEMONSTRATIONS OF THE GROUND STATION SERVICE"
        },
      ],
      "images": [{"path":""}]
    },
    {
      "id":2,
      "image":"assets/img/extras/sl3.jpg",
      "title":"Second Project Name",
      "date": "Jan 25, 2022",
      "description": "This Crash Course has the goal of providing the participants with knowledge in Earth Observation, from satellite to image to information delivery with application to agriculture and biodiversity You will learn about remote sensing techniques, geospatial analysis, GIS, Big Data, Machine Learning, Artificial Intelligence, and all the potential value of these technologies for Earth applications.",
    },
    {
      "id":3,
      "image":"assets/img/extras/sl3.jpg",
      "title":"Third Project Name",
      "date": "Jan 25, 2022",
      "description": "This Crash Course has the goal of providing the participants with knowledge in Earth Observation, from satellite to image to information delivery with application to agriculture and biodiversity You will learn about remote sensing techniques, geospatial analysis, GIS, Big Data, Machine Learning, Artificial Intelligence, and all the potential value of these technologies for Earth applications.",
    },
    {
      "id":4,
      "image":"assets/img/extras/sl3.jpg",
      "title":"Fourth Project Name",
      "date": "Jan 25, 2022",
      "description": "This Crash Course has the goal of providing the participants with knowledge in Earth Observation, from satellite to image to information delivery with application to agriculture and biodiversity You will learn about remote sensing techniques, geospatial analysis, GIS, Big Data, Machine Learning, Artificial Intelligence, and all the potential value of these technologies for Earth applications.",
    },
    {
      "id":5,
      "image":"assets/img/extras/sl3.jpg",
      "title":"Fifth Project Name",
      "date": "Jan 25, 2022",
      "description": "This Crash Course has the goal of providing the participants with knowledge in Earth Observation, from satellite to image to information delivery with application to agriculture and biodiversity You will learn about remote sensing techniques, geospatial analysis, GIS, Big Data, Machine Learning, Artificial Intelligence, and all the potential value of these technologies for Earth applications.",
    },
    {
      "id":6,
      "image":"assets/img/extras/sl3.jpg",
      "title":"Sixth Project Name",
      "date": "Jan 25, 2022",
      "description": "This Crash Course has the goal of providing the participants with knowledge in Earth Observation, from satellite to image to information delivery with application to agriculture and biodiversity You will learn about remote sensing techniques, geospatial analysis, GIS, Big Data, Machine Learning, Artificial Intelligence, and all the potential value of these technologies for Earth applications.",
    },
    {
      "id":7,
      "image":"assets/img/extras/sl3.jpg",
      "title":"Seventh Project Name",
      "date": "Jan 25, 2022",
      "description": "This Crash Course has the goal of providing the participants with knowledge in Earth Observation, from satellite to image to information delivery with application to agriculture and biodiversity You will learn about remote sensing techniques, geospatial analysis, GIS, Big Data, Machine Learning, Artificial Intelligence, and all the potential value of these technologies for Earth applications.",
    },
    {
      "id":8,
      "image":"assets/img/extras/sl3.jpg",
      "title":"Eigth Project Name",
      "date": "Jan 25, 2022",
      "description": "This Crash Course has the goal of providing the participants with knowledge in Earth Observation, from satellite to image to information delivery with application to agriculture and biodiversity You will learn about remote sensing techniques, geospatial analysis, GIS, Big Data, Machine Learning, Artificial Intelligence, and all the potential value of these technologies for Earth applications.",
    },
  ]


  constructor(
    private titleService: Title,
    private router: Router
    ) {
  }

  ngOnInit() {
    this.toTop();
  }


  toTop() {
    document.getElementById("target").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  toggleSearches(num) {
    if (num == 1) {
      this.isRadiusSearch = true;
      this.isGlobalSearch = false;
      this.isSatelliteSearch = false;
      this.isCustomSearch = false;
    }

    if (num == 2) {
      this.isRadiusSearch = false;
      this.isGlobalSearch = true;
      this.isSatelliteSearch = false;
      this.isCustomSearch = false;
    }
    if (num == 3) {
      this.isRadiusSearch = false;
      this.isGlobalSearch = false;
      this.isSatelliteSearch = true;
      this.isCustomSearch = false;
    }
    if (num == 4) {
      this.isRadiusSearch = false;
      this.isGlobalSearch = false;
      this.isSatelliteSearch = false;
      this.isCustomSearch = true;
    }
  }

  sendRouteObject(index) {

    let tempObj = this.projectsDB[index];

    this.router.navigate(['/project'], {
      state: {
        data: JSON.stringify({ value: tempObj}),
      }
    });
  }

  changePage(num) {
    if (num == 1) {
      this.isFirstProjectPage = true;
      this.isSecondProjectPage = false;
      this.isThirdProjectPage = false;
      this.isFourthProjectPage = false;
      this.isFifthProjectPage = false;
      this.isSixthProjectPage = false;
      this.isSeventhProjectPage = false;
      this.isEighthProjectPage = false;
      this.isNinethProjectPage = false;
      this.isTenthProjectPage = false;

      this.toTop();
    }
    if (num == 2) {
      this.isFirstProjectPage = false;
      this.isSecondProjectPage = true;
      this.isThirdProjectPage = false;
      this.isFourthProjectPage = false;
      this.isFifthProjectPage = false;
      this.isSixthProjectPage = false;
      this.isSeventhProjectPage = false;
      this.isEighthProjectPage = false;
      this.isNinethProjectPage = false;
      this.isTenthProjectPage = false;

      this.toTop();
    }
    if (num == 3) {
      this.isFirstProjectPage = false;
      this.isSecondProjectPage = false;
      this.isThirdProjectPage = true;
      this.isFourthProjectPage = false;
      this.isFifthProjectPage = false;
      this.isSixthProjectPage = false;
      this.isSeventhProjectPage = false;
      this.isEighthProjectPage = false;
      this.isNinethProjectPage = false;
      this.isTenthProjectPage = false;

      this.toTop();
    }
    if (num == 4) {
      this.isFirstProjectPage = false;
      this.isSecondProjectPage = false;
      this.isThirdProjectPage = false;
      this.isFourthProjectPage = true;
      this.isFifthProjectPage = false;
      this.isSixthProjectPage = false;
      this.isSeventhProjectPage = false;
      this.isEighthProjectPage = false;
      this.isNinethProjectPage = false;
      this.isTenthProjectPage = false;

      this.toTop();
    }
    if (num == 5) {
      this.isFirstProjectPage = false;
      this.isSecondProjectPage = false;
      this.isThirdProjectPage = false;
      this.isFourthProjectPage = false;
      this.isFifthProjectPage = true;
      this.isSixthProjectPage = false;
      this.isSeventhProjectPage = false;
      this.isEighthProjectPage = false;
      this.isNinethProjectPage = false;
      this.isTenthProjectPage = false;

      this.toTop();
    }
    if (num == 6) {
      this.isFirstProjectPage = false;
      this.isSecondProjectPage = false;
      this.isThirdProjectPage = false;
      this.isFourthProjectPage = false;
      this.isFifthProjectPage = false;
      this.isSixthProjectPage = true;
      this.isSeventhProjectPage = false;
      this.isEighthProjectPage = false;
      this.isNinethProjectPage = false;
      this.isTenthProjectPage = false;

      this.toTop();
    }
    if (num == 7) {
      this.isFirstProjectPage = false;
      this.isSecondProjectPage = false;
      this.isThirdProjectPage = false;
      this.isFourthProjectPage = false;
      this.isFifthProjectPage = false;
      this.isSixthProjectPage = false;
      this.isSeventhProjectPage = true;
      this.isEighthProjectPage = false;
      this.isNinethProjectPage = false;
      this.isTenthProjectPage = false;

      this.toTop();
    }
    if (num == 8) {
      this.isFirstProjectPage = false;
      this.isSecondProjectPage = false;
      this.isThirdProjectPage = false;
      this.isFourthProjectPage = false;
      this.isFifthProjectPage = false;
      this.isSixthProjectPage = false;
      this.isSeventhProjectPage = false;
      this.isEighthProjectPage = true;
      this.isNinethProjectPage = false;
      this.isTenthProjectPage = false;

      this.toTop();
    }
    if (num == 9) {
      this.isFirstProjectPage = false;
      this.isSecondProjectPage = false;
      this.isThirdProjectPage = false;
      this.isFourthProjectPage = false;
      this.isFifthProjectPage = false;
      this.isSixthProjectPage = false;
      this.isSeventhProjectPage = false;
      this.isEighthProjectPage = false;
      this.isNinethProjectPage = true;
      this.isTenthProjectPage = false;

      this.toTop();
    }
    if (num == 10) {
      this.isFirstProjectPage = false;
      this.isSecondProjectPage = false;
      this.isThirdProjectPage = false;
      this.isFourthProjectPage = false;
      this.isFifthProjectPage = false;
      this.isSixthProjectPage = false;
      this.isSeventhProjectPage = false;
      this.isEighthProjectPage = false;
      this.isNinethProjectPage = false;
      this.isTenthProjectPage = true;

      this.toTop();
    }
  }
}
